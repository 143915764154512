import React from 'react';

export default function BugleIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="81"
        height="93"
        viewBox="0 0 81 93"
        fill="none"
      >
        <path
          d="M64.2371 59.6875C60.8257 59.6875 57.8913 61.9326 56.8178 65.157C54.5276 71.9878 48.0863 76.884 40.5239 76.884C32.9614 76.884 26.4963 71.9639 24.2299 65.157C23.1564 61.9326 20.2221 59.6875 16.8106 59.6875C11.4906 59.6875 7.76901 64.9181 9.43896 69.9815C13.757 83.0461 26.043 92.4804 40.5477 92.4804C55.0524 92.4804 67.3385 83.0461 71.6565 69.9815C73.3264 64.9181 69.6048 59.6875 64.2849 59.6875H64.2371Z"
          fill="white"
        />
        <path
          d="M41.8121 14.0673V12.0133C43.9354 11.0101 45.4145 8.83668 45.4145 6.32885C45.4145 2.84176 42.5994 0.0234375 39.1164 0.0234375C35.6333 0.0234375 32.8183 2.84176 32.8183 6.32885C32.8183 8.83668 34.2974 11.0101 36.4206 12.0133V14.2345C15.9756 16.2885 0.0395508 33.5567 0.0395508 54.5748H81.0082C81.0082 32.6253 63.5692 14.7599 41.836 14.0673H41.8121ZM17.5024 45.7854H10.2024C10.2024 45.7854 13.5661 29.4726 24.8264 25.4839C16.6198 38.1186 17.5263 45.7854 17.5263 45.7854H17.5024Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
