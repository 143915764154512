import { useRef, useState, ReactNode } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

// project import

import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';

// assets

import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { AppTheme } from '../../../../../themes';
import Transitions from '../../../../../components/Transitions';
import MainCard from '../../../../../components/MainCard';
import { useDispatch } from 'react-redux';
import { onLogout } from '../../../../../store/auth';
import useAuth from '../../../../../hooks/useAuth';
import { paperBoxShadow } from '../../../../../config';
import i18n from '../../../../../i18n-config';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
  capitalizeFirstLetter,
  stringReduceAddDots
} from '../../../../../utils/stringUtils';

// tab panel wrapper
interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
  [key: string]: any;
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const navigate = useNavigate();

  const { user, restaurant } = useAuth();

  const anchorRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    // setOpen((prevOpen) => !prevOpen);
    navigate('/settings/company_info');
  };
  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar
            // alt="profile user"
            // src={'/user.png'}
            sx={{ width: 32, height: 32 }}
          />
          <Typography
            sx={{ fontSize: 16, fontWeight: 600, color: 'info.text1' }}
          >
            {restaurant?.ownerName
              ? capitalizeFirstLetter(
                  stringReduceAddDots(restaurant.ownerName + '', 10)
                )
              : 'N/A'}
          </Typography>
        </Stack>
      </ButtonBase>
    </Box>
  );
};

export default Profile;
