import { Box } from '@mui/material';
import React from 'react';

export default function FlagEn2() {
  return (
    <Box>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1086_86341)">
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#F0F0F0"
          />
          <path
            d="M9.56494 9.99933H19.9997C19.9997 9.09676 19.8794 8.22238 19.6552 7.39062H9.56494V9.99933Z"
            fill="#D80027"
          />
          <path
            d="M9.56494 4.78059H18.532C17.9198 3.78168 17.1371 2.89875 16.2246 2.17188H9.56494V4.78059Z"
            fill="#D80027"
          />
          <path
            d="M9.99975 19.9981C12.3532 19.9981 14.5164 19.1847 16.2246 17.8242H3.7749C5.48311 19.1847 7.64627 19.9981 9.99975 19.9981Z"
            fill="#D80027"
          />
          <path
            d="M1.46748 15.2142H18.532C19.0235 14.4123 19.4046 13.5357 19.6553 12.6055H0.344238C0.594902 13.5357 0.976035 14.4123 1.46748 15.2142Z"
            fill="#D80027"
          />
          <path
            d="M4.63219 1.56164H5.54348L4.69582 2.17746L5.01961 3.17391L4.17199 2.55809L3.32437 3.17391L3.60406 2.31309C2.85773 2.93477 2.20359 3.66313 1.66453 4.47469H1.95652L1.41695 4.86668C1.33289 5.00691 1.25227 5.14937 1.175 5.29395L1.43266 6.08695L0.951953 5.7377C0.832461 5.99086 0.723164 6.24973 0.624922 6.51398L0.908789 7.38773H1.95652L1.10887 8.00356L1.43266 9L0.585039 8.38418L0.0773047 8.75309C0.0264844 9.1616 0 9.57769 0 10H10C10 4.47719 10 3.82609 10 0C8.02453 0 6.18301 0.573047 4.63219 1.56164ZM5.01961 9L4.17199 8.38418L3.32437 9L3.64816 8.00356L2.80051 7.38773H3.84824L4.17199 6.39129L4.49574 7.38773H5.54348L4.69582 8.00356L5.01961 9ZM4.69582 5.09051L5.01961 6.08695L4.17199 5.47113L3.32437 6.08695L3.64816 5.09051L2.80051 4.47469H3.84824L4.17199 3.47824L4.49574 4.47469H5.54348L4.69582 5.09051ZM8.60656 9L7.75895 8.38418L6.91133 9L7.23512 8.00356L6.38746 7.38773H7.4352L7.75895 6.39129L8.0827 7.38773H9.13043L8.28277 8.00356L8.60656 9ZM8.28277 5.09051L8.60656 6.08695L7.75895 5.47113L6.91133 6.08695L7.23512 5.09051L6.38746 4.47469H7.4352L7.75895 3.47824L8.0827 4.47469H9.13043L8.28277 5.09051ZM8.28277 2.17746L8.60656 3.17391L7.75895 2.55809L6.91133 3.17391L7.23512 2.17746L6.38746 1.56164H7.4352L7.75895 0.565195L8.0827 1.56164H9.13043L8.28277 2.17746Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_1086_86341">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
