import React from 'react';

export const useStyle = () => {
  const styles = {
    modalbg: {
      background: '#fff15',
      backdropFilter: 'blur(1.5px)',
      '& .MuiPaper-root': {
        boxShadow:
          'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        background: '#fff',
        borderRadius: '12px',
        minWidth: '80%',
        height: '90%'
      }
    },
    mdlcan: {
      bgcolor: 'transparent',
      border: '1px solid #265767',
      color: '#265767',
      fontSize: 16,
      fontWeight: 600,
      minWidth: 190
    },
    mdlsave: {
      bgcolor: '#265767',
      color: 'white',
      fontSize: 16,
      fontWeight: 600,
      minWidth: 190
    }
  };
  return styles;
};
