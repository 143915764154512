import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useState } from 'react';
import useGetColors from '../../../../hooks/useGetColor';
import i18n from '../../../../i18n-config';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import AppButton from '../../../../components/button/AppButton';
import { Transition } from '../../../menu/components/Transition';
import AppTimePicker from '../../../../components/AppTimePicker/AppTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TimeInput from '../../../../components/timeInput/TimeInput';
import { stringToDate } from './AvailabilityItem';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: () => void;
  item: any;
  onSaveHandler: (item: any) => void;
}

type TimeSlots = {
  [key: number]: {
    startAt: Date | null;
    endAt: Date | null;
  };
};

function AvailabilityFormModal({
  open,
  handleClose,
  item,
  onSaveHandler
}: Props) {
  const colors = useGetColors();
  const { t } = useTranslation();

  const [timeSlots, setTimeSlots] = useState<TimeSlots>({
    1: {
      startAt: stringToTime(item?.time?.time?.[0]) as any,
      endAt: stringToTime(item?.time?.time?.[1]) as any
    }
  });
  const updateSlot = (index: number, keyVal: string, value: Date) =>
    setTimeSlots((prev) => ({
      ...prev,
      [index]: { ...prev[index], [keyVal]: value }
    }));

  const onSave = () => {
    const startAt = transformTimeArray(
      Object.values(timeSlots).map((el) =>
        formatTimeRange(el.startAt, el.endAt)
      )
    )[0];
    const endAt = transformTimeArray(
      Object.values(timeSlots).map((el) =>
        formatTimeRange(el.startAt, el.endAt)
      )
    )[1];
    const isValid = areStringsEqual(startAt, endAt);
    const isInferior = inferiorHour(startAt, endAt);
    if (isValid || isInferior) {
      notifyInfo(t('time_interval_incorrect'));
      return;
    }
    onSaveHandler({
      day: item?.day,
      time: transformTimeArray(
        Object.values(timeSlots).map((el) =>
          formatTimeRange(el.startAt, el.endAt)
        )
      )
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        background: '#fff15',
        backdropFilter: 'blur(1.5px)',
        '& .MuiPaper-root': {
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          background: '#fff',
          borderRadius: '12px',
          width: '30%'
        }
      }}
    >
      <DialogTitle sx={{ p: 0, m: 0 }}>
        <Box mb={2} p={3}>
          <Typography
            fontWeight={500}
            fontSize={24}
            mb={1}
            color={colors.primary.main}
          >
            {i18n.t('edit_availability')}
          </Typography>
          <Typography fontSize={14} width={'90%'} color={colors.primary.main}>
            {i18n.t('edit_availability_desc')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Box>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              ml: 3,
              height: '65px',
              width: '50%',
              maxWidth: '80%',
              borderRadius: '16px',
              backgroundColor: colors.primary.main + '30'
            }}
          >
            <Typography
              fontWeight={600}
              fontSize={32}
              color={colors.primary.main}
            >
              {i18n.t(item.day)}
            </Typography>
          </Stack>

          <Box sx={{ overflow: 'hidden', marginTop: '25px', height: '100%' }}>
            <Box sx={{ maxHeight: 350, overflow: 'auto' }}>
              <Stack mb={2} direction={'row'} alignItems="center" px={3}>
                <Typography width="50%">
                  {capitalizeFirstLetter(i18n.t('open'))}
                </Typography>
                <Typography>
                  {capitalizeFirstLetter(i18n.t('close'))}
                </Typography>
              </Stack>

              <Box sx={{ borderBottom: '2px solid #E5E7EB' }} />

              <Box mt={3} pl={3}>
                {Object.values(timeSlots).map((time, index) => (
                  <Stack
                    key={index}
                    direction={'row'}
                    spacing={3}
                    mb={2}
                    alignItems={'center'}
                  >
                    <TimeInput
                      value={time.startAt}
                      placeholder={'--:--'}
                      onChange={(val: any) =>
                        updateSlot(index + 1, 'startAt', val)
                      }
                    />
                    <TimeInput
                      value={time.endAt}
                      placeholder={'--:--'}
                      onChange={(val: any) =>
                        updateSlot(index + 1, 'endAt', val)
                      }
                    />

                    {/* <IconButton onClick={() => removeSlot(index + 1)}>
                      <TrashIcon />
                    </IconButton> */}
                  </Stack>
                ))}
                {/* {timeSlots.map((time, inx) => (
                  <TimeSlot
                    key={inx}
                    onChange={updateSlot}
                    time={time}
                    index={inx}
                    onDelete={() => removeSlot(inx)}
                  />
                ))} */}
              </Box>
            </Box>
            {/* <Stack justifyContent={'center'}>
              <AppButton
                icon={
                  <SvgContainer
                    svg={AddCircleIcon}
                    color={colors.accent}
                    rootStyle={{ paddingTop: 0.5 }}
                  />
                }
                title={i18n.t('add_time')}
                titleStyle={{
                  color: colors.accent,
                  fontWeight: 500
                }}
                containerStyle={{
                  backgroundColor: 'transparent',
                  border: 'none'
                }}
                onClick={addSlot}
              />
            </Stack> */}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 2, pb: 3 }}>
        <AppButton
          title={i18n.t('cancel')}
          containerStyle={{
            '&:hover': {
              opacity: 1
            },
            width: '150px',
            marginRight: '2rem',
            height: '48px',
            backgroundColor: 'transparent',
            border: `1px solid ${colors.primary.main}`
          }}
          titleStyle={{
            color: colors.primary.main,
            fontWeight: 'bold'
          }}
          onClick={handleClose}
        />
        <AppButton
          onClick={onSave}
          title={i18n.t('confirm')}
          titleStyle={{
            fontWeight: 'bold'
          }}
          containerStyle={{ width: '150px', height: '48px' }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AvailabilityFormModal;

function transformTimeArray(inputArray: string[]): string[] {
  const resultArray: string[] = [];
  for (const timeRange of inputArray) {
    const [startTime, endTime] = timeRange.split(',');
    resultArray.push(startTime, endTime, ';');
  }
  resultArray.pop();
  return resultArray;
}

function formatTimeRange(date1: any, date2: any) {
  const formatTime = (date: any) => {
    return new Intl.DateTimeFormat('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const time1 = formatTime(date1);
  const time2 = formatTime(date2);

  return `${time1.replace('h', ':')},${time2.replace('h', ':')}`
    .replace('AM', '')
    .replace('PM', '');
}

function stringToTime(timeString: string) {
  if (typeof timeString !== 'string') {
    return dayjs('2022-04-17T12:30');
  }

  var currentDate = new Date();

  var [hours, minutes] = timeString.split(':').map(Number);

  currentDate.setHours(hours + 1);
  currentDate.setMinutes(minutes);

  var formattedDateString = currentDate.toISOString().slice(0, -8);

  return dayjs(formattedDateString);
}

const compareDates = (
  date1: Dayjs | null | Date,
  date2: Dayjs | null | Date
): boolean => {
  if (date1 === null || date2 === null) {
    return false;
  }
  if (!dayjs.isDayjs(date2)) {
    return true;
  }
  return date2.isSame(date1) || date2.isBefore(date1);
};

function inferiorHour(heure1: string, heure2: string) {
  // Séparer les heures et les minutes des deux chaînes
  const [heure1_h, heure1_m] = heure1.split(':').map(Number);
  const [heure2_h, heure2_m] = heure2.split(':').map(Number);

  // Comparaison des heures et des minutes
  if (heure2_h < heure1_h) {
    return true;
  } else if (heure2_h === heure1_h && heure2_m < heure1_m) {
    return true;
  } else {
    return false;
  }
}

function areStringsEqual(string1: string, string2: string): boolean {
  return string1 === string2;
}
