import { Avatar, Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocationAddIcon } from '../../../../assets/icons';
import NoImageAltIcon from '../../../../assets/icons/NoImageAltIcon';
import NoImageIcon from '../../../../assets/icons/NoImageIcon';
import AppButton from '../../../../components/AppButton';
import AppInput from '../../../../components/AppInput';
import CustomDropzone from '../../../../components/CustomDropzone';
import CustomItemImage from '../../../../components/CustomItemImage';
import CustomLoader from '../../../../components/CustomLoader';
import CustomPhoneInput from '../../../../components/customPhoneInput/CustomPhoneInput';
import SvgContainer from '../../../../components/SvgContainer';
import useGetColors from '../../../../hooks/useGetColor';
import { updateRestaurantAsync } from '../../../../store/auth';
import { fromFirebaseToJs } from '../../../../utils/dateutils';
import { generateSettingBanner } from '../../../../utils/imageUtils';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import SettingsDetailTitle from '../../SettingsDetailTitle';
import MapModal from './MapModal';
import notifySuccess, {
  notifyError,
  notifyInfo
} from '../../../../utils/notify';
// eslint-disable-next-line no-restricted-imports
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import useAuth from '../../../../hooks/useAuth';
import { MapImage } from '../../../../assets/images/image';
import { useLocation } from 'react-router-dom';
import useLocale from '../../../../hooks/useLocale';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function CompanyInfo() {
  const colors = useGetColors();
  const loc = useLocation();
  const inputRef = useRef<any>();
  const dispatch = useDispatch();
  const { restaurant } = useAuth();
  const { local } = useLocale();
  const { t } = useTranslation();

  const [banners, setBanners] = useState(
    generateSettingBanner(restaurant?.banners)
  );
  const [loading, setLoading] = useState(false);
  const [mapShow, setMapShow] = useState(false);

  const [formData, setFormData] = useState({
    name: restaurant?.name || '',
    ownerName: restaurant?.ownerName || '',
    // email: restaurant?.email || '',
    email: restaurant?.email || '',
    description: restaurant?.description || '',
    phoneNumber: restaurant?.phoneNumber || ''
  });
  const [logoURL, setLogoURL] = useState(restaurant?.logoURL);
  const [location, setLocation] = useState(restaurant?.location || {});

  const mapModalHandler = () => setMapShow((prev) => !prev);

  const onChangeText = (valKey: any, text: any) => {
    setFormData({ ...formData, [valKey]: text });
  };

  const onChangeLocation = (valKey: any, text: any) => {
    setLocation({ ...location, [valKey]: text });
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      handleBannerImage({
        file: acceptedFiles[0],
        imageID: banners.filter((val: any) => val.isPrimary)?.[0]?.id || 0,
        source: 'primary',
        value: true
      });
    },
    [banners]
  );

  const handleImage = (ev: any) => {
    const file = ev.target.files[0];
    setLogoURL(file);
  };

  const handleBannerImage = ({ file, imageID, source, value }: any) => {
    const temp = banners.map((val: any) => {
      if (val.id === imageID) {
        let itemToChange = null;
        if (file) {
          itemToChange = { image: file?.target?.files[0] || file };
        }
        if (source === 'primary') {
          itemToChange = { ...itemToChange, isPrimary: value };
        } else if (source === 'remove') {
          itemToChange = { image: '' };
        }
        return { ...val, ...itemToChange };
      } else {
        if (source === 'primary' && val.id !== imageID) {
          return { ...val, isPrimary: false };
        } else {
          return val;
        }
      }
    });
    setBanners(temp);
  };

  const onChangeImage = () => {
    inputRef.current.click();
  };

  const callback = () => {
    notifySuccess(capitalizeFirstLetter(t('update_success')));
    setLoading(false);
  };
  const onError = () => {
    notifyError(capitalizeFirstLetter(t('error_occured')));
    setLoading(false);
  };

  const onSave = () => {
    // const toUpdate: any = {};
    // if (formData.name.trim() !== restaurant?.name) {
    //   toUpdate.name = formData.name;
    // }
    // if (formData.ownerName.trim() !== restaurant?.ownerName) {
    //   toUpdate.ownerName = formData.ownerName;
    // }
    // if (formData.description.trim() !== restaurant?.description) {
    //   toUpdate.description = formData.description;
    // }

    // if (formData.phoneNumber.trim() !== restaurant?.phoneNumber) {
    //   toUpdate.phoneNumber = formData.phoneNumber;
    // }
    // if (logoURL !== restaurant?.logoURL) {
    //   toUpdate.logoURL = logoURL;
    // }

    // const result = banners.map((el: any) =>
    //   typeof el.image === 'string' ? el.image : URL.createObjectURL(el?.image)
    // );
    // if (
    //   !isEqual(
    //     generateSettingBanner(restaurant.banners),
    //     generateSettingBanner(result)
    //   )
    // ) {
    //   console.log('result', result);
    // }
    // if (isEqual(toUpdate, {})) {
    //   notifyInfo(capitalizeFirstLetter(t('no_update')));
    //   return;
    // }

    setLoading(true);
    const newBanners = banners
      .map(({ id, ...rest }) => rest)
      .filter((val: any) => val.image !== '');
    dispatch(
      updateRestaurantAsync({
        data: {
          ...restaurant,
          ...formData,
          logoURL,
          banners: newBanners,
          location,
          updatedAt: new Date()
        },
        callback,
        onError
      }) as any
    );
  };

  const hasImages = banners.filter((val: any) => val.image !== '').length;

  const activeBanner: any =
    banners.filter((val: any) => val.isPrimary)[0] || banners[0];

  const disabled =
    !formData.name?.trim() ||
    !formData.email?.trim() ||
    // !formData.description.trim() ||
    !formData.phoneNumber?.trim();

  const localeDate = (dateOriginale: Date) => {
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    // Formater la date
    const dateFormatee = dateOriginale.toLocaleDateString(
      'fr-FR',
      options as any
    );
    return dateFormatee;
  };

  useEffect(() => {
    if (loc?.state?.showMap) {
      setMapShow(true);
    }
  }, [loc?.state?.showMap]);

  return (
    <Box sx={{ backgroundColor: 'white', minHeight: '100vh' }} pt={1}>
      <SettingsDetailTitle
        title="company_info"
        showUpdates={true}
        lastDateUpdate={dayjs(fromFirebaseToJs(restaurant?.updatedAt))
          .locale(local)
          .format('DD MMM YYYY')}
        rootStyle={{ mb: 3, ml: 4 }}
      />
      {mapShow ? (
        <MapModal
          open={mapShow}
          onClose={mapModalHandler}
          key={'map'}
          setAdress={(ev: any) =>
            // setLocation((prev: any) => ({ ...prev, address: ev }))

            setLocation(ev)
          }
        />
      ) : null}
      {loading ? <CustomLoader open={loading} /> : null}
      <Box p={4}>
        <Box mb={5} height="300px" sx={{ position: 'relative' }}>
          <CustomDropzone
            onDrop={onDrop}
            style={{
              border: '0.5px solid #E6E6E6',
              height: '261px',
              borderRadius: '16px'
            }}
          >
            {hasImages ? (
              <img
                src={
                  activeBanner?.image?.name
                    ? URL.createObjectURL(activeBanner?.image)
                    : activeBanner?.image
                }
                alt="banner"
                style={{
                  height: '261px',
                  width: '100%',
                  borderRadius: '16px',
                  objectFit: 'cover'
                }}
              />
            ) : (
              <Stack
                alignItems={'center'}
                justifyContent="center"
                sx={{
                  backgroundColor: '#F3F4F6',
                  height: '261px',
                  borderRadius: '16px'
                }}
              >
                <NoImageAltIcon />
                <Stack direction="row" spacing={1} mt={3}>
                  <Typography
                    fontSize={14}
                    color={colors.primary.active}
                    fontWeight={550}
                    sx={{ cursor: 'pointer' }}
                  >
                    {t('click_upload_image')}
                  </Typography>
                  <Typography fontSize={14} color={'#8392A1'}>
                    {t('click_upload_image_desc')}
                  </Typography>
                </Stack>
                <Typography fontSize={14} color={'#8392A1'}>
                  {t('click_upload_image_format')}
                </Typography>
              </Stack>
            )}
          </CustomDropzone>
          <Stack
            sx={{
              width: 120,
              height: 120,
              borderRadius: 100,
              backgroundColor: '#F9F9F9',
              position: 'absolute',
              bottom: 0,
              left: 40,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #E6E6E6',
              filter:
                'drop-shadow(0px 10px 10px rgba(31, 41, 55, 0.04)) drop-shadow(0px 20px 25px rgba(31, 41, 55, 0.10))'
            }}
            onClick={onChangeImage}
          >
            {logoURL ? (
              <CardMedia
                component="img"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 100,
                  objectFit: 'cover'
                }}
                image={logoURL?.name ? URL.createObjectURL(logoURL) : logoURL}
                alt="item logo"
              />
            ) : (
              <NoImageIcon />
            )}
          </Stack>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppInput
              title={capitalizeFirstLetter(t('company_name'))}
              placeholder={capitalizeFirstLetter(t('company_name'))}
              containerStyle={{
                backgroundColor: 'white',
                marginBottom: 3,
                width: '100%'
              }}
              value={formData.name}
              onChange={(val: string) => onChangeText('name', val)}
            />
            <AppInput
              title={capitalizeFirstLetter(t('email_address'))}
              placeholder={capitalizeFirstLetter(t('email_address'))}
              containerStyle={{
                backgroundColor: 'white',
                marginBottom: 3,
                width: '100%'
              }}
              value={formData.email}
              disabled={true}
              onChange={(val: string) => onChangeText('email', val)}
            />
            <AppInput
              title={capitalizeFirstLetter(t('description'))}
              placeholder={capitalizeFirstLetter(t('description'))}
              containerStyle={{
                backgroundColor: 'white',
                marginBottom: 3,
                width: '100%'
              }}
              multiline={true}
              maxRows={2}
              inputStyle={{ height: 70 }}
              value={formData.description}
              onChange={(val: string) => onChangeText('description', val)}
            />
          </Grid>
          <Grid item xs={6}>
            <AppInput
              title={capitalizeFirstLetter(t('owner_name'))}
              placeholder={capitalizeFirstLetter(t('owner_name'))}
              containerStyle={{
                backgroundColor: 'white',
                marginBottom: 3,
                width: '100%'
              }}
              value={formData.ownerName}
              onChange={(val: string) => onChangeText('ownerName', val)}
            />
            <CustomPhoneInput
              title={capitalizeFirstLetter(t('company_contact'))}
              rootStyle={{ marginBottom: 3 }}
              // phoneContainerStyle={{ borderColor: '#E6E6E6' }}
              onChange={(args, country) => onChangeText('phoneNumber', args)}
              value={formData.phoneNumber}
              titleStyle={{ color: '#344051', fontSize: 14, fontWeight: 500 }}
            />
          </Grid>
        </Grid>

        <Typography className="admin-title1" my={2}>
          {capitalizeFirstLetter(t('company_location'))}
        </Typography>

        <Grid2 container columnSpacing={2} rowSpacing={3}>
          {/* <Grid2 xs={6} md={4}>
            <AppInput
              title={capitalizeFirstLetter(t('country'))}
              containerStyle={{
                backgroundColor: 'white'
              }}
              value={location.address
                .split(',')
                ?.[location.address.split(',').length - 1]?.trim()}
              disabled={true}
            />
          </Grid2>
          <Grid2 xs={6} md={4}>
            <AppInput
              title={capitalizeFirstLetter(t('city'))}
              containerStyle={{
                backgroundColor: 'white'
              }}
              value={location.address.split(',')[1]?.trim()}
              disabled={true}
            />
          </Grid2> */}
          {/* <Grid2 xs={6} md={4}>
            <AppInput
              title={capitalizeFirstLetter(t('street'))}
              containerStyle={{
                backgroundColor: 'white'
              }}
              value={location.address.split(',')[0]?.trim()}
              disabled={true}
            />
          </Grid2> */}
          <Grid2 xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography
                sx={{ fontSize: 14, fontWeight: 400, color: '#8392A1', mb: 1 }}
              >
                {capitalizeFirstLetter(t('address_line'))}
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 500, color: '#333' }}>
                {capitalizeFirstLetter(location.address)}
              </Typography>
            </Box>

            <AppButton
              title={t('or_select_map')}
              containerStyle={{
                height: '44px',
                border: `1px solid ${colors.accent}`,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
              titleStyle={{
                color: colors.accent,
                fontWeight: 'bold'
              }}
              endIcon={
                <SvgContainer svg={LocationAddIcon} color={colors.accent} />
              }
              onClick={mapModalHandler}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            {/* <img
              alt=""
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${location?.longitude},${location?.latitude}&zoom=14&size=400x400&key=&signature=${GOOGLE_MAPS_API_KEY}`}
              style={{
                border: 'none',
                width: '100%',
                height: '100%',
                borderRadius: '16px'
              }}
            /> */}
            <Avatar
              variant="rounded"
              src={MapImage}
              sx={{ width: '100%', height: '100%' }}
            />
          </Grid2>
        </Grid2>

        <Box mt={4}>
          <Typography color="#265767" fontWeight="550" mb={2}>
            {t('banner_image')}
          </Typography>
          <Typography color="#667085" fontSize={14}>
            {t('banner_image_desc')}
          </Typography>
          <Typography color="#667085" fontSize={14}>
            {t('banner_image_desc2')}
          </Typography>
          <Stack direction="row" my={2}>
            <Typography fontSize={14} fontWeight="600" color={colors.accent}>
              {hasImages}
            </Typography>
            <Typography fontSize={14} color="#667085">
              /6 {t('added')}
            </Typography>
          </Stack>

          <Grid
            container
            columnGap={2}
            rowGap={2}
            sx={{ border: '1px solid #E6E6E6', borderRadius: '8px', p: 2 }}
          >
            {banners?.map((banner: any, inx: any) =>
              hasImages ? (
                <CustomItemImage
                  key={inx}
                  gridSize={2}
                  url={
                    banner?.image?.name
                      ? URL.createObjectURL(banner.image)
                      : banner.image
                  }
                  isPrimary={banner.isPrimary}
                  onSetPrimary={() =>
                    handleBannerImage({
                      imageID: banner.id,
                      source: 'primary',
                      value: !banner.isPrimary
                    })
                  }
                  onRemoveImage={() =>
                    handleBannerImage({ imageID: banner.id, source: 'remove' })
                  }
                  rootStyle={{ mt: 0 }}
                  handleImage={(val: any) =>
                    handleBannerImage({ file: val, imageID: banner.id })
                  }
                />
              ) : (
                <CustomItemImage
                  key={inx}
                  gridSize={2}
                  rootStyle={{ mt: 0 }}
                  title={capitalizeFirstLetter(t('tap_add'))}
                  handleImage={(val: any) =>
                    handleBannerImage({ file: val, imageID: banner.id })
                  }
                />
              )
            )}
          </Grid>
        </Box>

        <Stack direction="row" spacing={4} mt={5} justifyContent="flex-end">
          <AppButton
            title={t('discard_changes')}
            containerStyle={{
              // width: '150px',
              height: '44px',
              px: 3,
              backgroundColor: 'transparent',
              border: `1px solid #8392A1`,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            titleStyle={{
              color: '#8392A1',
              fontWeight: 'bold'
            }}
            // onClick={onSave}
          />
          <AppButton
            title={t('save_changes')}
            titleStyle={{
              fontWeight: 'bold'
            }}
            containerStyle={{
              width: '150px',
              height: '44px',
              backgroundColor: colors.primary.main
            }}
            disabled={disabled}
            onClick={onSave}
          />
        </Stack>
      </Box>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={handleImage}
        accept="image/*"
      />
    </Box>
  );
}
