import { lazy } from 'react';
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

import DashboardIcon from '../assets/icons/drawer/DashboardIcon';
import MenuIcon from '../assets/icons/drawer/MenuIcon';
import OrdersIcon from '../assets/icons/drawer/OrdersIcon';
import FeedbackIcon from '../assets/icons/drawer/FeedbackIcon';
import FeedbackActiveIcon from '../assets/icons/drawer/FeedbackActiveIcon';
import DashBoardActiveIcon from '../assets/icons/drawer/DashBoardActiveIcon';
import MenuActiveIcon from '../assets/icons/drawer/MenuActiveIcon';
import OrdersActiveIcon from '../assets/icons/drawer/OrdersActiveIcon';
import Settings from '../pages/settings/Settings';
import CompanyInfo from '../pages/settings/components/companyInfo/CompanyInfo';
import Availability from '../pages/settings/components/availability/Availability';
import PaymentMethod from '../pages/settings/components/paymentMethod/PaymentMethod';
import Security from '../pages/settings/components/security/Security';
import Theme from '../pages/settings/components/theme/Theme';
import HomeIcon from '../assets/icons/drawer/HomeIcon';
import HomeActiveIcon from '../assets/icons/drawer/HomeActiveIcon';
import SettingsIcon from '../assets/icons/drawer/SettingsIcon';
import ActiveSettingsIcon from '../assets/icons/drawer/ActiveSettingsIcon';
import NewsIcon from '../assets/icons/drawer/NewsIcon';
import FaqsIcon from '../assets/icons/drawer/FaqsIcon';
import AboutIcon from '../assets/icons/drawer/AboutIcon';

// render - dashboard
const Home = Loadable(lazy(() => import('../pages/home/Home')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Menu = Loadable(lazy(() => import('../pages/menu/Menu')));
const Orders = Loadable(lazy(() => import('../pages/orders/Orders')));
const Feedback = Loadable(lazy(() => import('../pages/feedback/Feedback')));
const News = Loadable(lazy(() => import('../pages/news/News')));
const Faqs = Loadable(lazy(() => import('../pages/faqs/Faqs')));
const About = Loadable(lazy(() => import('../pages/about/About')));

// ==============================|| MAIN ROUTING ||============================== //

export const siderbarRoutes: any[] = [
  {
    id: 1,
    path: '/',
    name: 'home',
    title: 'home',
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 2,
    path: '/report-statistics',
    name: 'Report & Stat',
    title: 'report_stat_drawer',
    icon: DashboardIcon,
    activeIcon: DashBoardActiveIcon
  },
  {
    id: 3,
    path: '/menus',
    name: 'menu',
    title: 'menu',
    icon: MenuIcon,
    activeIcon: MenuActiveIcon
  },

  {
    id: 4,
    path: '/orders-history',
    name: 'orders History',
    title: 'orders_history',
    icon: OrdersIcon,
    activeIcon: OrdersActiveIcon
  },
  {
    id: 5,
    path: '/settings',
    name: 'settings',
    title: 'settings',
    icon: SettingsIcon,
    activeIcon: ActiveSettingsIcon
  },

  {
    id: 6,
    path: '/feedback',
    name: 'feedback',
    title: 'feedback',
    icon: FeedbackIcon,
    activeIcon: FeedbackActiveIcon
  },
  {
    id: 7,
    path: '/news',
    name: 'news',
    title: 'news',
    icon: NewsIcon,
    activeIcon: NewsIcon
  },
  {
    id: 8,
    path: '/faqs',
    name: 'FAQs',
    title: 'FAQs',
    icon: FaqsIcon,
    activeIcon: FaqsIcon
  },
  {
    id: 9,
    path: '/about',
    name: 'About Tchop.me !',
    title: 'about_desc',
    icon: AboutIcon,
    activeIcon: AboutIcon
  }
];

const MainRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/report-statistics',
        element: <Dashboard />
      },

      {
        path: '/menus',
        element: <Menu />
      },

      {
        path: '/orders-history',
        element: <Orders />
      },

      {
        path: '/feedback',
        element: <Feedback />
      },

      {
        path: '/settings',
        element: <Settings />,
        children: [
          {
            path: 'company_info',
            name: 'company_info',
            element: <CompanyInfo />
          },
          {
            path: 'availabilities',
            name: 'availabilities',
            element: <Availability />
          },
          // {
          //   path: 'payment_method',
          //   name: 'payment_method',
          //   element: <PaymentMethod />
          // },
          // {
          //   path: 'security',
          //   name: 'security',
          //   element: <Security />
          // },
          {
            path: 'theme',
            name: 'theme',
            element: <Theme />
          }
        ]
      },
      {
        path: '/news',
        element: <News />
      },
      {
        path: '/faqs',
        element: <Faqs />
      },
      {
        path: '/about',
        element: <About />
      }
    ]
  }
];

export default MainRoutes;
