import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import i18n from '../../../i18n-config';
import useGetColors from '../../../hooks/useGetColor';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({
  open,
  handleDrawerToggle,
  show
}: // handleDrawerToggle
{
  open: boolean;
  handleDrawerToggle: () => void;
  show: boolean;
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const { pathname } = useLocation();
  const colors = useGetColors();
  const routeName = useMemo(() => {
    if (pathname?.includes('report-statistics')) {
      return capitalizeFirstLetter(i18n.t('report_statistics'));
    }
    if (pathname === '/') {
      return capitalizeFirstLetter(i18n.t('home'));
    }
    if (pathname?.includes('settings')) {
      return capitalizeFirstLetter(i18n.t('settings'));
    }
    if (pathname?.includes('orders-history')) {
      return capitalizeFirstLetter(i18n.t('orders_history'));
    }
    const tab = pathname?.split('/')?.filter((el) => !!el.trim());
    const n = tab?.[tab.length - 1];
    return capitalizeFirstLetter(
      i18n.t(!isNaN(Number(n)) ? tab[0] : tab?.[tab.length - 1])
    );
  }, [pathname]);

  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';

  // common header
  const mainHeader = (
    <Toolbar>
      {show ? (
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{
            color: 'text.primary',
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            ml: { xs: 0, lg: -2 }
          }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      ) : null}
      <Typography
        sx={{
          color: colors.primary.main,
          fontSize: 32,
          fontWeight: 600,
          width: '100%'
        }}
      >
        {routeName}
      </Typography>
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar: any = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      bgcolor: 'info.outlet'
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
