import React from 'react';

export default function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.5999 22.5608H6.39985C4.57984 22.5608 2.91985 21.1608 2.61985 19.3608L1.28984 11.4008C1.07984 10.1608 1.67985 8.57084 2.66985 7.78084L9.59986 2.23079C10.9399 1.15079 13.0498 1.1608 14.3998 2.2408L21.3299 7.78084C22.3099 8.57084 22.9099 10.1608 22.7099 11.4008L21.3799 19.3608C21.0799 21.1308 19.3899 22.5608 17.5999 22.5608ZM11.9899 2.94082C11.4599 2.94082 10.9298 3.10079 10.5398 3.41079L3.60985 8.96084C3.03985 9.42084 2.64986 10.4408 2.76986 11.1608L4.09986 19.1208C4.27986 20.1708 5.32984 21.0608 6.39985 21.0608H17.5999C18.6699 21.0608 19.7198 20.1708 19.8998 19.1108L21.2298 11.1508C21.3498 10.4308 20.9499 9.40083 20.3899 8.95083L13.4599 3.41079C13.0599 3.10079 12.5299 2.94082 11.9899 2.94082Z"
        fill="white"
      />
      <path
        d="M12 16.25C10.21 16.25 8.75 14.79 8.75 13C8.75 11.21 10.21 9.75 12 9.75C13.79 9.75 15.25 11.21 15.25 13C15.25 14.79 13.79 16.25 12 16.25ZM12 11.25C11.04 11.25 10.25 12.04 10.25 13C10.25 13.96 11.04 14.75 12 14.75C12.96 14.75 13.75 13.96 13.75 13C13.75 12.04 12.96 11.25 12 11.25Z"
        fill="white"
      />
    </svg>
  );
}
