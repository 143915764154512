import React from 'react';

export default function CompanyLoc() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31737 16.4158C11.2422 13.9613 13.6667 10.3953 13.6667 8.00594C13.6667 4.0439 10.6819 0.832031 7.00004 0.832031C3.31814 0.832031 0.333374 4.0439 0.333374 8.00594C0.333374 10.3953 2.75792 13.9613 4.68271 16.4158C5.68691 17.6963 6.18901 18.3366 7.00004 18.3366C7.81107 18.3366 8.31317 17.6963 9.31737 16.4158ZM7.00004 10.832C5.61933 10.832 4.50004 9.71274 4.50004 8.33203C4.50004 6.95132 5.61933 5.83203 7.00004 5.83203C8.38075 5.83203 9.50004 6.95132 9.50004 8.33203C9.50004 9.71274 8.38075 10.832 7.00004 10.832Z"
        fill="#265767"
      />
    </svg>
  );
}
