import {
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../assets/icons';
import SvgContainer from '../../../../components/SvgContainer';
import useGetColors from '../../../../hooks/useGetColor';
import '../../Settings.css';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import i18n from '../../../../i18n-config';
import useLocale from '../../../../hooks/useLocale';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { IOSSwitch } from '../../../../components/IOSSwitch';

interface Props {
  title: string;
  showBorderBottom?: boolean;
  onEdit: () => void;
  item: any;
  onSwitched: (el: boolean) => void;
}

export default function AvailabilityItem({
  title,
  showBorderBottom,
  onEdit,
  item,
  onSwitched
}: Props) {
  const { t } = useTranslation();
  const colors = useGetColors();
  const { local, formatedDate } = useLocale();

  const hours = useMemo(() => {
    if (!item?.time?.length) {
      return;
    }
    return {
      startTime: `${dayjs(stringToDate(item?.time?.[0]))
        .locale(local)
        .format(formatedDate(local))} ${stringFormat(
        local,
        stringToDate(item?.time?.[0])
      )}`,
      endTime: `${dayjs(stringToDate(item?.time?.[1]))
        .locale(local)
        .format(formatedDate(local))} ${stringFormat(
        local,
        stringToDate(item?.time?.[0])
      )}`
    };
  }, [formatedDate, item?.time, local]);
  return (
    <Grid
      container
      sx={{
        px: 4,
        alignItems: 'center',
        height: 65,
        bgcolor: item?.open ? 'white' : '#FAFAFA',
        borderBottom: '1px solid #E4E4E7',
        borderBottomStyle: showBorderBottom ? 'solid' : 'none'
      }}
    >
      <Grid item xs={3}>
        <Stack direction="row">
          <Typography
            fontWeight={400}
            fontSize={16}
            sx={{ opacity: item?.open ? 1 : 0.34 }}
          >
            {t(title + '')}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Stack direction="row">
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              backgroundColor: '#F2F4F7',
              borderRadius: 50,
              px: 2,
              width: 150
            }}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ opacity: item?.open ? 1 : 0.34 }}
            >
              {!item?.time?.length
                ? '--:-- - --:--'
                : `${hours?.startTime} - ${hours?.endTime}`}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction={'row'} justifyContent="space-between">
          <Stack direction={'row'} spacing={1}>
            <IOSSwitch
              checked={item?.open}
              onChange={(el: React.ChangeEvent<HTMLInputElement>) =>
                onSwitched(el.target.checked)
              }
              sx={{
                width: 36,
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked': {
                    '& + .MuiSwitch-track': {
                      backgroundColor: colors.accent
                    },
                    width: 15
                  },
                  '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: colors.accent,
                    border: '6px solid #fff'
                  }
                }
              }}
            />
            <Typography fontSize={14} fontWeight={500} color="#344054">
              {item?.open
                ? capitalizeFirstLetter(i18n.t('open'))
                : capitalizeFirstLetter(i18n.t('close'))}
            </Typography>
          </Stack>

          <IconButton onClick={onEdit}>
            <SvgContainer svg={EditIcon} color={colors.accent} />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

export const stringToDate = (timeString: string): Date => {
  const [hoursStr, minutesStr] = timeString.split(':');
  const hours = parseInt(hoursStr);
  const minutes = parseInt(minutesStr);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);

  return date;
};

const stringFormat = (local: string, time: Date) => {
  const hours = time.getHours();
  return local === 'fr' ? 'h' : hours >= 0 && hours < 12 ? 'AM' : 'PM';
};
