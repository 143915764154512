import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useGetColors from '../../hooks/useGetColor';
import {
  capitalizeFirstLetter,
  generateKeywordsByNames
} from '../../utils/stringUtils';
import i18n from '../../i18n-config';
import HideIcon from '../../assets/icons/HideIcon';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CustomPhoneInput from '../../components/customPhoneInput/CustomPhoneInput';
import { validateRegister } from '../../utils/validation';
import CompanyLoc from '../../assets/icons/CompanyLoc';
import { Navigate, useNavigate } from 'react-router-dom';
import BackBlueIcon from '../../assets/icons/BackBlueIcon';
import BlueEmailIcon from '../../assets/icons/BlueEmailIcon';
import BluePhoneIcon from '../../assets/icons/BluePhoneIcon';
import CompanyLoc2 from '../../assets/icons/CompanyLoc2';
import YellowEditIcon from '../../assets/icons/YellowEditIcon';
import DonutTop from '../../assets/icons/DonutTop';
import DonutBottom from '../../assets/icons/DonutBottom';
import TitleIcon from '../../assets/icons/TitleIcon';
import { initRestaurantAsync, onLogout } from '../../store/auth';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { GOOGLE_MAPS_API_KEY, auth, firestore } from '../../firebase';
import { createUserWithEmailAndPassword, deleteUser } from 'firebase/auth';
import axios from 'axios';
import { COLLECTIONS_NAMES } from '../../utils/constant';
import { deleteDoc, doc } from 'firebase/firestore';
import AppInput from '../../components/AppInput';
import { useStyle } from './useStyle';

export default function RegisterPage() {
  const colors = useGetColors();
  const navigate = useNavigate();
  const boxRef = useRef();
  const dispatch = useDispatch();
  const style = useStyle();

  const creatinRestaurant = useSelector(
    (state: RootState) => state.ui.creatinRestaurant
  );

  const user = useSelector((state: RootState) => state.auth);

  const [geoLocation, setGeoLocation] = useState<any>(null);

  const [formData, setFormData] = useState({
    restaurantName: '',
    businessLocation: '',
    email: '',
    mobileNumber: '',
    password: '',
    placeInfo: ''
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [loading, setLoading] = useState(false);

  const displayHandler = () => setShowNext((prev) => !prev);

  const formHandler = (key: string) => (value: string) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const onNext = () => {
    setShowNext((prev) => !prev);
  };

  const onError = async () => {
    // const auth = getAuth();
    const user = auth.currentUser;
    await deleteDoc(
      doc(firestore, COLLECTIONS_NAMES.RESTAURANTS, user?.uid + '')
    );

    deleteUser(user as any)
      .then(() => {
        console.log('Failed Auth user deleted successfully');
        dispatch(onLogout() as any);
      })
      .catch((error) => {
        console.log(
          'Failed deleting the user this maybe due to unstable network',
          error
        );
        return;
      });
  };

  const onRegister = async () => {
    try {
      setLoading(true);
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const data = {
        id: userCredentials.user.uid,
        restaurantName: formData.restaurantName,
        businessLocation: formData.placeInfo,
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        geoLocation: geoLocation,
        keywords: generateKeywordsByNames(formData.restaurantName),
        placeInfo: formData.placeInfo
      };
      dispatch(initRestaurantAsync({ data: data, onError: onError }) as any);
    } catch (error) {
      console.log('Failed to initialize user', error);
    } finally {
      setLoading(false);
    }
  };

  const getLocation = async (lat: number, lon: number) => {
    const baseURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat}, ${lon}&key=${GOOGLE_MAPS_API_KEY}&result_type=&language=fr`;
    try {
      axios.get(baseURL).then((response) => {
        const data = response.data?.results[0]?.formatted_address;
        const parts = data.split(',');

        if (parts.length > 1) {
          const result = parts.slice(1).join(',').trim();
          setFormData(
            (prev) =>
              ({
                ...prev,
                ['placeInfo']: result,
                ['businessLocation']: result
              } as any)
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        getLocation(latitude, longitude);
        setGeoLocation({ latitude, longitude, createdAt: position.timestamp });
      });
    } else {
      console.log('Geolocation is not available in this browser.');
    }
  }, []);

  if (user?.userData) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || creatinRestaurant}
      >
        <CircularProgress color="success" />
      </Backdrop>

      <Box
        sx={{
          width: '100%',
          height: '15vh',
          bgcolor: 'white',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          p: '27px 100px'
        }}
      >
        <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            <DonutTop />

            <DonutBottom />
          </Box>
          <TitleIcon />
        </Stack>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            cursor: 'pointer',
            color: colors.primary.main
          }}
        >
          {capitalizeFirstLetter(i18n.t('login'))}
        </Typography>
      </Box>
      <Box
        sx={{
          height: '90vh',
          backgroundImage: `linear-gradient(to bottom, ${colors.primary.main} 70%, white 50%)`,
          p: 4
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              fontSize: 32,
              fontWeight: 500,
              color: 'white',
              mb: 6,
              width: 580
            }}
          >
            {capitalizeFirstLetter(i18n.t('register_title'))}
          </Typography>

          <Box
            ref={boxRef}
            sx={{
              borderRadius: '12px',
              bgcolor: colors.info.bg2,
              boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
              p: 4
            }}
          >
            {!showNext ? (
              <Box>
                <Typography
                  sx={{
                    fontSize: 32,
                    fontWeight: 500,
                    color: colors.info.deliver
                  }}
                >
                  {capitalizeFirstLetter(i18n.t('register'))}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: colors.info.txt3,
                    mb: 3,
                    mt: 2,
                    width: 437
                  }}
                >
                  {capitalizeFirstLetter(i18n.t('register_desc'))}
                </Typography>

                <form>
                  <AppInput
                    title={capitalizeFirstLetter(i18n.t('restaurant_name'))}
                    onChange={formHandler('restaurantName')}
                    value={formData.restaurantName}
                    required={true}
                    containerStyle={{
                      mb: 3
                    }}
                  />

                  <div style={{ marginBottom: 24 }}>
                    <Stack direction={'row'} spacing={'10px'}>
                      <Typography
                        sx={{
                          color: '#344051',
                          fontSize: 14,
                          fontWeight: 500
                        }}
                      >
                        {capitalizeFirstLetter(i18n.t('business_location'))}
                      </Typography>
                      <span
                        style={{
                          color: 'red',
                          marginLeft: 10
                        }}
                      >
                        *
                      </span>
                    </Stack>

                    <Stack
                      direction={'row'}
                      spacing={1}
                      sx={{
                        border: `1px solid #CED2DA`,
                        height: 45,
                        color: '#292D32',
                        padding: '10px 8px 10px 12px',
                        borderRadius: '8px',
                        marginTop: '6px',
                        backgroundColor: '#fff',
                        width: '100%'
                      }}
                    >
                      <CompanyLoc />
                      <Typography
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        {formData.placeInfo}
                      </Typography>
                    </Stack>
                    {formData.placeInfo === '' ? (
                      <div
                        style={{
                          color: 'red',
                          marginTop: '5px'
                        }}
                      >
                        {capitalizeFirstLetter(i18n.t('reg_loc_desc'))}
                      </div>
                    ) : null}
                  </div>

                  <AppInput
                    title={capitalizeFirstLetter(i18n.t('email'))}
                    value={formData.email}
                    onChange={formHandler('email')}
                    type="email"
                    required={true}
                    containerStyle={{
                      mb: 3
                    }}
                  />

                  <CustomPhoneInput
                    title={i18n.t('mobile_number')}
                    // rootStyle={{ width: '50%', borderColor: '#E6E6E6' }}
                    // phoneContainerStyle={{ borderColor: '#E6E6E6' }}
                    onChange={formHandler('mobileNumber')}
                    value={formData.mobileNumber}
                    titleStyle={{ color: colors.main }}
                    required={true}
                  />
                </form>
                <Button
                  onClick={onNext}
                  disabled={
                    !validateRegister(
                      formData.email,
                      formData.restaurantName,
                      formData.businessLocation,
                      formData.mobileNumber
                    )
                  }
                  sx={{
                    bgcolor: colors.primary.main,
                    color: 'white',
                    width: '100%',
                    mt: 3
                  }}
                >
                  {capitalizeFirstLetter(i18n.t('get_started'))}
                </Button>
                <Stack
                  sx={{ cursor: 'pointer', mt: 1 }}
                  direction={'row'}
                  spacing={1}
                  onClick={() => navigate('/login')}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: colors.info.txtSh
                    }}
                  >
                    {capitalizeFirstLetter(i18n.t('already_have'))}{' '}
                  </Typography>
                  <span
                    style={{
                      color: colors.info.rating,
                      marginLeft: 8,
                      cursor: 'pointer'
                    }}
                  >
                    {capitalizeFirstLetter(i18n.t('log_in'))}
                  </span>
                </Stack>
              </Box>
            ) : (
              <Slide direction="left" in={showNext} container={boxRef.current}>
                <Box sx={{ width: 437 }}>
                  <Box
                    onClick={displayHandler}
                    sx={{ cursor: 'pointer', width: 'fit-content' }}
                  >
                    <BackBlueIcon />
                  </Box>

                  <Box
                    sx={{
                      borderRadius: '12px',
                      bgcolor: 'white',
                      p: '22px 24px'
                    }}
                  >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography sx={style.txt1}>
                        {formData.restaurantName}
                      </Typography>
                      <IconButton onClick={displayHandler}>
                        <YellowEditIcon />
                      </IconButton>
                    </Stack>

                    <Stack direction={'row'} spacing={1.25} mt={1.5}>
                      <CompanyLoc2 />
                      <Typography sx={style.cardTitle}>
                        {formData.businessLocation}
                      </Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={1.25} mt={1.25}>
                      <BlueEmailIcon />
                      <Typography sx={style.cardTitle}>
                        {formData.email}
                      </Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={1.25} mt={1.25}>
                      <BluePhoneIcon />
                      <Typography sx={style.cardTitle}>
                        {`(+${formData.mobileNumber.slice(0, 3)}) ` +
                          formatPhone(formData.mobileNumber.slice(3, 20))}
                      </Typography>{' '}
                    </Stack>
                  </Box>
                  <form>
                    <AppInput
                      title={capitalizeFirstLetter(i18n.t('password'))}
                      value={formData.password}
                      onChange={formHandler('password')}
                      type={show ? 'text' : 'password'}
                      endIcon={
                        <IconButton onClick={() => setShow((prev) => !prev)}>
                          {!show ? <HideIcon /> : <RemoveRedEyeOutlinedIcon />}
                        </IconButton>
                      }
                      containerStyle={{
                        mb: { xs: 1 }
                      }}
                    />
                    <AppInput
                      title={capitalizeFirstLetter(i18n.t('confirm_password'))}
                      value={confirmPassword}
                      onChange={(ev: string) => setConfirmPassword(ev)}
                      type={show2 ? 'text' : 'password'}
                      endIcon={
                        <IconButton onClick={() => setShow2((prev) => !prev)}>
                          {!show2 ? <HideIcon /> : <RemoveRedEyeOutlinedIcon />}
                        </IconButton>
                      }
                      containerStyle={{
                        mb: { xs: 1 }
                      }}
                    />
                    {confirmPassword.trim() !== '' &&
                    formData.password.trim() !== '' &&
                    confirmPassword.trim() !== formData.password.trim() &&
                    confirmPassword.trim().length >
                      formData.password.trim().length / 2 ? (
                      <span style={{ color: 'red' }}>
                        {capitalizeFirstLetter(i18n.t('pass_desc'))}
                      </span>
                    ) : null}
                  </form>
                  {creatinRestaurant ? (
                    <CircularProgress color="success" />
                  ) : (
                    <Button
                      onClick={onRegister}
                      disabled={
                        !validatePassword(formData.password, confirmPassword)
                      }
                      sx={{
                        bgcolor: colors.primary.main,
                        color: 'white',
                        width: '100%',
                        mt: 3
                      }}
                    >
                      {capitalizeFirstLetter(i18n.t('submit'))}
                    </Button>
                  )}
                </Box>
              </Slide>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const validatePassword = (pass: string, confirm: string): boolean => {
  if (pass.trim() === '' || confirm.trim() === '') {
    return false;
  }
  if (pass.length < 8) {
    return false;
  }
  if (pass !== confirm) {
    return false;
  }
  return true;
};

const formatPhone = (phoneNumber: string) => {
  let result = '';
  for (let i = 0; i < phoneNumber.length; i += 3) {
    result += phoneNumber.slice(i, i + 3) + ' ';
  }
  return result;
};
