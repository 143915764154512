import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const useMenu = () => {
  const stateMeals = useSelector((state: RootState) => state.menu);
  const meals = Object.values(stateMeals);
  const menu = useMemo(() => Object.values(meals), [meals]);

  const number_of_review: any = useMemo(() => {
    return Object.values(menu)
      .filter((el: any) => el?.isSide === false)
      .map((el: any) =>
        Object.values(el?.rating).reduce((acc: any, curr: any) => acc + curr, 0)
      )
      .reduce((acc: any, curr: any) => acc + curr, 0);
  }, [menu]);

  const mainMeals = Object.values(menu).filter(
    (el: any) => el.isSide === false
  );

  const ratingChartList: any = Object.values(menu)
    .filter((el: any) => el?.isSide === false)
    .map((el: any) => el?.rating);

  const result = calculateTotalRatings(ratingChartList);

  return {
    menu,
    number_of_review,
    mainMeals,
    result
  };
};

function calculateTotalRatings(arrayOfObjects: { [key: number]: number }[]) {
  let totalRatings: { [key: number]: number } = {};

  arrayOfObjects.forEach((obj) => {
    Object.keys(obj).forEach((key: any) => {
      const rating = parseInt(key, 10) as number;
      if (!totalRatings[rating]) {
        totalRatings[rating] = obj[key];
      } else {
        totalRatings[rating] += obj[key];
      }
    });
  });

  for (let i = 1; i <= 5; i++) {
    if (!totalRatings[i]) {
      totalRatings[i] = 0;
    }
  }

  return totalRatings;
}
