import React from 'react';

export default function ClockIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          opacity="0.4"
          d="M9.99984 18.3307C14.6022 18.3307 18.3332 14.5998 18.3332 9.9974C18.3332 5.39502 14.6022 1.66406 9.99984 1.66406C5.39746 1.66406 1.6665 5.39502 1.6665 9.9974C1.6665 14.5998 5.39746 18.3307 9.99984 18.3307Z"
          fill="#265767"
        />
        <path
          d="M13.0919 13.2745C12.9835 13.2745 12.8752 13.2495 12.7752 13.1828L10.1919 11.6411C9.55019 11.2578 9.0752 10.4161 9.0752 9.67448V6.25781C9.0752 5.91615 9.35853 5.63281 9.7002 5.63281C10.0419 5.63281 10.3252 5.91615 10.3252 6.25781V9.67448C10.3252 9.97448 10.5752 10.4161 10.8335 10.5661L13.4169 12.1078C13.7169 12.2828 13.8085 12.6661 13.6335 12.9661C13.5085 13.1661 13.3002 13.2745 13.0919 13.2745Z"
          fill="#265767"
        />
      </svg>
    </div>
  );
}
