import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import AppInput from '../../components/AppInput';
import { capitalizeFirstLetter, checkEmail } from '../../utils/stringUtils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGetColors from '../../hooks/useGetColor';
import SwipeableViews from 'react-swipeable-views';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, CONTINOUS_URL } from '../../firebase';
import { notifyError } from '../../utils/notify';

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const colors = useGetColors();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState(0);

  const onVerifyHandler = () => {
    setLoading(true);
    sendPasswordResetEmail(auth, email, {
      url: CONTINOUS_URL
    })
      .then(() => {
        setTab(1);
      })
      .catch((error) => {
        notifyError(t('something_wrong_email'));
        console.log('error', error);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const disabled = !checkEmail(email);
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <SwipeableViews
        index={tab}
        style={{
          width: 386
        }}
      >
        <form
          style={{
            width: 386
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              color: '#333',
              mb: 1.5,
              textAlign: 'center'
            }}
          >
            {capitalizeFirstLetter(t('enter_email_description'))}
          </Typography>
          <AppInput
            title={capitalizeFirstLetter(t('email'))}
            value={email}
            onChange={(ev) => setEmail(ev)}
            type="text"
            containerStyle={{
              marginBottom: '28px',
              width: 386
            }}
          />
          {loading ? (
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Button
                variant="contained"
                onClick={onVerifyHandler}
                disabled={disabled}
                sx={{
                  color: 'white',
                  width: 386,
                  mb: 2
                }}
              >
                {capitalizeFirstLetter(t('send_email'))}
              </Button>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: colors.info.rating,
                  mt: 1.5,
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                onClick={() => navigate(-1)}
              >
                {capitalizeFirstLetter(t('go_back_login'))}
              </Typography>
            </Box>
          )}
        </form>
        <form>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 500,
              color: '#000',
              mb: 7,
              textAlign: 'center'
            }}
          >
            {capitalizeFirstLetter(t('email_already_send')) + '  '}
            <span style={{ fontWeight: 600 }}>{email + '.'}</span>
            {' ' + capitalizeFirstLetter(t('email_already_send_2'))}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              color: 'white',
              width: 386,
              mb: 2
            }}
          >
            {capitalizeFirstLetter(t('login_page'))}
          </Button>
        </form>
      </SwipeableViews>
    </Box>
  );
}
