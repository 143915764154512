import React from 'react';

export default function TitleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="133"
      height="31"
      viewBox="0 0 133 31"
      fill="none"
    >
      <path
        d="M81.9657 21.7542C80.5749 21.7542 79.4463 20.8199 79.4463 19.6622C79.4463 19.3728 79.5134 19.0986 79.6415 18.8448C79.7025 18.7178 79.7818 18.601 79.8733 18.4893C79.9648 18.3776 80.0685 18.2761 80.1783 18.1796C80.2332 18.1339 80.2942 18.0882 80.3552 18.0425C80.6298 17.8547 80.947 17.7125 81.2947 17.6363C81.4533 17.6008 81.618 17.5754 81.7827 17.5703C81.8376 17.5703 81.8925 17.5703 81.9535 17.5703C82.0146 17.5703 82.0695 17.5703 82.1244 17.5703C82.2952 17.5805 82.4538 17.6008 82.6124 17.6363C82.9601 17.7125 83.2834 17.8547 83.5518 18.0425C83.6129 18.0831 83.6739 18.1288 83.7288 18.1796C83.8447 18.2761 83.9484 18.3776 84.0338 18.4893C84.1253 18.601 84.2046 18.7178 84.2656 18.8448C84.3937 19.0936 84.4669 19.3728 84.4669 19.6572C84.4669 20.8148 83.3444 21.7542 81.9535 21.7542H81.9657Z"
        fill="#265767"
      />
      <path
        d="M104.179 13.3407C104.179 11.9466 104.179 10.0258 101.733 10.0309C101.438 10.0309 99.2935 10.1239 99.2988 12.8244L99.3094 20.0894C99.3094 21.3131 98.3904 21.7468 97.4662 21.7468C96.542 21.7468 95.5913 21.3131 95.5913 20.0945L95.5808 13.351C95.5808 11.9569 95.5808 10.0361 93.1355 10.0412C92.8398 10.0412 90.6956 10.1342 90.7009 12.8347L90.7114 20.0997C90.7114 21.3234 89.7925 21.7572 88.8682 21.7572C87.944 21.7572 86.9934 21.3234 86.9934 20.1049L86.9775 8.36311C86.9775 7.14453 87.9282 6.70564 88.8471 6.70564C89.7661 6.70564 90.2731 7.05159 90.5107 7.54729C90.6322 7.81062 90.7484 7.81062 90.8699 7.81062C91.0177 7.81062 91.1973 7.63506 91.5247 7.43369C92.3275 6.938 92.8028 6.70564 94.0809 6.70564C95.4171 6.70564 96.4892 7.10839 97.3236 7.74866C97.5296 7.89324 97.7091 7.89324 97.8306 7.89324C98.1581 7.89324 98.364 7.74866 98.7232 7.54212C99.7636 6.95865 100.952 6.69531 102.082 6.69531C105.261 6.69531 107.881 8.46122 107.886 12.2099L107.897 20.0894C107.897 21.3131 106.978 21.7468 106.054 21.7468C105.129 21.7468 104.179 21.3131 104.179 20.0945L104.168 13.351L104.179 13.3407Z"
        fill="#265767"
      />
      <path
        d="M117.885 18.3016C118.728 18.3016 119.806 18.0382 120.526 17.5681C120.974 17.2789 121.278 17.0723 121.817 17.0723C122.836 17.0723 123.652 18.0847 123.652 18.9576C123.652 19.3347 123.444 19.7737 123.054 20.063C121.465 21.2561 119.811 21.752 117.773 21.7572C112.636 21.7623 111.255 18.8595 111.244 14.2366C111.239 9.84612 112.492 6.70565 117.597 6.69532C122.131 6.69015 123.785 9.27277 123.796 13.405C123.796 14.4793 123.646 15.5847 121.998 15.5899L115.453 15.6002C115.272 15.6002 115.154 15.719 115.154 15.8894C115.245 17.4029 115.821 18.3016 117.891 18.2964L117.885 18.3016ZM117.603 9.92876C116.04 9.92876 115.202 11.0393 115.176 12.4907C115.176 12.6922 115.266 12.7541 115.389 12.7541H119.891C120.04 12.749 120.099 12.6612 120.099 12.5165C120.099 11.0651 119.315 9.92876 117.603 9.93392V9.92876Z"
        fill="#265767"
      />
      <path
        d="M71.5855 5.75393C70.4905 5.75393 69.6271 6.05163 68.8638 6.51646C68.5005 6.74627 68.3005 7.01263 68.132 7.01263C68.0004 7.01263 67.8688 7.01263 67.7319 6.71493C67.4634 6.15609 66.9001 5.75915 65.8683 5.75915C64.8365 5.75915 63.7731 6.25532 63.7783 7.63937L63.8047 24.7703C63.8047 23.6317 64.7312 22.7073 65.8788 22.7073C67.0265 22.7073 67.9583 23.6265 67.9583 24.7651V22.1066C67.9583 21.7097 68.2215 21.6784 68.3531 21.6784C68.5216 21.6784 68.7216 21.9395 69.0849 22.1745C69.8166 22.6341 70.78 22.8326 71.6118 22.8326C76.1655 22.8274 77.7185 19.1662 77.7132 14.2828C77.708 9.40469 76.1392 5.74348 71.5855 5.75393ZM70.6431 18.9103C67.9846 18.9103 67.9478 16.1787 67.9425 14.2985C67.9425 12.1885 67.9688 9.6815 70.6273 9.67628C73.0174 9.67628 73.4543 11.4207 73.4596 14.288C73.4596 17.1554 73.1016 18.905 70.6431 18.9103Z"
        fill="#265767"
      />
      <path
        d="M65.8789 22.7109C64.7312 22.7109 63.8047 23.6354 63.8047 24.774V27.3593L63.8099 28.7903C63.8099 29.9289 64.4048 30.8481 65.8894 30.8481C67.037 30.8481 67.9636 30.4251 67.9636 28.7851V27.354L67.9583 24.7687C67.9583 23.6302 67.0265 22.7109 65.8789 22.7109Z"
        fill="#265767"
      />
      <path
        d="M5.63818 5.47199C5.63818 5.2683 5.58027 5.20563 5.3697 5.20563H1.66355C0.447477 5.21085 0 4.30208 0 3.36196C0 2.42185 0.442211 1.50786 1.65829 1.50786L13.4558 1.49219C14.6719 1.49219 15.1194 2.4323 15.1194 3.34107C15.1194 4.24985 14.6772 5.19518 13.4611 5.19518H9.75495C9.57596 5.2004 9.48646 5.25785 9.48646 5.46677L9.50752 20.4615C9.50752 21.7568 8.50202 22.0806 7.58601 22.0806C6.67001 22.0806 5.65924 21.762 5.65924 20.4668L5.63818 5.47199Z"
        fill="#265767"
      />
      <path
        d="M25.6166 9.0883C25.6166 9.97096 24.6427 11.0312 23.7846 11.0312C23.1897 11.0312 22.9528 10.7962 22.4211 10.6238C21.7368 10.3888 21.442 10.3313 20.8787 10.3313C18.5097 10.3313 18.3623 11.893 18.3675 14.4522C18.3675 17.0113 18.5202 18.5677 20.8945 18.5625C21.6052 18.5625 22.2264 18.4111 23.0002 18.0298C23.4424 17.8209 23.7109 17.5859 24.2426 17.5859C25.2481 17.5859 26.0536 18.6722 26.0536 19.5549C26.0536 19.9361 25.9378 20.3174 25.5798 20.6151C24.2795 21.6753 22.558 22.0618 20.8997 22.0618C15.7406 22.067 14.5824 18.808 14.5772 14.4574C14.5719 10.1067 15.7195 6.84248 20.8787 6.83203C22.479 6.83203 23.8741 7.15062 25.0586 8.03328C25.4429 8.32576 25.6219 8.73837 25.6219 9.09352L25.6166 9.0883Z"
        fill="#265767"
      />
      <path
        d="M37.304 13.543C37.304 12.1328 37.304 10.1899 34.8666 10.1951C34.5718 10.1951 32.4344 10.2892 32.4397 13.0207L32.4502 20.3692C32.4502 21.607 31.5342 22.0458 30.613 22.0458C29.6917 22.0458 28.7441 21.607 28.7441 20.3745L28.7178 3.02419C28.7178 1.7916 29.6654 1.34766 30.5814 1.34766C31.5026 1.34766 32.4187 1.78637 32.4239 3.01896V7.60462C32.4292 7.89709 32.5187 7.95977 32.6398 7.95455C32.7872 7.95455 32.9346 7.75085 33.261 7.54194C34.0611 7.04055 34.8034 6.80552 36.1353 6.8003C39.4835 6.8003 40.9996 9.26548 41.0049 12.232L41.0154 20.3483C41.0154 21.5862 40.0994 22.0249 39.1782 22.0249C38.2569 22.0249 37.3093 21.5862 37.3093 20.3536L37.2988 13.5325L37.304 13.543Z"
        fill="#265767"
      />
      <path
        d="M130.911 21.756C129.644 21.756 128.816 20.8555 128.816 19.6912C128.816 18.4698 129.639 17.6211 130.906 17.6211C132.084 17.6211 133 18.4647 133 19.686C133 20.8504 132.089 21.756 130.911 21.756ZM129.026 2.90839C129.026 1.68707 128.455 0.00517507 130.864 0C133.325 0 132.707 1.67672 132.712 2.90322L132.728 14.3815C132.728 15.5459 131.817 15.9858 130.9 15.9858C129.984 15.9858 129.042 15.5511 129.042 14.3867L129.026 2.90839Z"
        fill="#265767"
      />
      <path
        d="M61.011 10.0149C60.6194 10.0954 60.1043 9.96666 59.498 9.25841C57.9044 10.4442 57.6576 9.0116 57.6576 9.0116C57.6576 9.0116 55.6509 8.71113 56.7723 6.61858C56.0211 6.25909 55.8226 5.82985 55.8387 5.45963C54.9694 5.17526 54.0412 5.0143 53.0754 5.01966C48.2249 5.02503 44.2974 8.96331 44.3027 13.8191C44.3081 18.6695 48.2464 22.5971 53.1022 22.5917C57.9527 22.5863 61.8803 18.648 61.8749 13.7923C61.8749 12.4402 61.5637 11.1632 61.0164 10.0203L61.011 10.0149ZM53.0754 8.86136C55.7796 8.86136 57.9795 11.0451 57.9849 13.7547C57.9902 16.4643 55.8011 18.6588 53.0915 18.6641C50.3873 18.6641 48.1874 16.4804 48.182 13.7708C48.182 11.0666 50.3658 8.86673 53.0754 8.86136Z"
        fill="#D77910"
      />
    </svg>
  );
}
