import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Palette from './themes/palette';
import { useMemo } from 'react';
import CustomShadows from './themes/shadows';
import ComponentsOverrides from './themes/overrides';
import router from './routes/routes';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from './themes/typography';
// import FONTS from './themes/font';
import './i18n-config';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import SessionExpiredModal from './components/SessionExpiredModal';

function App() {
  const theme = Palette('light');

  const { sessionExpired } = useSelector((state: RootState) => state.ui);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = Typography();
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536
        }
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography
    }),
    [theme, themeTypography, themeCustomShadows]
  );

  const themes = createTheme(themeOptions as any);
  themes.components = ComponentsOverrides(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <RouterProvider router={router} />
          <SessionExpiredModal visible={sessionExpired} />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
