import { ReactNode, useEffect, useLayoutEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AvailabilityIcon,
  CompanyinfoIcon,
  PaymentMethodIcon,
  SecurityIcon,
  ThemeIcon
} from '../../assets/icons';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import PanelItem from '../../components/PanelItem';

type MenuItem = {
  key: string;
  title: string;
  subtitle: string;
  icon: ReactNode;
};

const MENU_ITEMS: MenuItem[] = [
  {
    key: 'company_info',
    title: 'company_info',
    subtitle: 'company_info_desc',
    icon: CompanyinfoIcon
  },
  {
    key: 'availabilities',
    title: 'availabilities',
    subtitle: 'availabilities_desc',
    icon: AvailabilityIcon
  },
  // {
  //   key: 'payment_method',
  //   title: 'payment_method',
  //   subtitle: 'payment_method_desc',
  //   icon: PaymentMethodIcon
  // },
  // {
  //   key: 'security',
  //   title: 'security',
  //   subtitle: 'security_desc',
  //   icon: SecurityIcon
  // },
  {
    key: 'theme',
    title: 'theme',
    subtitle: 'theme_desc',
    icon: ThemeIcon
  }
];

function Settings() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onItemPress = (item: string) => {
    navigate(item);
  };

  const getRouteName = (routeName: string) => {
    if (routeName.includes('company_info')) {
      return capitalizeFirstLetter(t('company_info'));
    }
    if (routeName.includes('availabilities')) {
      return capitalizeFirstLetter(t('availabilities'));
    }
    // if (routeName.includes('payment_method')) {
    //   return capitalizeFirstLetter(t('payment_method'));
    // }
    if (routeName.includes('security')) {
      return capitalizeFirstLetter(t('security'));
    }
    if (routeName.includes('theme')) {
      return capitalizeFirstLetter(t('theme'));
    }
  };

  useLayoutEffect(() => {
    if (pathname === '/settings') {
      navigate('/settings/company_info');
    }
  }, [pathname, navigate]);
  const renderItem = (item: MenuItem) => {
    return (
      <PanelItem
        key={item.key}
        title={t(item.title) + ''}
        subtitle={t(item.subtitle) + ''}
        titleStyle={{
          color: pathname?.includes(item.key) ? '#265767' : '#333333'
        }}
        icon={item.icon}
        focused={pathname?.includes(item.key)}
        onClick={() => onItemPress(item.key)}
      />
    );
  };

  return (
    <Box pt={1}>
      <Box mb={1} p={2} sx={{ backgroundColor: 'white', borderRadius: 1 }}>
        <Typography>{getRouteName(pathname)}</Typography>
      </Box>
      <Grid container width={1} bgcolor="#fff" sx={{ borderRadius: 1 }}>
        <Grid
          item
          md={3}
          xs={12}
          bgcolor="#fff"
          sx={{ borderRight: '1px solid #F4F4F5' }}
        >
          <Box>{MENU_ITEMS.map(renderItem)}</Box>
        </Grid>
        <Grid item md={9} xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Settings;
