import * as React from 'react';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Popover, SxProps, Typography } from '@mui/material';
import './AppPopper.css';
import i18n from '../../i18n-config';
import AppButton from '../button/AppButton';

export default function AppPopper({
  icon,
  showHeader = false,
  buttonStyle,
  containerStyle,
  placement = 'right',
  closeOnClick = false,
  children,
  popperStyle,
  showButton = false,
  show = true,
  title
}: {
  icon?: JSX.Element;
  showHeader?: boolean;
  showButton?: boolean;
  closeOnClick?: boolean;
  placement?: 'right' | 'center' | 'left';
  buttonStyle?: React.CSSProperties | SxProps;
  containerStyle?: React.CSSProperties;
  popperStyle?: React.CSSProperties;
  onClose?: () => void;
  children?: React.ReactNode;
  show?: boolean;
  title?: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onMouseClick = () => {
    if (closeOnClick) {
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Box sx={{ mb: 0.5 }}>{title}</Box>
      <IconButton
        sx={{
          backgroundColor: '#E6EAEE',
          height: 45,
          width: 45,
          borderRadius: '8px',
          ...buttonStyle
        }}
        disableRipple
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Popover
        id={id}
        open={show && open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: placement
        }}
        sx={{ ...popperStyle }}
      >
        <Box
          sx={{
            borderRadius: 2,
            p: 3,
            ...containerStyle
          }}
          onClick={onMouseClick}
        >
          {showHeader ? (
            <Box className="flex-row-b">
              <Typography className="text-20 font-600" color="#585572">
                {i18n.t('filter')}
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          ) : null}
          {children}
          {showButton ? (
            <Box className="flex-row-end" sx={{ mt: '80px' }}>
              <AppButton
                title={i18n.t('apply_filter')}
                containerStyle={{ width: '50%' }}
              />
            </Box>
          ) : null}
        </Box>
      </Popover>
    </Box>
  );
}
