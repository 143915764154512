/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { gettingDrivers, submittingReport } from './ui';
import {
  query,
  collection,
  where,
  getDocs,
  DocumentData,
  doc
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { COLLECTIONS_NAMES } from '../utils/constant';
import { v4 } from 'uuid';
import { setDoc } from '@firebase/firestore';

interface Driver {
  avatar?: string;
  driverName?: string;
  driverId?: string;
  contact?: string;
  registrationDate?: string;
  totalDeliveries?: number;
  paid?: number;
}

export interface DriverState {
  driver: Driver;
  idList: string[];
}

const initialState: DriverState = {
  driver: {},
  idList: []
};

// ==============================|| SLICE - MENU ||============================== //

const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    setDriversList(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.id] = el;
      }

      return { ...state, driver: { ...temp } };
    },
    setIdList(state, { payload }) {
      return { ...state, idList: payload };
    }
  }
});

export default driverSlice.reducer;

export const { setDriversList, setIdList } = driverSlice.actions;

export const getDriverListAsync =
  (driverList: string[], callBack?: () => void, onError?: (er: any) => void) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(gettingDrivers(true));

      const fetchPromises: Promise<DocumentData[]>[] = (driverList || []).map(
        (id) => fetchDataById(id)
      );

      Promise.all<DocumentData[]>(fetchPromises)
        .then((results) => {
          // Combine the results into a single array
          const allDrivers = ([] as DocumentData[]).concat(...results);
          dispatch(setDriversList(allDrivers));
        })
        .catch((error) => {
          console.error('Error fetching drivers:', error);
        });
      dispatch(gettingDrivers(false));

      callBack?.();
    } catch (error) {
      onError?.(error);
      console.log('Driver request list errors', error);
    }
  };

export const onSubmitReportAsync =
  (data: any, callBack?: () => void, onError?: (er: any) => void) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(submittingReport(true));
      const requestId = v4();

      const data_to_save = {
        id: requestId,
        createdAt: new Date().getTime(),
        updateAt: null,
        ...data
      };
      await setDoc(
        doc(firestore, COLLECTIONS_NAMES.REPORTS, requestId),
        data_to_save
      );
    } catch (error) {
      console.log('submmit  error', error);
      onError?.(error);
    } finally {
      dispatch(submittingReport(false));
      callBack?.();
    }
  };

async function fetchDataById(id: string) {
  try {
    const driverRef = query(
      collection(firestore, COLLECTIONS_NAMES.DRIVERS),
      where('id', '==', id)
    );

    const querySnapshot = await getDocs(driverRef);
    const driverData = querySnapshot.docs.map((doc) => doc.data());
    return driverData;
  } catch (error) {
    console.error('Error fetching driver:', error);
    throw error;
  }
}
