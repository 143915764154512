import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { InputAdornment, Stack, Typography } from '@mui/material';
import TimeICON from '../../assets/icons/ClockIcon';
import { useState } from 'react';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useLocale from '../../hooks/useLocale';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
// import { locale } from 'dayjs';

export default function TimeInput({
  value,
  onChange,
  title,
  placeholder,
  disabled = false
}: {
  value?: any;
  title?: string;
  minDate?: any;
  placeholder?: string;
  maxDate?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (val: any) => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const { local } = useLocale();
  const handleOpen = () => setOpen((prev) => !prev);
  return (
    <Stack spacing={2}>
      {title && (
        <Typography variant="subtitle2" color="#344051">
          {capitalizeFirstLetter(title)}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={local}>
        <MobileTimePicker
          views={['hours', 'minutes']}
          onChange={(ev) => onChange?.(dayjs(ev).toDate())}
          disabled={disabled}
          onClose={handleOpen}
          value={dayjs(value)}
          open={open}
          ampm={false}
          slotProps={{
            textField: {
              InputProps: {
                placeholder,
                endAdornment: (
                  <InputAdornment
                    onClick={handleOpen}
                    position="end"
                    sx={{ cursor: 'pointer' }}
                  >
                    <TimeICON />
                  </InputAdornment>
                )
              }
            }
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
}
