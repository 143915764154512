import { Box, Stack, Grid, Typography, Button } from '@mui/material';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useGetColors from '../../hooks/useGetColor';
import TitleIcon from '../../assets/icons/TitleIcon';
import DonutTop from '../../assets/icons/DonutTop';
import DonutBottom from '../../assets/icons/DonutBottom';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import RegisterPage from '../../pages/auth/RegisterPage';
import AuthLay from '../../assets/icons/auth/AuthLay';
import BugleIcon from '../../assets/icons/auth/BugleIcon';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import HelpLoginIcon from '../../assets/icons/login/HelpLoginIcon';
import AppPopper from '../../components/popper/AppPopper';
import WhatsappIcon from '../../assets/icons/login/WhatsappIcon';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const { pathname } = useLocation();
  const colors = useGetColors();
  const { restaurant } = useAuth();
  const { t } = useTranslation();

  if (pathname === '/register') {
    return <RegisterPage />;
  }

  if (restaurant) {
    return <Navigate to="/" />;
  }

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        display: 'flex'
      }}
    >
      <Grid
        item
        sx={{
          bgcolor: colors.primary.main
        }}
        xs={0}
        md={4}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BugleIcon />
          <AuthLay />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 4,
          height: 'calc(100% -250px)'
        }}
      >
        <Stack
          direction={'row'}
          mb={2}
          justifyContent={'end'}
          sx={{
            width: 1
          }}
        >
          <AppPopper
            buttonStyle={{ bgcolor: 'none' }}
            icon={<HelpLoginIcon />}
            closeOnClick
          >
            <Stack
              spacing={1}
              direction={'column'}
              sx={{ maxWidth: 280, textAlign: 'center', mb: 2 }}
            >
              <Typography
                sx={{ fontSize: 24, fontWeight: 500, color: '#D77910' }}
              >
                {capitalizeFirstLetter(t('customer_support'))}
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 400, color: '#27272A' }}
              >
                {capitalizeFirstLetter(t('customer_support_desc'))}
              </Typography>
            </Stack>

            <Stack direction={'column'} spacing={2} maxWidth={280}>
              <Button
                variant="contained"
                sx={{ width: 280 }}
                onClick={() => {
                  window.open(
                    'https://tchopme.com/foireauquestionsclients',
                    '_blank'
                  );
                }}
              >
                {capitalizeFirstLetter('FAQ')}
              </Button>

              <Button
                variant="text"
                sx={{ color: '#25D366' }}
                startIcon={<WhatsappIcon />}
                onClick={() => {
                  window.open(
                    `https://web.whatsapp.com/send?phone=654545428&text=Hello&app_absent=0`,
                    '_blank'
                  );
                }}
              >
                {capitalizeFirstLetter(t('whatsapp_us'))}
              </Button>
            </Stack>
          </AppPopper>
        </Stack>
        <Typography
          sx={{
            fontSize: 32,
            fontWeight: 500,
            color: colors.primary.main,
            mb: 7,
            textAlign: 'center',
            maxWidth: 500
          }}
        >
          {capitalizeFirstLetter(t('login_welcome'))}
        </Typography>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default MinimalLayout;
