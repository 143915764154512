import { useSelector } from 'react-redux';
import 'dayjs/locale/fr';
import { RootState } from '../store';

export default function useLocale() {
  const { systemLanguage } = useSelector((state: RootState) => state.ui.lang);
  const formatedDate = (local: string) => {
    return local === 'en' ? 'hh:mm' : 'HH:mm';
  };
  return {
    local: systemLanguage,
    formatedDate
  };
}
