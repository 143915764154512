// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: Record<string, any>) => {
  const { red, gold, cyan, green, grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';
  const active = `#5089A1`;

  return {
    primary: {
      main: '#265767',
      active: '#5089A1'
    },
    accent: '#D77910',
    secondary: {
      custom: '#5089A1',
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500],
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: '#B76E00'
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: '#00B8D9',
      dark: cyan[7],
      darker: cyan[9],
      contrastText,
      active,
      outlet: `#F3F4F6`,
      text1: `#292D32`,
      bottom: '#E6E6E6',
      float: '#8392A1',
      black2: '#333',
      whiteVariant: '#F4F4F5',
      rating: '#D77910',
      text2: '#384250',
      black3: '#344051',
      butt2: '#F7E4CF',
      red1: '#EB5757',
      black: '#000',
      list: '#475467',
      divider: '#E4E4E7',
      drawer: '#F9F9F9',
      orange2: '#F59E0B',
      marron1: '#71717A',
      gray800: '#27272A',
      gray700: '#3F3F46',
      txtSh: '#1F2A37',
      txtCh2: '#52525B',
      gray400: '#A1A1AA',
      delivery: '#265767',
      deliverySuccess: '#13CE66',
      gray750: '#344054',
      divide: '#F2F4F7',
      pinkButt: '#FEF2F2',
      red2: '#EF4444',
      blcv2: '#383838',
      grayNeutral500: '#6C737F',
      grayNeutral600: '#4D5761',
      grayNeutra300: '#637083',
      bg2: '#F9FAFB',
      txt3: '#4A4A4A',
      blue50: '#EFF6FF',
      warning: '#FFEDD5',
      warn2: '#FC9A2C',
      loc: '#667085',
      newBlack: '#101828'
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: '#22C55E',
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors
  };
};

export default Theme;
