/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function NoImageAltIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M44.0403 33.6405L37.7803 19.0005C36.6403 16.3205 34.9403 14.8005 33.0003 14.7005C31.0803 14.6005 29.2203 15.9405 27.8003 18.5005L24.0003 25.3205C23.2003 26.7605 22.0603 27.6205 20.8203 27.7205C19.5603 27.8405 18.3003 27.1805 17.2803 25.8805L16.8403 25.3205C15.4203 23.5405 13.6603 22.6805 11.8603 22.8605C10.0603 23.0405 8.52028 24.2805 7.50028 26.3005L4.04028 33.2005C2.80028 35.7005 2.92028 38.6005 4.38028 40.9605C5.84028 43.3205 8.38027 44.7405 11.1603 44.7405H36.6803C39.3603 44.7405 41.8603 43.4005 43.3403 41.1605C44.8603 38.9205 45.1003 36.1005 44.0403 33.6405Z"
        fill="#8392A2"
      />
      <path
        d="M13.9397 16.7602C17.6731 16.7602 20.6997 13.7337 20.6997 10.0002C20.6997 6.26679 17.6731 3.24023 13.9397 3.24023C10.2062 3.24023 7.17969 6.26679 7.17969 10.0002C7.17969 13.7337 10.2062 16.7602 13.9397 16.7602Z"
        fill="#8392A2"
      />
    </svg>
  );
}
