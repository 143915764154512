import React from 'react';

export default function DonutTop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="34"
      viewBox="0 0 51 34"
      fill="none"
    >
      <path
        d="M25.959 8.66473V7.39733C27.2782 6.76777 28.1967 5.43409 28.1883 3.88505C28.1883 1.73957 26.4265 0 24.264 0C22.1014 0 20.348 1.74786 20.348 3.89333C20.348 5.44238 21.2665 6.77605 22.5941 7.39733V8.77242C9.88596 10.0647 -0.0166781 20.7175 2.10891e-05 33.6649L50.3649 33.5903C50.3482 20.0631 39.4853 9.07063 25.959 8.66473Z"
        fill="#EFC00A"
      />
      <path
        d="M6.3125 28.2402C6.3125 28.2402 8.39155 18.1839 15.3802 15.707C10.2953 23.502 10.8547 28.232 10.8547 28.232H6.3125V28.2402Z"
        fill="white"
      />
    </svg>
  );
}
