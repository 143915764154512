import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  SxProps
} from '@mui/material';
import { ReactNode } from 'react';
import useGetColors from '../hooks/useGetColor';
import { capitalizeFirstLetter } from '../utils/stringUtils';
import SvgContainer from './SvgContainer';

interface Props extends ListItemProps {
  title?: string;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  onClick?(): void;
  focused?: boolean;
  isUniqueColor?: boolean;
  titleStyle?: SxProps;
}

export default function PanelItem({
  title,
  subtitle,
  icon,
  onClick,
  focused,
  isUniqueColor = false,
  titleStyle
}: Props) {
  const colors = useGetColors();

  return (
    <ListItem
      disableGutters
      sx={{
        // my: 2,
        backgroundColor: focused ? '#5089A1' + '20' : 'transparent',
        '&:hover': {
          backgroundColor: focused ? '#5089A1' + '20' : '#00000009'
        },
        borderBottom: '1px solid #F4F4F5'
      }}
      onClick={onClick}
    >
      <ListItemButton
        disableRipple
        disableTouchRipple
        sx={{
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <ListItemIcon
          sx={{
            width: 48,
            height: 48,
            borderRadius: '8px',
            backgroundColor: focused ? '#5089A1' : '#F3F4F6',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #E5E7EB'
          }}
        >
          <SvgContainer
            svg={icon}
            color={focused ? '#ffffff' : '#8292a1'}
            isUniqueColor={isUniqueColor}
            rootStyle={{ paddingTop: 0.5 }}
            width={32}
          />
        </ListItemIcon>
        <ListItemText
          primary={capitalizeFirstLetter(title || '')}
          secondary={subtitle}
          primaryTypographyProps={{
            sx: { fontWeight: 600, color: colors.primary.main, ...titleStyle }
          }}
          secondaryTypographyProps={{
            sx: { color: '#8392A1', fontWeight: 500, fontSize: 11 }
          }}
          sx={{ color: focused ? '#ED6918' : undefined, ml: 2 }}
        />
      </ListItemButton>
    </ListItem>
  );
}
