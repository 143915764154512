import {
  Box,
  Collapse,
  InputAdornment,
  InputBase,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
interface Props {
  title?: string | ReactNode | null;
  placeholder?: string;
  titleStyle?: React.CSSProperties | SxProps;
  inputStyle?: React.CSSProperties;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  value?: string;
  type?: string;
  multiline?: boolean;
  maxRows?: number;
  containerStyle?: SxProps;
  required?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  renderEndAdornment?: () => ReactNode;
  disabled?: boolean;
}

const AppInput: React.FC<Props> = ({
  title,
  type,
  titleStyle,
  inputStyle,
  placeholder,
  containerStyle,
  startIcon,
  endIcon,
  onChange,
  value,
  multiline = false,
  maxRows = 1,
  required = false,
  disabled = false,
  error,
  renderEndAdornment
}) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(ev.target.value);
  };

  const [borderColor, setBorderColor] = useState('#CED2DA');

  const onFocus = () => {
    setBorderColor('#8392A2');
  };
  const onBlur = () => {
    setBorderColor('#CED2DA');
  };

  return (
    <Box sx={{ minWidth: '230px', ...containerStyle }}>
      {title ? (
        <Stack direction={'row'} spacing={'10px'}>
          <Typography
            sx={{
              color: '#344051',
              fontSize: 14,
              fontWeight: 500,
              ...titleStyle
            }}
          >
            {title}
          </Typography>
          {required ? (
            <span
              style={{
                color: 'red',
                marginLeft: 10
              }}
            >
              *
            </span>
          ) : null}
        </Stack>
      ) : null}
      <InputBase
        className={disabled ? 'disabled-input' : ''}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        multiline={multiline}
        maxRows={maxRows}
        disabled={disabled}
        type={type || 'text'}
        error={true}
        sx={{
          border: `1px solid ${error ? 'red' : borderColor}`,
          height: 45,
          color: disabled ? 'red' : '#292D32',
          fontSize: '16px',
          padding: '10px 8px 10px 12px',
          borderRadius: '8px',
          marginTop: title ? '6px' : 0,
          backgroundColor: '#fff',
          width: '100%',
          ...inputStyle
        }}
        startAdornment={
          <InputAdornment position="start">{startIcon}</InputAdornment>
        }
        endAdornment={
          renderEndAdornment?.() ?? (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          )
        }
        autoComplete="on"
      />
      <Collapse in={error ? true : false}>
        <small style={{ color: 'red' }}>{error}</small>
      </Collapse>
    </Box>
  );
};
export default AppInput;
