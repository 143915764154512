/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import { AppDispatch, RootState } from '../../store';
import { openDrawer, welcomeHandler } from '../../store/ui';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { getRestaurantDataAsync, onLogout } from '../../store/auth';
import useFirestoreListener from '../../hooks/useFirestoreListener';
import useInit from '../../hooks/useInit';
import useAuth from '../../hooks/useAuth';
import { notifyError } from '../../utils/notify';
import i18n from '../../i18n-config';
import {
  getOrderHistoryAsync,
  getOrderHistoryCancellledAsync
} from '../../store/orders';
import WelcomeModal from '../../components/WelcomeModal';
import useGetColors from '../../hooks/useGetColor';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const { restaurant } = useAuth();
  const colors = useGetColors();

  const { drawerOpen, welcomeOpen } = useSelector(
    (state: RootState) => state.ui
  );
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const init = useInit();
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);

  // welcome modal toggle
  const [show, setShow] = useState(welcomeOpen);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const _onAuthStateChanged = useCallback(
    (userState: any) => {
      if (userState) {
        dispatch(
          getRestaurantDataAsync({
            UserID: userState.uid
            // callback: callBack
          }) as any
        );
      } else {
        dispatch(onLogout() as any);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    try {
      const subscriber = onAuthStateChanged(auth, _onAuthStateChanged);
      return subscriber;
    } catch (error) {
      console.log('error from auth state change', error);
    }
  }, [_onAuthStateChanged]);

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);
  useEffect(() => {
    if (restaurant) {
      dispatch(getOrderHistoryAsync(restaurant.id) as any);
      dispatch(getOrderHistoryCancellledAsync(restaurant.id) as any);
    }
  }, [dispatch, restaurant]);

  useEffect(() => {
    if (
      pathname === '/settings/company_info' ||
      (restaurant?.location?.longitude && restaurant?.location?.latitude)
    ) {
      // && !restaurant.location
      dispatch(welcomeHandler(false));
    } else {
      dispatch(welcomeHandler(true));
    }
    setShow(welcomeOpen);
  }, [dispatch, pathname, restaurant?.location, welcomeOpen]);

  useFirestoreListener({
    dispatch,
    restaurant
  });

  if (!restaurant) {
    return <Navigate to="/login" />;
  }

  if (restaurant && (restaurant.isArchived || restaurant.isBlocked)) {
    notifyError(
      i18n.t(
        restaurant.isArchived
          ? 'restaurant_is_archived'
          : 'restaurant_is_blocked'
      )
    );
    dispatch(onLogout() as any);
    return <Navigate to="/login" />;
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Header
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        show={matchDownLG}
      />
      <WelcomeModal
        open={show}
        restauName={restaurant?.name}
        onConfirm={() =>
          navigate('settings/company_info', { state: { showMap: true } })
        }
      />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          bgcolor: 'info.outlet',
          width: '100%',
          height: '100vh'
        }}
      >
        <Toolbar />
        <Box sx={{ width: '100%', height: 'calc(100% - 60px)' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
