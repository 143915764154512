import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import AppInput from '../../../components/AppInput';
import AppPopper from '../../../components/popper/AppPopper';

interface Props {
  color: string;
  keyVal: string;
  title: string;
  title_desc: string;
  // eslint-disable-next-line no-unused-vars
  handleColorChange: (keyVal: string, value: string) => void;
}

function AdminColorInput({
  color,
  keyVal,
  title,
  title_desc,
  handleColorChange
}: Props) {
  const [show, setShow] = useState(false);
  const handleShowOpen = () => setShow(!show);

  return (
    <Box>
      <Typography
        // className="admin-title4"
        mt={3}
        fontSize={16}
        fontWeight={600}
        color={'#475467'}
      >
        {title}
      </Typography>
      <Grid container rowSpacing={2}>
        <Grid item md={5}>
          <Typography fontSize={14} fontWeight={400} color={'#475467'} mt={1}>
            {title_desc}
          </Typography>
        </Grid>
        <Grid
          item
          md={7}
          alignItems={'end'}
          justifyContent={'end'}
          flexDirection={'row'}
        >
          <Stack
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flexDirection: 'end',
              direction: 'row',
              width: '100%'
            }}
          >
            <AppInput
              startIcon={<Typography>#</Typography>}
              value={color?.replace('#', '')}
              onChange={(value: string) => handleColorChange(keyVal, value)}
              endIcon={
                <AppPopper
                  // open={show}
                  icon={
                    <IconButton onClick={handleShowOpen}>
                      <Box
                        className="box-icon-ctn"
                        sx={{
                          backgroundColor: color
                            ? color?.includes('#')
                              ? color
                              : '#' + color
                            : '#585572'
                        }}
                      />
                    </IconButton>
                  }
                  // placement="bottom"
                  onClose={handleShowOpen}
                  containerStyle={{ padding: 0 }}
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    width: 0,
                    height: 10
                  }}
                >
                  <Box>
                    <SketchPicker
                      color={color}
                      onChangeComplete={(value: any) =>
                        handleColorChange(keyVal, value.hex)
                      }
                    />
                  </Box>
                </AppPopper>
              }
              inputStyle={{ width: '230px' }}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Typography className="admin-title3" width="40%" mt={1}>
          {title_desc}
        </Typography>
        <AppInput
          startIcon={<Typography>#</Typography>}
          value={color?.replace('#', '')}
          onChange={(value: string) => handleColorChange(keyVal, value)}
          endIcon={
            <AppPopper
              // open={show}
              icon={
                <IconButton onClick={handleShowOpen}>
                  <Box
                    className="box-icon-ctn"
                    sx={{
                      backgroundColor: color
                        ? color?.includes('#')
                          ? color
                          : '#' + color
                        : '#585572'
                    }}
                  />
                </IconButton>
              }
              // placement="bottom"
              onClose={handleShowOpen}
              containerStyle={{ padding: 0 }}
              buttonStyle={{
                backgroundColor: 'transparent',
                width: 0,
                height: 10
              }}
            >
              <Box>
                <SketchPicker
                  color={color}
                  onChangeComplete={(value: any) =>
                    handleColorChange(keyVal, value.hex)
                  }
                />
              </Box>
            </AppPopper>
          }
          inputStyle={{ width: '230px' }}
        />
      </Stack> */}
      <Divider sx={{ my: 3 }} />
    </Box>
  );
}

export default AdminColorInput;
