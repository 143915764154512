import React from 'react';

export default function BackBlueIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M19.5 35.75C28.4746 35.75 35.75 28.4746 35.75 19.5C35.75 10.5254 28.4746 3.25 19.5 3.25C10.5254 3.25 3.25 10.5254 3.25 19.5C3.25 28.4746 10.5254 35.75 19.5 35.75Z"
        fill="#265767"
      />
      <path
        d="M25.1875 18.2811H16.7537L19.5487 15.4861C20.02 15.0148 20.02 14.2348 19.5487 13.7636C19.0775 13.2923 18.2975 13.2923 17.8262 13.7636L12.9512 18.6386C12.48 19.1098 12.48 19.8898 12.9512 20.3611L17.8262 25.2361C18.07 25.4798 18.3787 25.5936 18.6875 25.5936C18.9962 25.5936 19.305 25.4798 19.5487 25.2361C20.02 24.7648 20.02 23.9848 19.5487 23.5136L16.7537 20.7186H25.1875C25.8537 20.7186 26.4062 20.1661 26.4062 19.4998C26.4062 18.8336 25.8537 18.2811 25.1875 18.2811Z"
        fill="#265767"
      />
    </svg>
  );
}
