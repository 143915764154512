// types
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n-config';
import { AppDispatch } from '.';

export interface ThemeProps {
  primary: any;
  secondary: any;
  accent: string;
}

// initial state
export const initialState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  welcomeOpen: true,
  componentDrawerOpen: true,
  sessionExpired: false,
  gettingDrivers: false,
  gettingOrders: false,
  gettingCustomers: false,
  submittingReport: false,
  restaurantExists: false,
  authIsLoaded: false,
  creatinRestaurant: false,
  updatingMenu: false,
  updatingOrderStatus: false,
  app_config: {},
  lang: {
    systemLanguage: 'fr',
    languages: [
      {
        lang: 'english',
        key: 'en'
      },
      {
        lang: 'french',
        key: 'fr'
      }
    ]
  },
  theme: {
    primary: { main: '#325665' },
    secondary: {
      custom: '#5089A1'
    },
    accent: '#D77910'
  }
};

// ==============================|| SLICE - MENU ||============================== //

const ui = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
    welcomeHandler: (state, { payload }) => {
      state.welcomeOpen = payload;
    },
    toggleSessionExpired: (state, action) => {
      state.sessionExpired = action.payload;
      return state;
    },
    setLanguage: (state, action) => {
      state.lang = { ...state.lang, systemLanguage: action.payload };
      return state;
    },
    gettingDrivers(state, { payload }) {
      state.gettingDrivers = payload;
    },
    submittingReport(state, { payload }) {
      state.submittingReport = payload;
    },
    gettingOrders(state, { payload }) {
      state.gettingOrders = payload;
    },
    updatingMenu(state, { payload }) {
      state.updatingMenu = payload;
    },
    gettingCustomers(state, { payload }) {
      state.gettingCustomers = payload;
    },
    authIsLoaded(state, { payload }) {
      state.authIsLoaded = payload;
    },
    creatinRestaurant(state, { payload }) {
      state.creatinRestaurant = payload;
    },
    updatingOrderStatus(state, { payload }) {
      state.updatingOrderStatus = payload;
    },
    changeTheme: (state, action) => {
      state.theme = action.payload;
      return state;
    },
    addAppconfig(state, { payload }) {
      state.app_config = payload;
    }
  }
});

export default ui.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  openComponentDrawer,
  toggleSessionExpired,
  setLanguage,
  submittingReport,
  gettingDrivers,
  changeTheme,
  gettingOrders,
  gettingCustomers,
  creatinRestaurant,
  authIsLoaded,
  updatingOrderStatus,
  updatingMenu,
  addAppconfig,
  welcomeHandler
} = ui.actions;

export const changeLanguage = (language: string) => (dispatch: AppDispatch) => {
  try {
    console.log('execute', language);
    // await AsyncStorage.setItem('LANG', language);
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
  } catch (error) {
    console.log('change language error', error);
  }
};
