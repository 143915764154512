export function validateRegister(
  email: string,
  restaurantName: string,
  businessLocation: string,
  phoneNumber: string
): boolean {
  if (!email || !restaurantName || !businessLocation || !phoneNumber) {
    return false;
  }
  if (
    email.trim() === '' ||
    restaurantName.trim() === '' ||
    businessLocation.trim() === '' ||
    phoneNumber.trim() === ''
  ) {
    return false;
  }
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailPattern.test(email)) {
    return false;
  }

  return true;
}

function isFile(variable: any): variable is File {
  return variable instanceof File;
}

export function filterByFileType(array: any[]): any[] {
  return array.filter((item) => isFile(item.image));
}
