/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { gettingCustomers } from './ui';
import {
  query,
  collection,
  where,
  getDocs,
  DocumentData
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { COLLECTIONS_NAMES } from '../utils/constant';

interface Customers {}

export interface CustomerState {
  customer: Customers;
  userIdList: string[];
}

const initialState: CustomerState = {
  customer: {},
  userIdList: []
};

// ==============================|| SLICE - MENU ||============================== //

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerList(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.id] = el;
      }

      return { ...state, customer: { ...temp } };
    },
    setUserIdList(state, { payload }) {
      return { ...state, userIdList: payload };
    }
  }
});

export default customerSlice.reducer;

export const { setCustomerList, setUserIdList } = customerSlice.actions;

export const getCustomerListAsync =
  (
    customerList: string[],
    callBack?: () => void,
    onError?: (er: any) => void
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(gettingCustomers(true));

      const fetchPromises: Promise<DocumentData[]>[] = (customerList || []).map(
        (id) => fetchDataById(id)
      );

      // Use Promise.all to execute all promises simultaneously
      Promise.all<DocumentData[]>(fetchPromises)
        .then((results) => {
          // Combine the results into a single array
          const allCustomers = ([] as DocumentData[]).concat(...results);
          dispatch(setCustomerList(allCustomers));
        })
        .catch((error) => {
          console.error('Error fetching Customer:', error);
        });
    } catch (error) {
      onError?.(error);
      console.log('Customer request list errors', error);
    } finally {
      dispatch(gettingCustomers(false));
      callBack?.();
    }
  };

async function fetchDataById(id: string) {
  try {
    const customerRef = query(
      collection(firestore, COLLECTIONS_NAMES.CUSTOMERS),
      where('id', '==', id)
    );

    const querySnapshot = await getDocs(customerRef);
    const customerData = querySnapshot.docs.map((doc) => doc.data());
    return customerData;
  } catch (error) {
    console.error('Error fetching Customer:', error);
    throw error;
  }
}
