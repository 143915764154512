import { NotificationManager } from 'react-notifications';

export default function notifySuccess(message?: string) {
  NotificationManager.success(
    'Réussi',
    message || 'opération réalisé avec success'
  );
}
export function notifyError(message?: string) {
  NotificationManager.error('Erreur', message || "une s'est produite");
}

export function notifyInfo(message?: string) {
  NotificationManager.info(message);
}
