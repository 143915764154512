/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

interface Tag {
  key: string;
  title: string;
}

const initialState: Tag[] = [];

// ==============================|| SLICE - MENU ||============================== //

const tagSlice = createSlice({
  name: 'tag',
  initialState: {},
  reducers: {
    listTags: (state, action) => {
      const temp: any = {};
      for (const el of action.payload) {
        temp[el.id] = el;
      }
      return { ...state, ...temp };
    }
  }
});

export default tagSlice.reducer;

export const { listTags } = tagSlice.actions;
