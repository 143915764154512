// material-ui
import { useTheme, SxProps, styled } from '@mui/material/styles';
import {
  Stack,
  Box,
  Typography,
  Tooltip,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors
} from '@mui/material';
import useGetColors from '../../../hooks/useGetColor';
import PointIcon from '../../../assets/icons/home/PointIcon';
import NewStarIcon from '../../../assets/icons/drawer/NewStarIcon';
import useAuth from '../../../hooks/useAuth';
import { calculateAverageRating } from '../../../utils/stringUtils';
// import Logo from '../../../../components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, sx }: { theme: any; open: boolean; sx?: SxProps }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
  ...sx
}));

const DrawerHeader = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const { restaurant, restauRating } = useAuth();
  const colors = useGetColors();
  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{ bgcolor: colors.primary.main }}
    >
      <ListItem disablePadding>
        <ListItemAvatar sx={{ ml: 1 }}>
          <Avatar src={restaurant?.logoURL} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Tooltip title={restaurant.name} placement="top-start">
              <Typography
                sx={{
                  color: 'info.contrastText',
                  fontSize: 24,
                  fontWeight: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '90%'
                }}
              >
                {restaurant.name}
              </Typography>
            </Tooltip>
          }
          secondary={
            <Stack direction={'row'} spacing={'5px'} alignItems={'center'}>
              <Tooltip title={restaurant?.placeInfo} placement="top-start">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: colors.info.outlet,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '60%'
                  }}
                >
                  {restaurant?.location?.address}
                </Typography>
              </Tooltip>

              <PointIcon />

              <NewStarIcon />

              <Stack
                direction={'row'}
                spacing={0.5}
                sx={{
                  alignItems: 'end',
                  alignContent: 'end',
                  justifyContent: 'end',

                  display: 'flex'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: colors.info.outlet
                  }}
                >
                  {restauRating}
                </Typography>
              </Stack>
            </Stack>
          }
        />
      </ListItem>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
