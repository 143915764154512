import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { CSSProperties, useRef, useState } from 'react';
import useGetColors from '../hooks/useGetColor';
import GalleryIcon from '../assets/icons/GalleryIcon';
import i18n from '../i18n-config';
import SvgContainer from './SvgContainer';
import { DeleteIcon, StarIcon } from '../assets/icons';

export default function CustomItemImage({
  title,
  url,
  onRemoveImage,
  rootStyle,
  cardMediaStyle,
  gridSize = 4,
  handleImage,
  onSetPrimary,
  mdGridSize,
  isPrimary
}: {
  title?: string;
  handleImage?: (ev: any) => void;
  url?: string;
  gridSize?: number;
  mdGridSize?: number;
  isPrimary?: boolean;
  onRemoveImage?: () => void;
  onSetPrimary?: () => void;
  rootStyle?: SxProps;
  cardMediaStyle?: CSSProperties;
}) {
  const inputRef = useRef<any>();

  const colors = useGetColors();

  const onChangeImage = () => {
    inputRef.current.click();
  };

  return (
    <Grid item xs={gridSize} md={mdGridSize}>
      {!url ? (
        <Stack
          sx={{
            border: '1px dashed #EAECF0',
            backgroundColor: '#f9fafb',
            height: '110px',
            borderRadius: '8px',
            width: '100%',
            mt: 2,
            ...rootStyle
          }}
          alignItems="center"
          justifyContent={'center'}
          onClick={onChangeImage}
        >
          <GalleryIcon />
          <Typography fontSize={12} color="#475467" mt={1}>
            {title || i18n.t('no_image')}
          </Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            mt: 2,
            position: 'relative',
            border: '1px solid #c2c2c250',
            borderRadius: '8px',
            ...rootStyle
          }}
        >
          <CardMedia
            component="img"
            style={{
              width: '100%',
              height: '110px',
              backgroundColor: 'white',
              borderRadius: '8px',
              ...cardMediaStyle
            }}
            image={url || ''}
            alt="item cover"
            onClick={onChangeImage}
          />
          <Stack
            direction={'row'}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
              left: 5,
              zIndex: 50,
              justifyContent: 'space-between'
            }}
          >
            {!onSetPrimary ? (
              <Box />
            ) : (
              <IconButton
                onClick={onSetPrimary}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: 'white'
                  }
                }}
              >
                <SvgContainer
                  svg={StarIcon}
                  color={isPrimary ? colors.accent : '#8392A1'}
                  rootStyle={{ paddingTop: 0.5 }}
                />
              </IconButton>
            )}
            {!onRemoveImage ? null : (
              <IconButton
                onClick={onRemoveImage}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: 'white'
                  }
                }}
              >
                <SvgContainer
                  svg={DeleteIcon}
                  color="#EB5757"
                  rootStyle={{ paddingTop: 0.5 }}
                />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={handleImage}
        accept="image/*"
      />
    </Grid>
  );
}
