import React from 'react';

export default function LocationIcon() {
  return (
    <div>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20"
          cy="20"
          r="19.5"
          fill="#D77910"
          fillOpacity="0.1"
          stroke="#D77910"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7808 27.7005C25.0905 24.7551 28 20.4759 28 17.6087C28 12.8542 24.4183 9 20 9C15.5817 9 12 12.8542 12 17.6087C12 20.4759 14.9095 24.7551 17.2192 27.7005C18.4242 29.2371 19.0268 30.0055 20 30.0055C20.9732 30.0055 21.5758 29.2371 22.7808 27.7005ZM20 21C18.3431 21 17 19.6569 17 18C17 16.3431 18.3431 15 20 15C21.6569 15 23 16.3431 23 18C23 19.6569 21.6569 21 20 21Z"
          fill="#D77910"
        />
      </svg>
    </div>
  );
}
