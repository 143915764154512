import React from 'react';

export default function GalleryIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M22 7.81V13.9L20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L2.67 18.95L2.56 19.03C2.19 18.23 2 17.28 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
        fill="#8392A2"
      />
      <path
        d="M9.00012 10.3801C10.3146 10.3801 11.3801 9.31456 11.3801 8.00012C11.3801 6.68568 10.3146 5.62012 9.00012 5.62012C7.68568 5.62012 6.62012 6.68568 6.62012 8.00012C6.62012 9.31456 7.68568 10.3801 9.00012 10.3801Z"
        fill="#8392A2"
      />
      <path
        d="M22.0001 13.9001V16.1901C22.0001 19.8301 19.8301 22.0001 16.1901 22.0001H7.81006C5.26006 22.0001 3.42006 20.9301 2.56006 19.0301L2.67006 18.9501L7.59006 15.6501C8.39006 15.1101 9.52006 15.1701 10.2301 15.7901L10.5701 16.0701C11.3501 16.7401 12.6101 16.7401 13.3901 16.0701L17.5501 12.5001C18.3301 11.8301 19.5901 11.8301 20.3701 12.5001L22.0001 13.9001Z"
        fill="#8392A2"
      />
    </svg>
  );
}
