import React from 'react';

export default function Drop2Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.833 7.4987L10.7068 12.6249C10.3163 13.0154 9.68309 13.0154 9.29257 12.6249L4.16634 7.4987"
        stroke="#637083"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
