import React from 'react';

export default function HideIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.8916 12.7323C7.73327 12.7323 7.57493 12.674 7.44993 12.549C6.7666 11.8656 6.3916 10.9573 6.3916 9.99896C6.3916 8.00729 8.00827 6.39062 9.99993 6.39062C10.9583 6.39062 11.8666 6.76562 12.5499 7.44896C12.6666 7.56562 12.7333 7.72396 12.7333 7.89063C12.7333 8.05729 12.6666 8.21563 12.5499 8.33229L8.33327 12.549C8.20827 12.674 8.04994 12.7323 7.8916 12.7323ZM9.99993 7.64062C8.69993 7.64062 7.6416 8.69896 7.6416 9.99896C7.6416 10.4156 7.74993 10.8156 7.94993 11.1656L11.1666 7.94896C10.8166 7.74896 10.4166 7.64062 9.99993 7.64062Z"
        fill="#292D32"
      />
      <path
        d="M4.66654 15.426C4.52487 15.426 4.37487 15.376 4.2582 15.276C3.36654 14.5177 2.56654 13.5844 1.8832 12.501C0.99987 11.126 0.99987 8.88437 1.8832 7.50104C3.91654 4.31771 6.87487 2.48438 9.99987 2.48438C11.8332 2.48438 13.6415 3.11771 15.2249 4.30938C15.4999 4.51771 15.5582 4.90937 15.3499 5.18437C15.1415 5.45937 14.7499 5.51771 14.4749 5.30937C13.1082 4.27604 11.5582 3.73438 9.99987 3.73438C7.3082 3.73438 4.7332 5.35104 2.9332 8.17604C2.3082 9.15104 2.3082 10.851 2.9332 11.826C3.5582 12.801 4.27487 13.6427 5.06654 14.326C5.32487 14.551 5.3582 14.9427 5.1332 15.2094C5.01654 15.351 4.84154 15.426 4.66654 15.426Z"
        fill="#292D32"
      />
      <path
        d="M10.0001 17.5176C8.8918 17.5176 7.80847 17.2926 6.7668 16.8509C6.45013 16.7176 6.30013 16.3509 6.43347 16.0342C6.5668 15.7176 6.93347 15.5676 7.25013 15.7009C8.13347 16.0759 9.05847 16.2676 9.9918 16.2676C12.6835 16.2676 15.2585 14.6509 17.0585 11.8259C17.6835 10.8509 17.6835 9.15089 17.0585 8.17589C16.8001 7.76756 16.5168 7.37589 16.2168 7.00923C16.0001 6.74256 16.0418 6.35089 16.3085 6.12589C16.5751 5.90923 16.9668 5.94256 17.1918 6.21756C17.5168 6.61756 17.8335 7.05089 18.1168 7.50089C19.0001 8.87589 19.0001 11.1176 18.1168 12.5009C16.0835 15.6842 13.1251 17.5176 10.0001 17.5176Z"
        fill="#292D32"
      />
      <path
        d="M10.5747 13.5576C10.283 13.5576 10.0163 13.3493 9.95799 13.0493C9.89133 12.7076 10.1163 12.3826 10.458 12.3243C11.3747 12.1576 12.1413 11.391 12.308 10.4743C12.3747 10.1326 12.6997 9.91598 13.0413 9.97431C13.383 10.041 13.608 10.366 13.5413 10.7076C13.2747 12.1493 12.1247 13.291 10.6913 13.5576C10.6497 13.5493 10.6163 13.5576 10.5747 13.5576Z"
        fill="#292D32"
      />
      <path
        d="M1.66686 18.9573C1.50853 18.9573 1.3502 18.899 1.2252 18.774C0.983529 18.5323 0.983529 18.1323 1.2252 17.8906L7.4502 11.6656C7.69186 11.424 8.09186 11.424 8.33353 11.6656C8.5752 11.9073 8.5752 12.3073 8.33353 12.549L2.10853 18.774C1.98353 18.899 1.8252 18.9573 1.66686 18.9573Z"
        fill="#292D32"
      />
      <path
        d="M12.1083 8.51589C11.9499 8.51589 11.7916 8.45755 11.6666 8.33255C11.4249 8.09089 11.4249 7.69089 11.6666 7.44922L17.8916 1.22422C18.1333 0.982552 18.5333 0.982552 18.7749 1.22422C19.0166 1.46589 19.0166 1.86589 18.7749 2.10755L12.5499 8.33255C12.4249 8.45755 12.2666 8.51589 12.1083 8.51589Z"
        fill="#292D32"
      />
    </svg>
  );
}
