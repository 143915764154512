import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Box,
  CircularProgress,
  Backdrop,
  Fab
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import i18n from '../../../../i18n-config';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import { TransitionProps } from '@mui/material/transitions';
import { useStyle } from '../../useStyle';
import MapPicker from 'react-google-map-picker';
import LocationIcon from '../../../../assets/icons/settings/LocationIcon';
import axios from 'axios';
import useGetColors from '../../../../hooks/useGetColor';
import { useDispatch } from 'react-redux';
import { updateRestaurantAsync } from '../../../../store/auth';
import ResetIcon from '../../../../assets/icons/settings/ResetIcon';
import ZoonPlusIcon from '../../../../assets/icons/settings/ZoonPlusIcon';
import ZoomMinusIcon from '../../../../assets/icons/settings/ZoomMinusIcon';
import MapSearchInput, { PlaceType } from './MapSearchInput';
import useAuth from '../../../../hooks/useAuth';
import { notifyError } from '../../../../utils/notify';
import { localisation } from '../../../../data.def';
import { GOOGLE_MAPS_API_KEY } from '../../../../firebase';
// import CustomMarkerIcon from '../../../../assets/icons/settings/CustomMarkerIcon';

const modalbg = {
  background: '#fff15',
  backdropFilter: 'blur(1.5px)',
  '& .MuiPaper-root': {
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    background: '#fff',
    borderRadius: '12px',
    minWidth: '60%',
    height: '90%'
  }
};
interface Props {
  open: boolean;
  onClose: () => void;
  setAdress?: any;
}
export default function MapModal({ open, onClose, setAdress }: Props) {
  const styles = useStyle();
  const user = useAuth().restaurant;
  const colors = useGetColors();
  const dispatch = useDispatch();
  const API_KEY = useMemo(() => {
    const result = GOOGLE_MAPS_API_KEY;
    return result;
  }, []);

  const [location, setLocation] = useState<localisation>({
    lat: user?.location?.latitude || 4.0665088,
    lng: user?.location?.longitude || 9.7189888
  });
  const [zoom, setZoom] = useState(15);
  const [places, setPlaces] = useState(user?.placeInfo);
  const [loadingSelected, setLoadingSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const initPosition = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        console.log(latitude, longitude);
        setLocation({ lat: latitude, lng: longitude });
      });
    } else {
      notifyError('Geolocation is not available in this browser.');
    }
  };

  const locationFromPlaces = async (description: PlaceType | null) => {
    const response = await axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json',

      {
        params: {
          address: description,
          key: API_KEY
        }
      }
    );
    if (response.data.status === 'OK') {
      const { lat, lng } = response.data.results[0].geometry.location;
      setAdress({ address: description, latitude: lat, longitude: lng });
      setLocation({
        lat: lat,
        lng: lng
      });
      // console.log(`Latitude : ${lat}, Longitude : ${lng}`);
    }
  };
  const onSearchHandler = (value: PlaceType | null) => {
    setPlaces(value);
    locationFromPlaces(value);
  };

  const zoomPlus = () => setZoom((prev) => prev + 1);
  const zoomMinus = () => setZoom((prev) => prev - 1);
  const resetLocation = () => {
    // if (!user?.location?.longitude) {
    //   initPosition();
    //   return;
    // }
    // setLocation({
    //   lat: user?.location?.latitude,
    //   lng: user?.location?.longitude
    // });
    initPosition();
  };

  function handleChangeLocation(lat: number, lng: number) {
    setLocation({ lat: lat, lng: lng });
    getLocation(lat, lng);
  }

  function handleChangeZoom(newZoom: number) {
    setZoom(newZoom);
  }
  const getLocation = async (lat: number, lon: number) => {
    const baseURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat}, ${lon}&key=${API_KEY}&result_type=&language=fr`;
    try {
      setLoadingSelected(true);
      axios.get(baseURL).then((response) => {
        const data = response.data?.results[0]?.formatted_address;
        setPlaces(response.data?.results[0].formatted_address + '');

        const parts = data.split(',');

        if (parts.length > 1) {
          const result = parts.slice(1).join(',').trim();
        }
      });
    } catch (error) {
      console.log('error geting location', error);
    } finally {
      setLoadingSelected(false);
    }
  };

  const callback = () => {
    setLoading(false);
    onClose();
  };
  const onError = () => {
    alert(capitalizeFirstLetter(i18n.t('error_occured')));
    setLoading(false);
  };

  const onConfirm = () => {
    dispatch(
      updateRestaurantAsync({
        data: {
          ...user,
          location: {
            ...user.location,
            updatedAt: new Date(),
            latitude: location.lat,
            longitude: location.lng,
            address: places
          },
          placeInfo: places
        },
        callback,
        onError,
        onStart() {
          setLoading(true);
        }
      }) as any
    );
  };

  useEffect(() => {
    if (!user?.location?.longitude) {
      initPosition();
      return;
    }
  }, [user?.location?.longitude]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      sx={modalbg}
      aria-describedby="alert-dialog-slide-description"
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box>
            <Typography
              sx={{ fontSize: 24, fontWeight: 500, color: '#265767' }}
            >
              {' '}
              {capitalizeFirstLetter(i18n.t('what_location_desc'))}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 400, color: '#667085' }}
            >
              {' '}
              {capitalizeFirstLetter(i18n.t('content_desc'))}
            </Typography>
          </Box>
        </Stack>
        <Divider sx={{ mt: '20px', mb: '10px' }} />
      </DialogTitle>
      <DialogContent>
        <Fab
          disableFocusRipple={true}
          disableRipple={true}
          disableTouchRipple={true}
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            width: '90%',
            bgcolor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            '&:hover': {
              bgcolor: 'transparent',
              boxShadow: 'none',
              border: 'none'
            }
          }}
        >
          <MapSearchInput onChangePlaces={(ev) => onSearchHandler(ev)} />
        </Fab>
        <Stack
          direction={'column'}
          justifyContent={'end'}
          sx={{
            position: 'absolute',
            mt: '5%',
            ml: '88%'
          }}
        >
          <Fab
            onClick={resetLocation}
            sx={{
              bgcolor: 'white',
              width: 40,
              height: 40
            }}
          >
            <ResetIcon />
          </Fab>
          <Fab
            onClick={zoomPlus}
            sx={{
              mt: '10px',
              bgcolor: 'white',
              width: 40,
              height: 40,
              borderRadius: '12px 12px 0px 0px',
              boxShadow:
                '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)'
            }}
          >
            <ZoonPlusIcon />
          </Fab>
          <Fab
            onClick={zoomMinus}
            sx={{
              borderRadius: '0px 0px 12px 12px',
              bgcolor: 'white',
              width: 40,
              height: 40,
              mt: '4px',
              boxShadow:
                '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)'
            }}
          >
            <ZoomMinusIcon />
          </Fab>
        </Stack>

        <MapPicker
          defaultLocation={location}
          zoom={zoom}
          mapTypeId={'roadmap' as any}
          style={{
            height: '700px',
            borderRadius: '12px'
          }}
          onChangeLocation={handleChangeLocation}
          onChangeZoom={handleChangeZoom}
          apiKey={API_KEY}
        />
        {/* <CustomMarkerIcon /> */}
      </DialogContent>
      <DialogActions>
        <Box width={'100%'} mt={2}>
          <Stack direction={'row'} spacing={2} pl={2}>
            <LocationIcon />
            <div>
              <Typography
                sx={{ fontSize: 14, fontWeight: 400, color: colors.info.loc }}
              >
                {capitalizeFirstLetter(i18n.t('marker_desc'))}
              </Typography>
              {loadingSelected ? (
                <CircularProgress color="warning" />
              ) : (
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: colors.info.rating
                  }}
                >
                  {!places ? '---' : capitalizeFirstLetter(places)}
                </Typography>
              )}
            </div>
          </Stack>

          <Stack
            justifyContent={'end'}
            spacing={2}
            direction={'row'}
            py={3}
            pr={4}
          >
            <Button onClick={onClose} sx={styles.mdlcan}>
              {capitalizeFirstLetter(i18n.t('cancel'))}
            </Button>
            <Button
              onClick={onConfirm}
              //   disabled={!onVerify(carouselForm)}
              sx={{
                ...styles.mdlsave,
                '&:hover': {
                  opacity: 1,
                  bgcolor: '#265767'
                }
              }}
            >
              {capitalizeFirstLetter(i18n.t('confirm'))}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
