import React from 'react';
import {
  Avatar,
  Dialog,
  DialogContent,
  Stack,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import LoadingButton from './LoadingButton';
import { toggleSessionExpired } from '../store/ui';
import { onLogout } from '../store/auth';
import { error401 } from '../assets/images/image';
import i18n from '../i18n-config';

interface Props {
  visible: boolean;
}

export default function SessionExpiredModal({ visible }: Props) {
  const dispatch = useDispatch();

  const onConnect = () => {
    dispatch(toggleSessionExpired({ payload: false }));
    dispatch(onLogout() as any);
  };

  return (
    <Dialog
      open={visible}
      keepMounted
      sx={{ zIndex: 9999 }}
      maxWidth="md"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ p: 5 }}>
        <Stack alignItems="center" justifyContent="center" spacing={2}>
          <Typography variant="h1">{i18n.t('session_expired')}</Typography>
          <Typography>{i18n.t('session_expired_desc')}</Typography>
          <Avatar src={error401} sx={{ height: 400, width: 400 }} />

          <Stack direction={'row'} mt={3} justifyContent="center">
            <LoadingButton
              title={i18n.t('connect')}
              sx={{ width: 'auto' }}
              type="button"
              onClick={onConnect}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
