import React from 'react';

export default function NewStarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.26 3.47203L11.2 5.35203C11.3266 5.61203 11.6666 5.8587 11.9533 5.91203L13.6533 6.19203C14.74 6.37203 14.9933 7.1587 14.2133 7.94536L12.8866 9.27203C12.6666 9.49203 12.54 9.92537 12.6133 10.2387L12.9933 11.8787C13.2933 13.172 12.6 13.6787 11.46 12.9987L9.86663 12.052C9.57996 11.8787 9.09997 11.8787 8.8133 12.052L7.21996 12.9987C6.07996 13.672 5.38663 13.172 5.68663 11.8787L6.06664 10.2387C6.13997 9.93203 6.0133 9.4987 5.7933 9.27203L4.46664 7.94536C3.68664 7.16536 3.93997 6.3787 5.02664 6.19203L6.72663 5.91203C7.0133 5.86536 7.3533 5.61203 7.47997 5.35203L8.41997 3.47203C8.91997 2.45203 9.74664 2.45203 10.26 3.47203Z"
        fill="#EFC005"
      />
      <path
        opacity="0.4"
        d="M5.3335 3.83203H1.3335C1.06016 3.83203 0.833496 3.60536 0.833496 3.33203C0.833496 3.0587 1.06016 2.83203 1.3335 2.83203H5.3335C5.60683 2.83203 5.8335 3.0587 5.8335 3.33203C5.8335 3.60536 5.60683 3.83203 5.3335 3.83203Z"
        fill="#EFC005"
      />
      <path
        opacity="0.4"
        d="M3.3335 13.168H1.3335C1.06016 13.168 0.833496 12.9413 0.833496 12.668C0.833496 12.3946 1.06016 12.168 1.3335 12.168H3.3335C3.60683 12.168 3.8335 12.3946 3.8335 12.668C3.8335 12.9413 3.60683 13.168 3.3335 13.168Z"
        fill="#EFC005"
      />
      <path
        opacity="0.4"
        d="M2.00016 8.5H1.3335C1.06016 8.5 0.833496 8.27333 0.833496 8C0.833496 7.72667 1.06016 7.5 1.3335 7.5H2.00016C2.2735 7.5 2.50016 7.72667 2.50016 8C2.50016 8.27333 2.2735 8.5 2.00016 8.5Z"
        fill="#EFC005"
      />
    </svg>
  );
}
