import React from 'react';

export default function CompanyLoc2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M17.1833 7.04036C16.3083 3.19036 12.95 1.45703 9.99999 1.45703C9.99999 1.45703 9.99999 1.45703 9.99165 1.45703C7.04999 1.45703 3.68332 3.18203 2.80832 7.03203C1.83332 11.332 4.46665 14.9737 6.84999 17.2654C7.73332 18.1154 8.86665 18.5404 9.99999 18.5404C11.1333 18.5404 12.2667 18.1154 13.1417 17.2654C15.525 14.9737 18.1583 11.3404 17.1833 7.04036Z"
        fill="#265767"
      />
      <path
        d="M10 11.2148C11.4497 11.2148 12.625 10.0396 12.625 8.58984C12.625 7.1401 11.4497 5.96484 10 5.96484C8.55025 5.96484 7.375 7.1401 7.375 8.58984C7.375 10.0396 8.55025 11.2148 10 11.2148Z"
        fill="#265767"
      />
    </svg>
  );
}
