import React from 'react';
import useGetColors from '../../hooks/useGetColor';

export const useStyle = () => {
  const colors = useGetColors();

  const style = {
    txt1: {
      fontSize: 24,
      fontWeight: 400,
      color: colors.primary.main
    },
    cardTitle: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.info.text2
    }
  };

  return style;
};
