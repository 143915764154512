const textEN = {
  french: "French",
  english: "English",
  menu: "Menu",
  drivers: "Tchop-riders",
  orders: "Orders",
  billing: "Billing",
  report_statistics: "Reports & statistiques",
  feedback: "Feedback",
  meals: "Meals",
  restaurant: "Tchop-resto",
  couriers: "Tchop-riders",
  customers_reviews: "Custumer reviews",
  your_rating: "Your rating",
  created_on: "Created on",
  votes: "Votes",
  last_updated: "Last updated",
  overall_rating: "Overall ratings",
  total_menus: "Total menus",
  total_riders: "Total number of Tchop-riders",
  ongoing_deliveries: "Ongoing deliveries",
  complete_deliveries: "Completeed deliveries",
  total_deliveries: "Total deliveries",
  most_order_meals: "Most ordered meals",
  last_3_days: "Last 3 days",
  waiting_deliveries: "Awaiting deliveries",
  go_to_deliveries: "Sent to delivery ",
  drivers_list: "List Tchop-riders",
  new: "New",
  driver_name: "Tchop-rider's name",
  driver_id: "Tchop-rider's  id",
  contact: "Contact",
  registration_date: "Registration date",
  paid: "Paid",
  actions: "Actions",
  report: "Report",
  driver_details: "Tchop-rider's details",
  list_of: "List of",
  page: "Page",
  report_driver: "Report Tchop-rider",
  bad_driving: "Bad Driving",
  issue_desc: "Issue with delivering the package",
  cold_starters: "Cold Starters",
  issue_with_drivers: "Issues with the Tchop-riders",
  lost_an_item: "Lost an Item",
  other: "Other",
  type_here: "Type here",
  destination: "Destination",
  destinations: "Destinations",
  courrier_track: "Tchop-rider's tracking  id ",
  items: "Items",
  status: "Status",
  departure_date: "Departure date",
  arrival_date: "Arrival date",
  time_delay: "Time frame",
  orders_details: "Order details",
  company_info: "Establishment's infos",
  company_info_desc: "Name, Contact",
  availabilities: "Availabilities",
  availabilities_desc: "Opening hours",
  payment_method: "Payment Method",
  payment_method_desc: "Specify Customer payment method ",
  security: "Security",
  security_desc: "Password, access",
  theme: "Theme",
  theme_desc: "Define Colors",
  theme_settings: "Theme Settings",
  change_color_polor:
    "To customize your experience on the platform, Tchop.me ! gives you the possibility to modify the color palette of your establishment management portal.",
  primary: "Primary color",
  secondary: "Secondary color",
  accent: " ",
  primary_desc:
    "The main color of your brand will be applied to the majority of the application's components.",
  secondary_desc:
    "The complementary shade of your brand is primarily intended to be used as a hover color or applied to secondary elements.",
  accent_desc:
    "This shade is chosen for its high contrast, in order to highlight the essential components of your portal.",
  last_update: "Last update",
  save_changes: "Save Changes",
  company_availabilities: "Establishment's availabilities",
  closed: "Closed",
  opened: "Opened",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  add_time: "Add time",
  edit_availability: "Edit availability",
  edit_availability_desc: "Select the type of food you want to add to menu",
  order_at: "Order at",
  ordered_from: "Ordered from",
  customer_contact: "Customer's contact",
  delivery_details: "Delivery details",
  order_pick_up: "Order pick up",
  miles: "Miles",
  minutes: "Minutes",
  grand_total: "Grand total",
  delivery_fee: "Delivery fee",
  on_going: "On going",
  ordered_at: "Ordered at",
  order_id: "Order ID",
  customer_id: "Customer ID",
  last_order: "Last order",
  total_orders: "Total order",
  user_details: "User details",
  money_spend: "Amount spent",
  total_ordered: "Total ordered",
  users: "Users",
  categories: "Categories",
  create_item: "Create item",
  no_item_found: "No item found",
  add_category: "Add category",
  new_item: "New item",
  edit_item: "Edit item",
  new_item_desc: "Create a new item that will appear in your restaurant menu",
  unit_price: "Unit price",
  item_name: "Item name",
  item_image: "Item Image(s)",
  no_image: "No Image",
  no_categories: "No Categories",
  add_categories: "Add categories",
  add_categories_desc: "Select type of food you want to add to your menu",
  search: "Search",
  selected: "Selected",
  details: "Details",
  earnings: "Earnings",
  ordered_meals: "Ordered Meals",
  riders: "Tchop-riders",
  total_meals: "Total meals",
  ongoing_orders: "Ongoing Orders",
  completed_orders: "Completed Orders",
  totals_orders: "Total Deliveries",
  yesterday: "Yesterday",
  last_week: "Last week",
  this_month: "This month",
  last_month: "Last month",
  this_year: "This year",
  last_year: "Last year",
  all_time: "All the time",
  onDelivery: "On delivery",
  ordered_time: "Time ordered",
  cost: "Cost",
  order_details: "Order details",
  order_preparation: "Preparing order",
  delivery_address: "Delivery Address",
  order_items: "Ordered items",
  cancel: "Cancel",
  confirm: "Confirm",
  order_ready: "Your order is ready !",
  price: "Price",
  enter_amount: "Enter amount",
  click_upload: "Click upload",
  drag_drop: "Drag or drop",
  tag: "Tag",
  subtag: "Sub tag",
  email: "Email",
  password: "Password",
  forgot_desc: "Forgot your password ?",
  log_desc:
    "By logging in, you agree to the security conditions established for Tchop.me ! merchants!",
  log_in: "Log in",
  no_account: "No account",
  parter_with: "Partner with Tchop.me !  for a successful collaboration.",
  register: "Register",
  business_location: "Establishment's location",
  mobile_number: "Mobile number",
  get_started: "Get started",
  restaurant_name: "Establishment's name",
  register_desc:
    "Enter into a partnership with Tchop. me! to increase your sales via the application and also on your website.",
  register_title:
    "Maximize your business's potential with a wide range of growth opportunities.",
  already_have: "Already have an account?",
  available: "Available",
  unavailable: "Unavailable",
  yes: "Yes",
  no: "No",
  rating_chart: "Rating chart",
  add_a_comment: "Add a comment",
  home: "Home",
  preparing: "Preparing",
  ready: "Ready",
  orders_history: "Order history",
  delivered: "Delivered",
  placeholder_desc: "No data found",
  confirm_password: "Confirm password",
  shipping_desc: "Delivery in progress",
  find_order: "Find order",
  pass_desc: "Incorrect password. Try again or reset password.",
  add_payment_method: "Add payment method",
  no_payment_desc: "No paiement method. ",
  new_payment: "New payment method",
  new_payment_desc: "This mode of payment is available for clients to use.",
  payment_type: "Payment type",
  credit_debit_card: "Credit/debit card",
  mobile_money: "Mobile money",
  paypal: "Paypal",
  bank_account: "Bank Account",
  name_card: "Name on card",
  card_number: "Card number",
  expiry_date: "Expiry date",
  cvv: "CVV",
  account_name: "Account Name",
  account_number: "Account number",
  credit_card: "Credit card",
  add_credit_card: "Add a credit card",
  momo_account: "Mobile money account",
  other_payment_method: "Other payment methods",
  empty: "Empty",
  logout: "Logout",
  find_driver: "Find Tchop-rider",
  find_a_user: "Find user",
  reg_loc_desc:
    "To accurately detect your address, please enable your real-time geolocation.",
  total_sales: "Total sales",
  no_order_today: "No order today",
  discard_changes: "Discard changes",
  company_location: "Establishment's location",
  company_name: "Establishment's name",
  owner_name: "Owner's name",
  type: "Type",
  company_contact: "Establishment's contact details",
  email_address: "Email address",
  description: "Description",
  text_here: "Text here",
  country: "Country",
  state: "State",
  city: "City",
  address_line: "Address line",
  or_select_map: "Select from map",
  enter_address: "Enter Address",
  enter_country: "Enter country",
  enter_state: "Enter state",
  enter_city: "Enter city",
  click_upload_image: "Click to upload image.",
  click_upload_image_desc:
    "This visual will appear on your establishment's profile",
  click_upload_image_format: "SVG, PNG, JPG or GIF (max. 800x400px)",
  item: "Item",
  select: "Select",
  km_away: "Km away",
  warning_desc:
    'Once assigned to a Tchop-rider, the status of your order will change to "Ready", enabling it to be tracked live.',
  waiting_driver: "Waiting for a Tchop-rider",
  driver_onroad: "Tchop-rider is en route!",
  banner_image: "Banner images",
  banner_image_desc:
    "Use photos to showcase your establishment and attract more customers.",
  banner_image_desc2: "PNG, JPG (max. ***x***px)",
  added: "Added",
  tap_add: "Tap to add",
  update_success: "Update Success",
  create_success: "Created Successfully",
  delete_success: "Deleted Successfully",
  error_occured: "An error has occurred",
  service_fee: "Service fee",
  report_stat: "Reports & statistics",
  news: "News",
  about_desc: "About Tchop.me !",
  settings: "Settings",
  show_hotdeal: "Promote a dish as a Hot deal 🔥?",
  show_hotdeal_desc:
    'This dish will be specifically promoted within our "Hot deal 🔥" selection. This distinction is designed to attract the attention of customers ',
  sides: "Side dish",
  sides_desc: "This dish is served with a side dish",
  included: "Included",
  included_desc:
    "Please choose the extras you would like to add to this dish. These options will be presented to the customer at the time of ordering.",
  add: "Add",
  unit_price_desc: "This price includes the side dish's cost",
  main_tag: "Main Tag",
  customize_tag: "Customize Tag",
  customize_tag_desc:
    "Please separate each tag with a comma in order to add more than one.",
  hot_deals: "Hot deals 🔥",
  what_location_desc: "What is your exact location?",
  content_desc:
    "Indicating your location improves search and delivery accuracy, ensures clear order tracking and enables us to offer you tailor-made recommendations.",
  marker_desc:
    "This marker define your location (move in the map to adjust position)",
  customer: "Customer",
  new_hot_deal: "New Hot deals 🔥",
  hot_deal_desc:
    "Please select an item and allocate a discount to it, applicable for a period not exceeding ten days.",
  selected_meal: "selected meal",
  start_date: "Start date",
  end_date: "End date",
  start_time: "Start time",
  end_time: "End time",
  tap_to_select: "Tap to select",
  old_price: "Initial price",
  deal_price: "Discounted price",
  exp_date: "Expiration date",
  starters: "Starters",
  beverage: "Beverages",
  main_course: "Main courses",
  desserts: "Desserts",
  no_items_found: "No items found",
  deal_old_comp: "Must be inferior to the initial price",
  hint: "Hint",
  hot_deal_explication: "Hot deals 🔥 can not exceed",
  day: "Day",
  today: "Today",
  feb: "Feb",
  apr: "April",
  may: "May",
  jun: "June",
  jul: "July",
  aug: "August",
  report_stat_drawer: "Report & statistics",
  to_day: "Today",
  this_week: "This week",
  last_sev_day: "Last seven day",
  apply: "Apply",
  reset: "Reset",
  add_sides: "Add side dishes",
  add_sides_desc: "Select side dishes you want to add to menu",
  tag_name: "Tag name",
  tag_length_description: "No more than 3 subtags",
  select_meals: "Select meals",
  waiting_payment: "Awaiting payment",
  confirm_meals: "Confirm meals",
  confirm_meals_desc: "Please confirm which dishes you are able to cater for",
  add_to_extras: "Add to extras",
  add_to_extras_desc: "This supplement will be included in the extra list.",
  include_in_price: "Supplement prices are included in the meal's price",
  customer_rating: "Customer rating",
  username: "Username",
  street: "Street",
  define_as_new:
    'Include a new dish in the "New meals " category in the customer application',
  define_as_new_des:
    "This dish will be presented as a new addition to our selection.",
  open: "Open",
  close: "Close",
  delete_meal: "Delete meal",
  delete_meal_desc: "The removal of this dish is definitive and irreversible.",
  yes_delete: "Yes, delete",
  invalid_email_or_password: "The user name or password entered is invalid.",
  restaurant_is_archived:
    "Your establishment has been archived. Please contact our support team.",
  restaurant_is_blocked:
    "Your establishment has been blocked, please contact our support team",
  restaurant_not_found:
    "Your establishment  was not found please contact our support team",
  someting_went_wrong_try_again:
    "A problem has occurred. Please try again later.",
  no_update: "No update",
  order_is_ready: "The order is ready!",
  meal_reviews: "Meal review",
  ratings: "Ratings",
  customer_reviews: "Customers reviews",
  no_rating: "No rating",
  all_dishes: "All dishes",
  star_1: "1 star",
  star_2: "2 stars",
  star_3: "3 stars",
  star_4: "4 stars",
  star_5: "5 stars",
  all: "All",
  view: "View",
  sidesExtra: "Accompaniment/extra",
  refuse: "Refuse",
  accept: "Accept",
  subTotal: "sub-total",
  welcome: "welcome",
  welcome_text:
    "We are happy to see you take control of your restaurant with our intuitive and practical application.",
  lets_start_by: "let's start by recording the geolocation of your restaurant",
  lets_go: "let's go",
  until: "until",
  soon_available: "soon Available",
  go_back_login: "go back to login page",
  enter_email_description:
    "enter your email adress .A verification link will be sent to that email",
  send_email: "send the link",
  email_already_send: "a verification link has been sent to the email address",
  email_already_send_2: "open your email inbox to complete the procedure",
  login_page: "login page",
  something_wrong_email:
    "something went wrong when sending the verification link",
  mobile_money_account: "mobile money account",
  login_welcome: "Welcome to your partner restaurant interface!",
  time_interval_incorrect: "time interval incorrect",
  new_side: "new Side",
  edit_side: "edit Side",
  customer_support: "customer support",
  customer_support_desc:
    "Are you lost or finding it difficult to use the app? Go to the FAQ for help or whatsapp us and we will get back to you as soon as possible.",
  whatsapp_us: "whatsapp us",
  order_time: "order time",
  arrival: "arrival",
  order_delivered: "order delivered",
  order_cancelled: "order cancelled",
  active_orders: "active orders",
};
export default textEN;