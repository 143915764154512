import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
  CircularProgress,
  Backdrop
} from '@mui/material';
import useGetColors from '../../hooks/useGetColor';
import AppInput from '../../components/AppInput';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import i18n from '../../i18n-config';
import HideIcon from '../../assets/icons/HideIcon';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LanguageSelector from './LanguageSelector';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { getRestaurantDataAsync, onLogout } from '../../store/auth';
import { useDispatch } from 'react-redux';
import { notifyError } from '../../utils/notify';

export default function LoginPage() {
  const colors: any = useGetColors();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authIsLoaded = useSelector((state: RootState) => state.ui.authIsLoaded);

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formHandler = (key: string) => (value: string) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const onLoginHandler = async () => {
    try {
      setLoading(true);
      // dispatch(onLogout() as any);
      console.log('formData', formData);
      const user = await signInWithEmailAndPassword(
        auth,
        formData.username?.trim(),
        formData.password
      );

      console.log('user', user);

      dispatch(
        getRestaurantDataAsync({
          UserID: user?.user.uid,
          onError(ev) {
            notifyError(capitalizeFirstLetter(ev + ''));
          }
          // callback: () => {
          //   navigate('/');
          // }
        }) as any
      );
      // navigate('/');
    } catch (error: any) {
      notifyError(capitalizeFirstLetter(i18n.t('invalid_email_or_password')));
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || authIsLoaded}
      >
        <CircularProgress color="success" />
      </Backdrop>
      {/* <Typography
        sx={{
          fontSize: 32,
          fontWeight: 500,
          color: colors.primary.main,
          mb: 7
        }}
      >
        {'Tchop.me Restaurant'}
      </Typography> */}

      <form>
        <AppInput
          title={capitalizeFirstLetter(i18n.t('email'))}
          value={formData.username}
          onChange={formHandler('username')}
          type="text"
          containerStyle={{
            marginBottom: '28px',
            width: 386
          }}
        />
        <AppInput
          title={capitalizeFirstLetter(i18n.t('password'))}
          value={formData.password}
          onChange={formHandler('password')}
          type={show ? 'text' : 'password'}
          endIcon={
            <IconButton onClick={() => setShow((prev) => !prev)}>
              {!show ? <HideIcon /> : <RemoveRedEyeOutlinedIcon />}
            </IconButton>
          }
          containerStyle={{
            width: 386
          }}
        />
      </form>
      <Typography
        onClick={() => navigate('/reset')}
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: colors.info.rating,
          mt: 1.5,
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
      >
        {capitalizeFirstLetter(i18n.t('forgot_desc'))}
      </Typography>

      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: colors.info.text2,
          mt: 4,
          mb: 4,
          width: 386
        }}
      >
        {capitalizeFirstLetter(i18n.t('log_desc'))}
      </Typography>

      {/* loading button */}

      <div>
        <Button
          variant="contained"
          onClick={onLoginHandler}
          disabled={
            !validateEmailAndPassword(formData.username, formData.password)
          }
          sx={{
            color: 'white',
            width: 386,
            mb: 2
          }}
        >
          {capitalizeFirstLetter(i18n.t('log_in'))}
        </Button>
        {error ? (
          <Typography sx={{ mt: 2, color: 'red', textAlign: 'center' }}>
            {' '}
            {'please verify your login credentials'}
          </Typography>
        ) : null}
      </div>

      {/* <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: colors.info.txtSh,
          mb: '12px',
          textAlign: 'center'
        }}
      >
        {capitalizeFirstLetter(i18n.t('no_account')) + '?'}
      </Typography> */}
      {/* <Button
        onClick={() => navigate('/register')}
        sx={{
          bgcolor: 'transparent',
          color: colors.primary.main,
          width: 386,

          border: `1px solid ${colors.primary.main}`,
          mb: 4
        }}
      >
        {capitalizeFirstLetter(i18n.t('parter_with'))}
      </Button> */}
      <LanguageSelector />
    </Box>
  );
}

function validateEmailAndPassword(email: string, password: string): boolean {
  if (!email || !password) {
    return false;
  }
  // if (email.length < 6) {
  //   return false;
  // }
  if (password.length < 8) {
    return false;
  }
  return true;
}

function checkEmail(inputString: string): string {
  const emailRegex: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (emailRegex.test(inputString)) {
    return inputString;
  } else {
    return inputString + '@tchopme.com';
  }
}
