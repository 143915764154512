import React from 'react';

export default function HelpLoginIcon() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#547381" />
      <path
        d="M18 32.3174C17.72 32.3174 17.43 32.2475 17.17 32.1075C16.6 31.8075 16.25 31.2074 16.25 30.5674V29.1475C13.23 28.8375 11.25 26.6174 11.25 23.4374V17.4375C11.25 13.9975 13.56 11.6875 17 11.6875H27C30.44 11.6875 32.75 13.9975 32.75 17.4375V23.4374C32.75 26.8774 30.44 29.1874 27 29.1874H23.23L18.97 32.0275C18.68 32.2175 18.34 32.3174 18 32.3174ZM17 13.1775C14.42 13.1775 12.75 14.8475 12.75 17.4275V23.4276C12.75 26.0076 14.42 27.6776 17 27.6776C17.41 27.6776 17.75 28.0176 17.75 28.4276V30.5576C17.75 30.6876 17.83 30.7475 17.88 30.7775C17.93 30.8075 18.03 30.8375 18.14 30.7675L22.59 27.8076C22.71 27.7276 22.86 27.6776 23.01 27.6776H27.01C29.59 27.6776 31.26 26.0076 31.26 23.4276V17.4275C31.26 14.8475 29.59 13.1775 27.01 13.1775H17Z"
        fill="white"
      />
      <path
        d="M21.9998 22.1094C21.5898 22.1094 21.2498 21.7694 21.2498 21.3594V21.1494C21.2498 19.9894 22.0998 19.4194 22.4198 19.1994C22.7898 18.9494 22.9098 18.7794 22.9098 18.5194C22.9098 18.0194 22.4998 17.6094 21.9998 17.6094C21.4998 17.6094 21.0898 18.0194 21.0898 18.5194C21.0898 18.9294 20.7498 19.2694 20.3398 19.2694C19.9298 19.2694 19.5898 18.9294 19.5898 18.5194C19.5898 17.1894 20.6698 16.1094 21.9998 16.1094C23.3298 16.1094 24.4098 17.1894 24.4098 18.5194C24.4098 19.6594 23.5698 20.2294 23.2598 20.4394C22.8698 20.6994 22.7498 20.8694 22.7498 21.1494V21.3594C22.7498 21.7794 22.4098 22.1094 21.9998 22.1094Z"
        fill="white"
      />
      <path
        d="M22 24.6016C21.58 24.6016 21.25 24.2616 21.25 23.8516C21.25 23.4416 21.59 23.1016 22 23.1016C22.41 23.1016 22.75 23.4416 22.75 23.8516C22.75 24.2616 22.42 24.6016 22 24.6016Z"
        fill="white"
      />
    </svg>
  );
}
