import { Transition } from '../pages/menu/components/Transition';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/stringUtils';

interface Props {
  open: boolean;
  onConfirm: () => void;
  restauName: string;
}

export default function WelcomeModal({ open, onConfirm, restauName }: Props) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      sx={{
        background: '#fff15',
        backdropFilter: 'blur(1.5px)',
        '& .MuiPaper-root': {
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          background: '#fff',
          borderRadius: '12px',
          minWidth: '30%',
          minHeight: '50%',
          maxWidth: '45%'
        }
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography
            sx={{
              fontSize: 28,
              fontWeight: 500,
              color: '#265767'
            }}
          >
            {capitalizeFirstLetter(t('welcome')) +
              ' ' +
              capitalizeFirstLetter(restauName)}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 24,
              mt: 2,
              color: '#265767',
              textAlign: 'center'
            }}
          >
            {capitalizeFirstLetter(t('welcome_text'))}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 16,
              mt: 2,
              color: '#667085'
            }}
          >
            {capitalizeFirstLetter(t('lets_start_by'))}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', alignItems: 'center', pb: 2 }}
      >
        <Button
          variant="contained"
          onClick={onConfirm}
          sx={{
            fontWeight: 600,
            minWidth: 200
          }}
        >
          {t('lets_go')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
