import React from 'react';

export default function DonutBottom() {
  return (
    <div style={{ marginLeft: '5px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="22"
        viewBox="0 0 40 22"
        fill="none"
      >
        <path
          d="M34.9442 0.761719C32.8234 0.761719 31.0032 2.15338 30.3269 4.14974C28.9075 8.35786 24.9164 11.3897 20.1988 11.398C15.4813 11.398 11.4735 8.3827 10.0457 4.18288C9.36938 2.18651 7.54081 0.811419 5.42001 0.811419C2.10521 0.811419 -0.20763 4.04206 0.836071 7.165C3.533 15.2167 11.1896 21.0153 20.2072 21.007C29.2248 20.9988 36.8646 15.167 39.5365 7.10702C40.5719 3.98407 38.2507 0.761717 34.9359 0.770001L34.9442 0.761719Z"
          fill="#265767"
        />
      </svg>
    </div>
  );
}
