/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

interface Sides {
  key: string;
  title: string;
}

const initialState = {};

// ==============================|| SLICE - MENU - SIDES ||============================== //

const sidesSlice = createSlice({
  name: 'sides',
  initialState: initialState,
  reducers: {
    listSides: (state, action) => {
      const temp: any = {};
      for (const el of action.payload) {
        temp[el.id] = el;
      }
      return { ...state, ...temp };
    }
  }
});

export default sidesSlice.reducer;

export const { listSides } = sidesSlice.actions;
