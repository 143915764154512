// ==============================|| DRAWER CONTENT ||============================== //

// import { useSelector } from 'react-redux';
// import { RootState } from '../../../store';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import SimpleBar from '../../../components/SimpleBar';
import NavItem from './NavItem';
import { siderbarRoutes } from '../../../routes/MainRoutes';
import useGetColors from '../../../hooks/useGetColor';
import LogoutIcon from '../../../assets/icons/LogoutIcon';
import i18n from '../../../i18n-config';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { onLogout } from '../../../store/auth';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DrawerContent = () => {
  const dispatch = useDispatch();
  const colors = useGetColors();
  const { t } = useTranslation();

  const { drawerOpen } = useSelector((state: RootState) => state.ui);

  const handleLogout = async () => {
    dispatch(onLogout() as any);
  };

  const onClick = (item: any) => {
    switch (item.path) {
      case '/news':
        window.open('https://tchopme.com/actualites', '_blank');
        break;
      case '/faqs':
        window.open('https://tchopme.com/foireauquestionsclients', '_blank');
        break;
      case '/about':
        window.open('https://tchopme.com/a-propos-de-tchop-me', '_blank');
        break;

      default:
        window.open('https://tchopme.com/a-propos-de-tchop-me', '_blank');
        break;
    }
  };

  return (
    <SimpleBar
      sx={{
        height: '100%',
        bgcolor: colors.primary.main,
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          pt: 2,
          height: 'calc(100vh - 80px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box
          px={2}
          justifyContent={'space-between'}
          display={'flex'}
          flexDirection={'column'}
        >
          {siderbarRoutes.slice(0, 6).map((item, index) => (
            <NavItem key={index} item={item} level={1} />
          ))}
        </Box>

        <List>
          <Box
            px={2}
            justifyContent={'space-between'}
            display={'flex'}
            flexDirection={'column'}
          >
            {siderbarRoutes.slice(6, 9).map((item, index) => {
              const Icon = item?.icon;
              return (
                <Button
                  key={index}
                  variant="text"
                  sx={{
                    px: 3
                  }}
                  onClick={() => onClick(item)}
                >
                  <Stack direction={'row'} sx={{ width: 1 }}>
                    <Icon
                      style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }}
                    />
                    <Typography
                      sx={{
                        color: colors.info.contrastText,
                        fontSize: 16,
                        fontWeight: 500,
                        letterSpacing: '0.374px',
                        textTransform: 'capitalize',
                        ml: 3
                      }}
                    >
                      {t(item?.title)}
                    </Typography>
                  </Stack>
                </Button>
              );
              // <NavItem key={index} item={item} level={1} />
            })}
          </Box>
          <ListItem disablePadding sx={{ px: 3 }} onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: '0.374px',
                  color: '#FFFFFF',
                  ml: 3
                }}
              >
                {drawerOpen ? i18n.t('logout') : null}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </SimpleBar>
  );
};

export default DrawerContent;
