import { Button, CircularProgress, SxProps } from '@mui/material';

export default function LoadingButton({
  type = 'button',
  title = 'Enregister',
  onClick,
  loading,
  disabled,
  sx
}: {
  title?: string;
  type?: 'button' | 'reset' | 'submit' | undefined;
  loading?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  onClick?: () => void;
}) {
  return (
    <Button
      disableElevation
      fullWidth
      type={type}
      variant="contained"
      color="primary"
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : null
      }
      sx={{ ...sx }}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
