import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useGetColors from '../../hooks/useGetColor';
import { capitalizeFirstLetter } from '../../utils/stringUtils';

interface Props {
  title: string;
  rootStyle?: SxProps;
  contentStyle?: SxProps;
  showBorderBottom?: boolean;
  children?: ReactNode;
  leftComponent?: ReactNode;
  lastDateUpdate?: string;
  rightComponent?: ReactNode;
  showUpdates?: boolean;
}

function SettingsDetailTitle({
  title,
  children,
  rootStyle,
  leftComponent,
  showBorderBottom,
  contentStyle,
  rightComponent,
  showUpdates = false,
  lastDateUpdate = '10 Sept 2023'
}: Props) {
  const { t } = useTranslation();

  const colors = useGetColors();

  return (
    <Box sx={{ ...rootStyle }}>
      <Box sx={{ ...contentStyle }}>
        <Typography className="admin-title1">
          {capitalizeFirstLetter(t(title))}
        </Typography>
        <Stack direction="row">
          {leftComponent}
          {showUpdates ? (
            <Stack direction={'row'} spacing={1}>
              <Typography fontSize={14} color={'#8392A1'}>
                {t('last_update')}
              </Typography>
              <Typography fontSize={14}>
                {capitalizeFirstLetter(lastDateUpdate)}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        {rightComponent}
      </Box>
      {children}
      {showBorderBottom ? <Divider /> : null}
    </Box>
  );
}

export default SettingsDetailTitle;
