/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function NoImageIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5365 14.3737C20.6786 14.25 22.1182 14.25 23.9548 14.25H24.0452C25.8818 14.25 27.3214 14.25 28.4635 14.3737C29.6291 14.5 30.5734 14.7623 31.3798 15.3482C31.8679 15.7028 32.2972 16.1321 32.6518 16.6202C33.2377 17.4266 33.5 18.3709 33.6263 19.5365C33.75 20.6786 33.75 22.1182 33.75 23.9547V24.006C33.75 24.9403 33.75 25.7682 33.7348 26.5005C33.6903 28.6546 33.5217 30.1825 32.6518 31.3798C32.2972 31.8679 31.8679 32.2972 31.3798 32.6518C30.5734 33.2377 29.6291 33.5 28.4635 33.6263C27.3214 33.75 25.8818 33.75 24.0453 33.75H23.9547C22.1182 33.75 20.6786 33.75 19.5365 33.6263C18.3709 33.5 17.4266 33.2377 16.6202 32.6518C16.1498 32.3101 15.734 31.899 15.3871 31.4327C15.374 31.4151 15.361 31.3975 15.3482 31.3798C14.7623 30.5734 14.5 29.6291 14.3737 28.4635C14.25 27.3214 14.25 25.8818 14.25 24.0453V23.9548C14.25 22.1182 14.25 20.6786 14.3737 19.5365C14.5 18.3709 14.7623 17.4266 15.3482 16.6202C15.7028 16.1321 16.1321 15.7028 16.6202 15.3482C17.4266 14.7623 18.3709 14.5 19.5365 14.3737ZM32.2453 25.7517L32.1823 25.6908C31.9063 25.4257 31.7024 25.2463 31.5106 25.1101C29.3618 23.5845 26.3978 23.9885 24.7358 26.0336C24.535 26.2806 24.3341 26.6097 24.0182 27.1622L23.5 27C22.3045 26.7609 21.7067 26.6413 21.1581 26.653C19.6898 26.6842 18.3096 27.3593 17.3835 28.4991C17.0748 28.879 16.8246 29.354 16.387 30.2263C16.441 30.3218 16.4991 30.412 16.5617 30.4981L16.5904 30.5372C16.8469 30.8818 17.1542 31.1857 17.5019 31.4383C18.0099 31.8074 18.6601 32.0225 19.698 32.135C20.75 32.249 22.1084 32.25 24 32.25C25.8916 32.25 27.25 32.249 28.302 32.135C29.3399 32.0225 29.9901 31.8074 30.4981 31.4383C30.8589 31.1762 31.1762 30.8589 31.4383 30.4981C32 29.725 32.19 28.6526 32.2352 26.4695C32.2399 26.2406 32.2431 26.0016 32.2453 25.7517ZM23 23C21.8954 23 21 22.1046 21 21C21 19.8954 21.8954 19 23 19C24.1046 19 25 19.8954 25 21C25 22.1046 24.1046 23 23 23Z"
        fill="#8392A2"
      />
    </svg>
  );
}
