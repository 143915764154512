import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useMenu } from './useMenu';
import { useMemo } from 'react';
import { calculRestauRating } from '../utils/stringUtils';

export default function useAuth() {
  const user: any = useSelector((state: RootState) => state.auth.userData);
  const restaurant: any = useSelector(
    (state: RootState) => state.auth.restaurant
  );
  const paymentMethod: any = useSelector(
    (state: RootState) => state.auth.paymentMethod
  );
  const { mainMeals } = useMenu();
  const number_of_rating = Number(
    mainMeals
      .map((el: any) =>
        Object.values(el?.rating).reduce((acc: any, el: any) => acc + el, 0)
      )
      .reduce((acc: any, el: any) => acc + el, 0) as number
  );

  const restauRating = useMemo(() => {
    if (!mainMeals.length || number_of_rating === 0) {
      return 0;
    }
    const result: any | number = mainMeals
      .filter((el: any) =>
        Object.values(el?.rating).reduce((acc: any, el: any) => acc + el, 0)
      )
      .map((el: any) => el?.rating)
      .map((el: any) => calculRestauRating(el))
      .reduce((acc: any, el: any) => acc + el, 0);

    return Number((result / number_of_rating).toFixed(1));
  }, [mainMeals, number_of_rating]);
  return {
    user,
    restaurant,
    restauRating,
    paymentMethod
  };
}
