import { Box } from '@mui/material';
import React from 'react';

export default function YellowEditIcon() {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M18.75 28.4375H11.25C4.4625 28.4375 1.5625 25.5375 1.5625 18.75V11.25C1.5625 4.4625 4.4625 1.5625 11.25 1.5625H13.75C14.2625 1.5625 14.6875 1.9875 14.6875 2.5C14.6875 3.0125 14.2625 3.4375 13.75 3.4375H11.25C5.4875 3.4375 3.4375 5.4875 3.4375 11.25V18.75C3.4375 24.5125 5.4875 26.5625 11.25 26.5625H18.75C24.5125 26.5625 26.5625 24.5125 26.5625 18.75V16.25C26.5625 15.7375 26.9875 15.3125 27.5 15.3125C28.0125 15.3125 28.4375 15.7375 28.4375 16.25V18.75C28.4375 25.5375 25.5375 28.4375 18.75 28.4375Z"
          fill="#D77910"
        />
        <path
          d="M10.625 22.1117C9.86248 22.1117 9.16248 21.8367 8.64998 21.3367C8.03748 20.7242 7.77498 19.8367 7.91248 18.8992L8.44998 15.1367C8.54998 14.4117 9.02498 13.4742 9.53748 12.9617L19.3875 3.11172C21.875 0.624219 24.4 0.624219 26.8875 3.11172C28.25 4.47422 28.8625 5.86172 28.7375 7.24922C28.625 8.37422 28.025 9.47422 26.8875 10.5992L17.0375 20.4492C16.525 20.9617 15.5875 21.4367 14.8625 21.5367L11.1 22.0742C10.9375 22.1117 10.775 22.1117 10.625 22.1117ZM20.7125 4.43672L10.8625 14.2867C10.625 14.5242 10.35 15.0742 10.3 15.3992L9.76248 19.1617C9.71248 19.5242 9.78748 19.8242 9.97498 20.0117C10.1625 20.1992 10.4625 20.2742 10.825 20.2242L14.5875 19.6867C14.9125 19.6367 15.475 19.3617 15.7 19.1242L25.55 9.27422C26.3625 8.46172 26.7875 7.73672 26.85 7.06172C26.925 6.24922 26.5 5.38672 25.55 4.42422C23.55 2.42422 22.175 2.98672 20.7125 4.43672Z"
          fill="#D77910"
        />
        <path
          d="M24.8125 12.2859C24.725 12.2859 24.6375 12.2734 24.5625 12.2484C21.275 11.3234 18.6625 8.71089 17.7375 5.42339C17.6 4.92339 17.8875 4.41089 18.3875 4.26089C18.8875 4.12339 19.4 4.41089 19.5375 4.91089C20.2875 7.57339 22.4 9.68589 25.0625 10.4359C25.5625 10.5734 25.85 11.0984 25.7125 11.5984C25.6 12.0234 25.225 12.2859 24.8125 12.2859Z"
          fill="#D77910"
        />
      </svg>
    </Box>
  );
}
