import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const MENU_IMAGES_DIRECTORY = 'MENU_IMAGES';

type ArgsType = {
  file: any;
  path?: string;
  directory?: string;
};

export const uploadImage = async ({
  file,
  path,
  directory = MENU_IMAGES_DIRECTORY
}: ArgsType) => {
  try {
    const imageID = uuidv4();
    const storage = getStorage();
    const storageRef = ref(storage, `${path || directory}/${imageID}`);

    const uploadTask = await uploadBytes(storageRef, file);

    const url = (await getDownloadURL(uploadTask.ref)) as string;

    return url;
  } catch (error) {
    console.log('image uploading error', error);
  }
};

export const getImageUrl = (img: string) => {
  const type = typeof img;
  if (type === 'string') {
    return img;
  }
  return '';
};

export const generateNewBanners = (data: any) => {
  if (data?.length) {
    const originalArray = [...data];
    if (originalArray?.length < 6) {
      // Calculate the number of additional items needed
      const additionalItemCount = 6 - originalArray?.length;

      // Create a function to generate a new item
      const generateNewItem = () => ({
        id: 0,
        image: '',
        isPrimary: false
      });

      // Add additional items to the original array
      for (let i = 1; i <= additionalItemCount; i++) {
        originalArray.push(generateNewItem());
      }
    }

    return originalArray.map((item, index) => {
      return {
        image: typeof item === 'string' ? item : '',
        id: index,
        isPrimary: false
      };
    });
  } else {
    return [
      { id: 0, image: '', isPrimary: false },
      { id: 1, image: '', isPrimary: false },
      { id: 2, image: '', isPrimary: false },
      { id: 3, image: '', isPrimary: false },
      { id: 4, image: '', isPrimary: false },
      { id: 5, image: '', isPrimary: false }
    ];
  }
};

export const generateSettingBanner = (data: any) => {
  if (data?.length) {
    const originalArray = [...data];
    if (originalArray?.length < 6) {
      const additionalItemCount = 5 - originalArray?.length;
      for (let i = 1; i <= additionalItemCount; i++) {
        const generateNewItem = () => ({
          id: 0,
          image: '',
          isPrimary: false
        });
        originalArray.push(generateNewItem());
      }
    }

    return originalArray.map((item, index) => {
      return {
        image: item.image,
        id: index,
        isPrimary: item.isPrimary
      };
    });
  } else {
    return [
      { id: 0, image: '', isPrimary: false },
      { id: 1, image: '', isPrimary: false },
      { id: 2, image: '', isPrimary: false },
      { id: 3, image: '', isPrimary: false },
      { id: 4, image: '', isPrimary: false },
      { id: 5, image: '', isPrimary: false }
    ];
  }
};
