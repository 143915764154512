import React from 'react';

export default function BlueEmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M14.1667 17.0846H5.83332C3.33332 17.0846 1.66666 15.8346 1.66666 12.918V7.08464C1.66666 4.16797 3.33332 2.91797 5.83332 2.91797H14.1667C16.6667 2.91797 18.3333 4.16797 18.3333 7.08464V12.918C18.3333 15.8346 16.6667 17.0846 14.1667 17.0846Z"
        fill="#265767"
      />
      <path
        d="M10 10.7259C9.30001 10.7259 8.59168 10.5093 8.05001 10.0676L5.44167 7.98429C5.17501 7.76762 5.12501 7.37595 5.34168 7.10928C5.55834 6.84262 5.95001 6.79262 6.21668 7.00929L8.82501 9.09262C9.45834 9.60096 10.5333 9.60096 11.1667 9.09262L13.775 7.00929C14.0417 6.79262 14.4417 6.83428 14.65 7.10928C14.8667 7.37595 14.825 7.77595 14.55 7.98429L11.9417 10.0676C11.4083 10.5093 10.7 10.7259 10 10.7259Z"
        fill="#265767"
      />
    </svg>
  );
}
