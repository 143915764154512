import React from 'react';

export default function OrdersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.6201 9.62012H12.3701C11.9601 9.62012 11.6201 9.28012 11.6201 8.87012C11.6201 8.46012 11.9601 8.12012 12.3701 8.12012H17.6201C18.0301 8.12012 18.3701 8.46012 18.3701 8.87012C18.3701 9.28012 18.0401 9.62012 17.6201 9.62012Z"
        fill="white"
      />
      <path
        d="M7.11957 10.3798C6.92957 10.3798 6.73957 10.3098 6.58957 10.1598L5.83957 9.40982C5.54957 9.11982 5.54957 8.63982 5.83957 8.34982C6.12957 8.05982 6.60957 8.05982 6.89957 8.34982L7.11957 8.56982L8.83957 6.84982C9.12957 6.55982 9.60957 6.55982 9.89957 6.84982C10.1896 7.13982 10.1896 7.61982 9.89957 7.90982L7.64957 10.1598C7.50957 10.2998 7.31957 10.3798 7.11957 10.3798Z"
        fill="white"
      />
      <path
        d="M17.6201 16.6201H12.3701C11.9601 16.6201 11.6201 16.2801 11.6201 15.8701C11.6201 15.4601 11.9601 15.1201 12.3701 15.1201H17.6201C18.0301 15.1201 18.3701 15.4601 18.3701 15.8701C18.3701 16.2801 18.0401 16.6201 17.6201 16.6201Z"
        fill="white"
      />
      <path
        d="M7.11957 17.3798C6.92957 17.3798 6.73957 17.3098 6.58957 17.1598L5.83957 16.4098C5.54957 16.1198 5.54957 15.6398 5.83957 15.3498C6.12957 15.0598 6.60957 15.0598 6.89957 15.3498L7.11957 15.5698L8.83957 13.8498C9.12957 13.5598 9.60957 13.5598 9.89957 13.8498C10.1896 14.1398 10.1896 14.6198 9.89957 14.9098L7.64957 17.1598C7.50957 17.2998 7.31957 17.3798 7.11957 17.3798Z"
        fill="white"
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill="white"
      />
    </svg>
  );
}
