const textFR = {
  french: "Français",
  english: "Anglais",
  menu: "Menu",
  drivers: "Tchop-riders",
  orders: "Commandes",
  billing: "Facturation",
  report_statistics: "Rapports & Stats",
  feedback: "Feedback",
  meals: "Plats",
  restaurant: "Tchop-resto",
  couriers: "Tchop-riders",
  customers_reviews: "Avis clients",
  your_rating: "Votre note",
  created_on: "Créé le",
  votes: "Votes",
  last_updated: "Dernières mises à jour",
  overall_rating: "Notes globales",
  total_menus: "Nombre total de  menus",
  total_riders: "Nombre total de Tchop-riders",
  ongoing_deliveries: "Livraisons en cours",
  complete_deliveries: "Livraisons terminées",
  total_deliveries: "Total des livraisons",
  most_order_meals: "Les plats les plus commandés",
  last_3_days: "Les 3 derniers jours",
  waiting_deliveries: "En attente de livraisons",
  go_to_deliveries: "Envoyé en livraison ",
  drivers_list: "Liste des Tchop-riders",
  new: "Nouveau",
  driver_name: "Nom du Tchop-rider",
  driver_id: "ID du Tchop-rider",
  contact: "Contact",
  registration_date: "Date d'inscription",
  paid: "Payé",
  actions: "Actions",
  report: "Signaler",
  driver_details: "Détails du Tchop-rider",
  list_of: "Liste de",
  page: "Page",
  report_driver: "Signaler le Tchop-rider",
  bad_driving: "Mauvaise conduite",
  issue_desc: "Problèmes de livraison du colis",
  cold_starters: "Entrées froides",
  issue_with_drivers: "Problèmes avec le Tchop-riders",
  lost_an_item: "Perte d'un article",
  other: "Autres",
  type_here: "Saisir ici",
  destination: "Destination",
  destinations: "Destinations",
  courrier_track: "Identifiant de suivi du Tchop-rider. ",
  items: "Articles",
  status: "Statut",
  departure_date: "Date de départ",
  arrival_date: "Date d'arrivée",
  time_delay: "Délai",
  orders_details: "Détails de la commande",
  company_info: "Infos sur l'établissement",
  company_info_desc: "Nom, Contact",
  availabilities: "Disponibilités",
  availabilities_desc: "Horaires d'ouverture",
  payment_method: "Methode de paiement",
  payment_method_desc: "Spécifie le mode de paiement du client",
  security: "Sécurité",
  security_desc: "Mot de passe, accès",
  theme: "Thème",
  theme_desc: "Définir les couleurs",
  theme_settings: "Paramètres des thèmes",
  change_color_polor:
    "Pour personnaliser votre expérience sur la plateforme, Tchop.me vous laisse la possibilité de modifier la palette de couleurs de votre portail de gestion d'établissement.",
  primary: "Couleur primaire",
  secondary: "Couleur secondaire",
  accent: "Couleur d'accentuation",
  primary_desc:
    "La teinte principale de votre marque, sera appliquée à la majorité des composants de l'application.",
  secondary_desc:
    "La teinte complémentaire de votre marque, est destinée avant tout à servir de couleur de survol ou à être appliquée sur des éléments secondaires.",
  accent_desc:
    "Cette teinte est choisie pour son contraste élevé, afin de mettre en exergue les composants essentiels de votre portail",
  last_update: "Dernière mise à jour",
  save_changes: "Sauvegarder les modifications",
  company_availabilities: "Disponibilités de l'établissement",
  closed: "Fermé",
  opened: "Ouvert",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  add_time: "Ajouter du temps",
  edit_availability: "Modifier les disponibilités",
  edit_availability_desc:
    "Sélectionnez le type d'aliment que vous souhaitez ajouter à la liste",
  order_at: "Commander à",
  ordered_from: "Commandé auprès de",
  customer_contact: "Coordonnées du client",
  delivery_details: "Détails de livraison",
  order_pick_up: "Retrait de la commande",
  miles: "Kilomètre(s)",
  minutes: "Minutes",
  grand_total: "Total",
  delivery_fee: "Frais de livraison",
  on_going: "En cours",
  ordered_at: "Commander à",
  order_id: "Identifiant de la commande",
  customer_id: "Identifiant du client",
  last_order: "Dernière commande",
  total_orders: "Commande globale",
  user_details: "Détails de l'utilisateur",
  money_spend: "Montant dépensé",
  total_ordered: "Total des commandes",
  users: "Utilisateurs",
  categories: "Catégories",
  create_item: "Créer un article",
  no_item_found: "Aucun article trouvé",
  add_category: "Ajouter une catégorie",
  new_item: "Nouvel article",
  edit_item: "Modifier un article",
  new_item_desc:
    "Créez un nouvel article qui apparaîtra dans le menu de votre restaurant",
  unit_price: "Prix unitaire",
  item_name: "Nom de l'article",
  item_image: "Image(s) de l'article",
  no_image: "Aucune image",
  no_categories: "Aucune catégorie",
  add_categories: "Ajouter des catégories",
  add_categories_desc:
    "Sélectionnez le type d'aliment qui figurera sur votre menu.",
  search: "Rechercher",
  selected: "Sélectionnés",
  details: "Détails",
  earnings: "Revenues",
  ordered_meals: "Plats commandés",
  riders: "Tchop-riders",
  total_meals: "Total repas",
  ongoing_orders: "Commandes en cours",
  completed_orders: "Commandes terminées",
  totals_orders: "Total des livraisons",
  yesterday: "Hier",
  last_week: "La semaine dernière",
  this_month: "Ce mois",
  last_month: "Le mois dernier",
  this_year: "Cette année",
  last_year: "L'année dernière",
  all_time: "Récurrent",
  onDelivery: "En livraison",
  ordered_time: "Heure commandée",
  cost: "Coût",
  order_details: "Détails de la commande",
  order_preparation: "Préparation de commande",
  delivery_address: "Adresse de livraison",
  order_items: "Articles commandés",
  cancel: "Annuler",
  confirm: "Confirmer",
  order_ready: "Votre commande est prête !",
  price: "Prix",
  enter_amount: "Saisir le montant",
  click_upload: "Cliquer pour téléverser votre visuel",
  drag_drop: "Glisser-déposer",
  tag: "Étiquette",
  subtag: "Sous-tag",
  email: "E-mail",
  password: "Mot de passe",
  forgot_desc: "Mot de passe oublié ?",
  log_desc:
    "En vous connectant, vous consentez de ce fait aux conditions de sécurité établies pour les commerçants de Tchop.me !",
  log_in: "Se connecter",
  no_account: "Aucun compte",
  parter_with: " Associez-vous à Tchop.me ! pour une collaboration réussie.",
  register: "S'inscrire",
  business_location: "Emplacement de l'établissement",
  mobile_number: "Numéro de téléphone mobile",
  get_started: "Démarrer",
  restaurant_name: "Nom de l'établissement",
  register_desc:
    "Entrez en partenariat avec Tchop.me !, pour agrandir votre chiffres d'affaires par l'application mais aussi sur votre site web.",
  register_title:
    "Optimisez le potentiel de votre entreprise avec une large gamme d'opportunités de croissance.",
  already_have: "Possédez-vous déjà un compte?",
  available: "Disponible",
  unavailable: "Indisponible",
  yes: "Oui",
  no: "Non",
  rating_chart: "Répartition des évaluations",
  add_a_comment: "Ajouter un commentaire",
  home: "Accueil",
  preparing: "En préparation",
  ready: "Prêt",
  orders_history: "Historiques",
  delivered: "Livré",
  placeholder_desc: "Aucune donnée trouvée",
  confirm_password: "Veuillez confirmer votre mot de passe.",
  shipping_desc: "Livraison en cours",
  find_order: "Rechercher une commande",
  pass_desc:
    "Le mot de passe incorrect. Veuillez essayer à nouveau ou faire mot de passe oublié.",
  add_payment_method: "Ajouter un mode de paiement",
  no_payment_desc: "Aucun mode de paiement",
  new_payment: "Nouveau mode de paiement",
  new_payment_desc: "Ce mode de paiement est à la disposition des clients.",
  payment_type: "Mode de paiement",
  credit_debit_card: "Carte de crédit/débit",
  mobile_money: "Mobile money",
  paypal: "Paypal",
  bank_account: "Compte bancaire",
  name_card: "Nom sur la carte",
  card_number: "Numéro de la carte",
  expiry_date: "Date d'expiration",
  cvv: "CVV",
  account_name: "Nom du compte",
  account_number: "Numéro de compte",
  credit_card: "Carte de crédit",
  add_credit_card: "Ajouter une carte de crédit",
  momo_account: "Compte mobile money",
  other_payment_method: "Autre mode de paiement",
  empty: "Vide",
  logout: "Déconnexion",
  find_driver: "Trouver un Tchop-rider",
  find_a_user: "Trouver un utilisateur",
  reg_loc_desc:
    "Activez votre géolocalisation en temps réel afin que nous puissions détecter votre adresse.",
  total_sales: "Total des ventes",
  no_order_today: "Aucune commande aujourd'hui",
  discard_changes: "Annuler les modifications",
  company_location: "Emplacement de l'établissement",
  company_name: "Nom de l'établissement",
  owner_name: "Nom du propriétaire",
  type: "Type",
  company_contact: "Coordonnées de l'établissement",
  email_address: "Adresse e-mail",
  description: "Description",
  text_here: "Texte ici",
  country: "Pays",
  state: "État",
  city: "Ville",
  address_line: "Ligne d'addresse",
  or_select_map: "Sélectionner sur la carte",
  enter_address: "Saisir l'adresse",
  enter_country: "Saisir le pays",
  enter_state: "Saisir l'état",
  enter_city: "Saisir la ville",
  click_upload_image: "Cliquez pour téléverser une image.",
  click_upload_image_desc:
    "Ce visuel apparaîtra sur le profil de votre établissement",
  click_upload_image_format: "SVG, PNG, JPG ou GIF (max. 800x400px)",
  item: "Article",
  select: "Sélectionner",
  km_away: "Km de distance",
  warning_desc:
    'Une fois attribuée à un Tchop-rider, le statut de votre commande passera à "Prêt", permettant son suivi en direct.',
  waiting_driver: "En attente du Tchop rider",
  driver_onroad: "Le Tchop-rider est en route",
  banner_image: "Visuels de banniere",
  banner_image_desc:
    "Ajoutez des photos pour aider les clients à visualiser et apprécier votre établissement.",
  banner_image_desc2: "PNG, JPG (max. ***x***px)",
  added: "Ajouté",
  tap_add: "Appuyez pour ajouter",
  update_success: "Mise à jour réussie",
  create_success: "Créé avec succès",
  delete_success: "Supprimé avec succès",
  error_occured: "Une erreur est survenue",
  service_fee: "Frais de service",
  report_stat: "Rapports & statistiques",
  news: "Actualités",
  about_desc: "À propos de Tchop.me!",
  settings: "Paramètres",
  show_hotdeal: "Promouvoir un plat en tant que Hot deal 🔥?",
  show_hotdeal_desc:
    'Ce plat sera spécifiquement valorisé au sein de notre sélection "Hot deal 🔥". Cette distinction vise à attirer l\'attention des clients ',
  sides: "Plats d'accompagnement",
  sides_desc: "Ce plat est accompagné d'un accompagnement",
  included: "Inclus",
  included_desc:
    "Veuillez choisir les extras à ajouter à ce plat. Ces options seront présentées au client au moment de sa commande.",
  add: "Ajouter",
  unit_price_desc: "Ce tarif inclut le coût du plat d'accompagnement.",
  main_tag: "Étiquette principale",
  customize_tag: "Étiquette personnalisé",
  customize_tag_desc:
    "Veuillez séparer chaque étiquette par une virgule afin d'en ajouter plusieurs.",
  hot_deals: "Hot deals 🔥",
  what_location_desc: "Quelle est votre emplacement exacte?",
  content_desc:
    "L'indication de votre localisation améliore l'exactitude de la recherche et de la livraison, garantit un suivi de commande clair et permet de vous offrir des recommandations sur mesure.",
  marker_desc:
    "Ce marqueur définit votre emplacement (déplacez-vous sur la carte pour ajuster la position)",
  customer: "Client",
  new_hot_deal: "Nouveau Hot deals 🔥",
  hot_deal_desc:
    "Veuillez choisir un article et lui attribuer une réduction, applicable sur une durée ne dépassant pas dix jours.",
  selected_meal: "Article sélectionné",
  start_date: "Date de début",
  end_date: "Date de fin",
  start_time: "Heure de début",
  end_time: "Fin",
  tap_to_select: "Cliquez pour sélectionner",
  old_price: "Prix initial",
  deal_price: "Montant de la réduction",
  exp_date: "Date d'expiration",
  starters: "Entrées",
  beverage: "Boisson ",
  main_course: "Plats principaux",
  desserts: "Desserts",
  no_items_found: "Aucun élément trouvé",
  deal_old_comp: "Doit être inférieur à la valeur initiale",
  hint: "Indice",
  hot_deal_explication: "Un Hot deals 🔥 à ne pas aller au delà de ",
  day: "Jour",
  today: "Aujourd'hui",
  feb: "Fev",
  apr: "Avril",
  may: "Mai",
  jun: "Juin",
  jul: "Jui",
  aug: "Août ",
  report_stat_drawer: "Rapports & Stats",
  to_day: "Aujourd'hui",
  this_week: "Cette semaine",
  last_sev_day: "7 derniers jours",
  apply: "Appliquer",
  reset: "Réinitialiser",
  add_sides: "Ajouter des side ",
  add_sides_desc:
    "Veuillez sélectionner les plats en accompagnement que vous souhaiteriez ajouter au menu.",
  tag_name: "Nom de l'étiquette",
  tag_length_description: "Pas plus de 3 sous-tags",
  select_meals: "Sélectionner les plats",
  waiting_payment: "En attente du paiement.",
  confirm_meals: "Confirmation de commande",
  confirm_meals_desc:
    "Veuillez confirmer les commandes que vous êtes en mesure d'honorer",
  add_to_extras: "Ajouter aux extras.",
  add_to_extras_desc: "Cet article sera inclus dans la liste des extras.",
  include_in_price:
    "Les prix des suppléments seront inclus dans le prix du plat principal",
  customer_rating: "Évaluation du client",
  username: "Nom d'utilisateur",
  street: "Rue",
  define_as_new:
    'Intégrer un nouveau plat dans la catégorie "Nouveautés" dans l\'application client',
  define_as_new_des:
    "Ce plat sera présenté comme une nouveauté dans notre sélection.",
  open: "Ouvert",
  close: "Fermé",
  delete_meal: "Supprimer le repas",
  delete_meal_desc:
    "La suppression de ce plat sera définitive et irréversible.",
  yes_delete: "Oui, supprimer",
  invalid_email_or_password:
    "Le nom d'utilisateur ou le mot de passe saisi est invalide.",
  restaurant_is_archived:
    "Votre établissement a été archivé. Veuillez contacter notre équipe de support.",
  restaurant_is_blocked:
    "Votre établissement a été bloqué. Veuillez contacter notre équipe de support.",
  restaurant_not_found:
    "Votre établissement n'a pas été trouvé. Veuillez contacter notre service support.",
  someting_went_wrong_try_again:
    "Un problème est survenu. Veuillez réessayer ultérieurement.",
  no_update: "Aucune mise a jour",
  order_is_ready: "La commande est prête!",
  meal_reviews: "Évaluation des plats",
  ratings: "Notes",
  customer_reviews: "Évaluation des clients",
  no_rating: "Aucune note",
  all_dishes: "Tous les plats",
  star_1: "1 étoile",
  star_2: "2 étoiles",
  star_3: "3 étoiles",
  star_4: "4 étoiles",
  star_5: "5 étoiles",
  all: "Tous",
  view: "Vues",
  sidesExtra: "Side / Extra",
  refuse: "Refuser",
  accept: "Accepter",
  save: "sauvegarder",
  subTotal: "sous-total",
  welcome: "bienvenue",
  welcome_text:
    "nous sommes heureux de vous voir prendre le contrôle de votre restaurant grâce à notre application intuitive et pratique.",
  lets_start_by:
    "commençons par enregistrer la géolocalisation de votre restaurant",
  lets_go: "allons-y",
  until: "jusqu'au",
  soon_available: "bientot Disponible",
  go_back_login: "rentrer a la page de connexion",
  enter_email_description:
    "entrez votre adresse e-mail. Un lien de vérification sera envoyé à cette adresse e-mail",
  send_email: "envoyer le lien",
  email_already_send: "un lien de vérification a été envoyé à l'adresse e-mail",
  email_already_send_2: "ouvrez votre boîte email pour terminer la procédure",
  login_page: "page d'accueil",
  something_wrong_email:
    "quelque chose s'est mal passé lors de l'envoi du lien de vérification",
  mobile_money_account: "compte mobile money",
  login_welcome: "Bienvenue sur votre interface restaurant partenaire !",
  time_interval_incorrect: "interval de temps incorrect",
  new_side: "Nouvel accompagnement",
  edit_side: "modifier accompagnement",
  customer_support: "support client",
  customer_support_desc:
    "Êtes-vous perdu ou avez-vous des difficultés à utiliser l'application ? Accédez à la FAQ pour obtenir de l'aide ou envoyez-nous un message WhatsApp et nous vous répondrons dans les plus brefs délais.",
  whatsapp_us: "nous écrire sur whatsapp",
  order_time: "date de commande",
  arrival: "arrivée",
  order_delivered: "commande livrée",
  order_cancelled: "commande annulée",
  active_orders: "commandes active",
};
export default textFR;