import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../components/AppButton';
import useGetColors from '../../../../hooks/useGetColor';
import '../../Settings.css';
import SettingsDetailTitle from '../../SettingsDetailTitle';
import AvailabilityFormModal from './AvailabilityFormModal';
import AvailabilityItem from './AvailabilityItem';

import useAuth from '../../../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { updateRestaurantAsync } from '../../../../store/auth';
import notifySuccess, {
  notifyError,
  notifyInfo
} from '../../../../utils/notify';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import i18n from '../../../../i18n-config';
import { DAYORDER } from '../../../../utils/constant';
import isEqual from 'react-fast-compare';

export default function Availability() {
  const { t } = useTranslation();
  const colors = useGetColors();
  const { restaurant } = useAuth();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [activeHours, setActivehours] = useState<any>(restaurant.hours);

  useEffect(() => {
    setActivehours(restaurant.hours);
  }, [restaurant.hours]);

  const toggleAvailabilityModal = () => {
    setOpenModal(!openModal);
  };

  const onSwitchHandler = (day: string, value: boolean) => {
    setActivehours((prev: any) => ({
      ...prev,
      [day]: { time: prev?.[day]?.time, open: value }
    }));
  };
  const onSaveModal = (day: string, hour: string[]) => {
    setActivehours((prev: any) => ({
      ...prev,
      [day]: { time: hour, open: prev?.[day]?.open }
    }));
  };
  const onError = () => {
    notifyError(capitalizeFirstLetter(i18n.t('error_occured')));
    setLoading(false);
  };

  const callback = () => {
    notifySuccess(capitalizeFirstLetter(i18n.t('update_success')));
    setLoading(false);
  };

  const onSave = () => {
    let data_to_save: any = {};
    if (!isEqual(activeHours, restaurant.hours)) {
      data_to_save.hours = activeHours;
    }
    if (isEqual(data_to_save, {})) {
      notifyInfo(capitalizeFirstLetter(i18n.t('no_update')));
      return;
    }
    setLoading(true);
    data_to_save = {
      ...restaurant,
      hours: activeHours,
      updatedAt: new Date()
    };
    dispatch(
      updateRestaurantAsync({
        data: data_to_save,
        callback,
        onError
      }) as any
    );
  };

  return (
    <Box sx={{ backgroundColor: 'white', minHeight: '100vh' }} pt={1}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5000 }}
        open={loading}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <SettingsDetailTitle
        title="company_availabilities"
        rootStyle={{ mb: 3, ml: 4 }}
      />
      {openModal && (
        <AvailabilityFormModal
          open={openModal}
          handleClose={toggleAvailabilityModal}
          item={selected}
          onSaveHandler={(el) => onSaveModal(el.day, el.time)}
        />
      )}
      {DAYORDER.map((date: any, index: number) => (
        <AvailabilityItem
          key={index}
          item={activeHours?.[date]}
          title={date + ''}
          showBorderBottom
          onSwitched={(el: boolean) => onSwitchHandler(date, el)}
          onEdit={() => {
            setSelected({
              day: date,
              time: activeHours[date]
            });
            toggleAvailabilityModal();
          }}
        />
      ))}

      <Stack direction="row" justifyContent={'flex-end'} m={4}>
        <AppButton
          title={t('save_changes')}
          onClick={onSave}
          containerStyle={{
            height: '44px',
            minWidth: '148px',
            backgroundColor: colors.primary.main
          }}
        />
      </Stack>
    </Box>
  );
}
