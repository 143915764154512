import React from 'react';

export default function OrdersActiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 6.15002H6C3.79 6.15002 2 7.94002 2 10.15V6.53998C2 4.49998 3.65 2.84998 5.69 2.84998H11.31C13.35 2.84998 15 4.11002 15 6.15002Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M17.48 12.2C16.98 12.69 16.74 13.43 16.94 14.18C17.19 15.11 18.11 15.7 19.07 15.7H20V17.15C20 19.36 18.21 21.15 16 21.15H6C3.79 21.15 2 19.36 2 17.15V10.15C2 7.94002 3.79 6.15002 6 6.15002H16C18.2 6.15002 20 7.95002 20 10.15V11.6H18.92C18.36 11.6 17.85 11.82 17.48 12.2Z"
        fill="white"
      />
      <path
        d="M22 12.62V14.68C22 15.24 21.54 15.7 20.97 15.7H19.04C17.96 15.7 16.97 14.91 16.88 13.83C16.82 13.2 17.06 12.61 17.48 12.2C17.85 11.82 18.36 11.6 18.92 11.6H20.97C21.54 11.6 22 12.06 22 12.62Z"
        fill="white"
      />
      <path
        d="M13 11.9H7C6.59 11.9 6.25 11.56 6.25 11.15C6.25 10.74 6.59 10.4 7 10.4H13C13.41 10.4 13.75 10.74 13.75 11.15C13.75 11.56 13.41 11.9 13 11.9Z"
        fill="white"
      />
    </svg>
  );
}
