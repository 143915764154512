import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import textEN from './assets/locale/en';
import textFR from './assets/locale/fr';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    debug: false,
    resources: {
      en: {
        translation: {
          ...textEN
        }
      },
      fr: {
        translation: {
          ...textFR
        }
      }
    }
  });

export default i18n;
