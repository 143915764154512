/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { uploadImage } from '../utils/imageUtils';
import { AppDispatch } from '.';
import { COLLECTIONS_NAMES, MEAL_TYPES } from '../utils/constant';
import { updatingMenu } from './ui';
import { updateRestaurant } from './auth';
import { fromJsToFirebase } from '../utils/dateutils';

// ==============================|| SLICE - MENU ||============================== //
interface Rating {
  '1': number;
  '2': number;
  '3': number;
  '4': number;
  '5': number;
}

export interface Menu {
  id?: string;
  categoryID: string;
  restaurantID: string;
  currency: string;
  userID: string;
  name: string;
  price: number;
  images: string[];
  tagFilters?: string[];
  hotDealPrice?: number;
  description?: string;
  tags?: string[];
  rating: Rating;
  isAvailable?: boolean;
  newMealStartedAt?: string;
  hotDealStartedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  isSide?: boolean;
  hasSide?: boolean;
  isDeleted: boolean;
  type?: string;
}

const menuSlice = createSlice({
  name: 'menu',
  initialState: {},
  reducers: {
    listMenus: (state, action) => {
      const temp: any = {};
      for (const el of action.payload) {
        temp[el.id] = el;
      }
      return { ...state, ...temp };
    },
    updateMenu: (state, action) => {
      const temp: any = {};
      for (const el of action.payload) {
        temp[el.id] = el;
      }
      return { ...temp };
    },
    createMenu: (state, action) => {
      const payload = action.payload;
      return { ...state, [payload.id]: payload };
    }
  }
});

export const { listMenus, createMenu, updateMenu } = menuSlice.actions;

export const createAsyncMenu =
  (data: any, callback?: () => void, onError?: () => void) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(updatingMenu(true));

      const user = getState().auth.userData;
      const restaurant = getState().auth.restaurant;
      const mealID = uuidv4();
      const userID = user.id;
      const groupID = user.groupID;

      const data_to_save = {
        ...data,
        restaurantID: restaurant?.id,
        isDeleted: false,
        type: data?.isSide ? MEAL_TYPES.SECONDARY : MEAL_TYPES.MAIN,
        id: mealID,
        createdAt: new Date(),
        updatedAt: new Date(),
        sideIDs: data.sideIDs,
        userID,
        groupID
      };
      let docPath = '';
      if (data?.isSide) {
        docPath = `${COLLECTIONS_NAMES.SIDES}/${mealID}`;
        delete data_to_save.hasSide;
      } else {
        docPath = `${COLLECTIONS_NAMES.DISHES_BEVERAGE}/${mealID}`;
      }

      const docRef = doc(firestore, docPath);

      let image_to_upload: any[] = [];
      if (data.images?.length) {
        image_to_upload = data.images.map(async (el: any, index: number) =>
          uploadImage({
            file: el?.image
          })
        );

        await Promise.all(image_to_upload)
          .then(async (results) => {
            await setDoc(
              docRef,
              { ...data_to_save, images: results },
              { merge: true }
            );
          })
          .catch((error) => console.log('promise error', error));
      }
      callback?.();
    } catch (error) {
      console.log('error creating menu', error);
      onError?.();
    } finally {
      dispatch(updatingMenu(false));
    }
  };

export const updateAsyncMenu =
  (data: any, callback?: () => void, onError?: () => void) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(updatingMenu(true));
      const user = getState().auth.userData;
      let docRef: any = null;
      if (!data.isSide) {
        docRef = doc(
          firestore,
          `${COLLECTIONS_NAMES.DISHES_BEVERAGE}/${data.id}`
        );
      } else {
        docRef = doc(firestore, `${COLLECTIONS_NAMES.SIDES}/${data.id}`);
      }
      if (data?.images) {
        const newImages = data.images?.filter(
          (image: any) => typeof image.image !== 'string'
        );
        const old = data.images?.filter(
          (image: any) => typeof image.image === 'string'
        );
        const finalOldImages: any[] = [];
        for (let i = 0; i < old.length; i++) {
          finalOldImages.push(old[i].image);
        }
        let newUrl: any[] = finalOldImages;
        if (newImages?.length) {
          const image_to_upload = newImages.map((image: any, index: number) =>
            uploadImage({
              file: image.image
            })
          );

          try {
            const results = await Promise.all(image_to_upload);
            newUrl = [...finalOldImages, ...results];
          } catch (error) {
            console.log('upload image error', error);
          }
        }
        await setDoc(
          docRef,
          {
            ...data,
            images: newUrl,
            updatedAt: new Date(),
            userID: ''
          },
          { merge: true }
        );
      } else {
        await setDoc(
          docRef,
          {
            ...data,
            updatedAt: new Date(),
            userID: user.id
          },
          { merge: true }
        );
      }

      callback?.();
    } catch (error) {
      onError?.();
      console.log('update menu error', error);
    } finally {
      dispatch(updatingMenu(false));
    }
  };

export const manageMenuAsync =
  (data: any, callback?: () => void, onError?: (err: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(updatingMenu(true));
      let docRef: any = null;
      if (data.type === MEAL_TYPES.MAIN) {
        docRef = doc(
          firestore,
          `${COLLECTIONS_NAMES.DISHES_BEVERAGE}/${data.id}`
        );
      } else {
        docRef = doc(firestore, `${COLLECTIONS_NAMES.SIDES}/${data.id}`);
      }
      await setDoc(
        docRef,
        {
          ...data,
          createdAt: fromJsToFirebase(data?.createdAt),
          updatedAt: new Date()
        },
        { merge: true }
      );
    } catch (error) {
      onError?.(error);
      console.log('error managin hot deal', error);
    } finally {
      dispatch(updatingMenu(false));
      callback?.();
    }
  };

export const deleteHotDeal =
  (data: any, callback?: () => void, onError?: (err: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(updatingMenu(true));
      let docRef: any = null;
      if (data.type === MEAL_TYPES.MAIN) {
        docRef = doc(
          firestore,
          `${COLLECTIONS_NAMES.DISHES_BEVERAGE}/${data.id}`
        );
      } else {
        docRef = doc(firestore, `${COLLECTIONS_NAMES.SIDES}/${data.id}`);
      }
      await setDoc(docRef, {
        ...data,
        createdAt: fromJsToFirebase(data?.createdAt),
        updatedAt: new Date()
      });
    } catch (error) {
      onError?.(error);
      console.log('error managin hot deal', error);
    } finally {
      dispatch(updatingMenu(false));
      callback?.();
    }
  };

export const deleteAsyncMenu =
  (item: any, callback?: () => void, onError?: () => void) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(updatingMenu(true));
      const menu: any[] = Object.values(getState().menu);
      const result = menu.filter((el) => el.id !== item.id);
      let docRef: any = null;

      if (item.type === MEAL_TYPES.MAIN) {
        docRef = doc(
          firestore,
          `${COLLECTIONS_NAMES.DISHES_BEVERAGE}/${item.id}`
        );
      } else {
        docRef = doc(firestore, `${COLLECTIONS_NAMES.SIDES}/${item.id}`);
      }
      await setDoc(
        docRef,
        {
          updatedAt: new Date(),
          isDeleted: true
        },
        { merge: true }
      );

      dispatch(updateMenu(result));
      callback?.();
    } catch (error) {
      onError?.();
      console.log('update menu error', error);
    } finally {
      dispatch(updatingMenu(false));
    }
  };

export const saveCategoriesAsync =
  (data: any, callback?: () => void, onError?: (err: any) => void) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(updatingMenu(true));

      const restaurant = getState().auth.restaurant;

      const docRef = doc(
        firestore,
        `${COLLECTIONS_NAMES.RESTAURANTS}/${restaurant.id}`
      );

      const data_to_save = {
        categories: data,
        updatedAt: new Date()
      };
      await setDoc(docRef, data_to_save, { merge: true });

      const final_data = {
        ...restaurant,
        createdAt: fromJsToFirebase(data?.createdAt),
        ...data_to_save
      };
      dispatch(updateRestaurant(final_data));
    } catch (error) {
      onError?.(error);
      console.log('error updating categories', error);
    } finally {
      callback?.();
      dispatch(updatingMenu(false));
    }
  };
export default menuSlice.reducer;
