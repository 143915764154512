/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../firebase';
import { COLLECTIONS_NAMES } from '../utils/constant';
import { fromFirebaseToJs } from '../utils/dateutils';

export interface Review {
  id?: string;
  entityID: string;
  orderID: string;
  entityType: 'MEAL' | 'DRIVER';
  description: string;
  rating: number;
  tags: string[];
  customerInfo: {
    id: string;
    photoURL: string;
    name: string;
  };
  restaurantInfo: {
    id: string;
    photoURL: string;
    name: string;
  };
  driverInfo?: {
    id: string;
    photoURL: string;
    name: string;
  };
  mealInfo?: {
    id: string;
    photoURL: string;
    name: string;
  };
  isActive?: boolean;
  createdAt?: string;
  updateAt?: string;
}

export interface FeedbackState {
  restaurant: Review;
}

const initialState: FeedbackState = {
  restaurant: {} as any
};

// ==============================|| SLICE - MENU ||============================== //

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setRestaurantFeedback(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.id] = el;
      }
      return { ...state, restaurant: { ...state.restaurant, ...temp } };
    }
  }
});

export default feedbackSlice.reducer;

export const { setRestaurantFeedback } = feedbackSlice.actions;

export const getMealsFeedackbackAsync =
  (
    mealId: string,
    onLoad: () => void,
    callBack?: (ev: any) => void,
    onError?: (er: any) => void
  ) =>
  async () => {
    try {
      onLoad();
      const requestRef = query(
        collection(firestore, COLLECTIONS_NAMES.REVIEWS),
        where('entityID', '==', mealId)
      );
      const requestDocs = await getDocs(requestRef);
      const requests: any = [];
      requestDocs.forEach((doc) =>
        requests.push({
          ...doc.data(),
          id: doc.id,
          updateAt: fromFirebaseToJs(doc.data().rejectedAt),
          createdAt: fromFirebaseToJs(doc.data().createdAt)
        })
      );
      callBack?.(requests);
    } catch (error) {
      onError?.(error);
    } finally {
      onLoad();
    }
  };
