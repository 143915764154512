import React from 'react';

export default function BluePhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M9.82499 11.843L7.09999 14.568C6.79999 14.3013 6.50832 14.0263 6.22499 13.743C5.36666 12.8763 4.59166 11.968 3.89999 11.018C3.21666 10.068 2.66666 9.11797 2.26666 8.1763C1.86666 7.2263 1.66666 6.31797 1.66666 5.4513C1.66666 4.88464 1.76666 4.34297 1.96666 3.84297C2.16666 3.33464 2.48332 2.86797 2.92499 2.4513C3.45832 1.9263 4.04166 1.66797 4.65832 1.66797C4.89166 1.66797 5.12499 1.71797 5.33332 1.81797C5.54999 1.91797 5.74166 2.06797 5.89166 2.28464L7.82499 5.00964C7.97499 5.21797 8.08332 5.40964 8.15832 5.59297C8.23332 5.76797 8.27499 5.94297 8.27499 6.1013C8.27499 6.3013 8.21666 6.5013 8.09999 6.69297C7.99166 6.88463 7.83332 7.08464 7.63332 7.28464L6.99999 7.94297C6.90832 8.03464 6.86666 8.14297 6.86666 8.2763C6.86666 8.34297 6.87499 8.4013 6.89166 8.46797C6.91666 8.53464 6.94166 8.58463 6.95832 8.63463C7.10832 8.90964 7.36666 9.26797 7.73332 9.7013C8.10832 10.1346 8.50832 10.5763 8.94166 11.018C9.24166 11.3096 9.53332 11.593 9.82499 11.843Z"
        fill="#265767"
      />
      <path
        d="M18.3083 15.2742C18.3083 15.5076 18.2667 15.7492 18.1833 15.9826C18.1583 16.0492 18.1333 16.1159 18.1 16.1826C17.9583 16.4826 17.775 16.7659 17.5333 17.0326C17.125 17.4826 16.675 17.8076 16.1667 18.0159C16.1583 18.0159 16.15 18.0242 16.1417 18.0242C15.65 18.2242 15.1167 18.3326 14.5417 18.3326C13.6917 18.3326 12.7833 18.1326 11.825 17.7242C10.8667 17.3159 9.90833 16.7659 8.95833 16.0742C8.63333 15.8326 8.30833 15.5909 8 15.3326L10.725 12.6076C10.9583 12.7826 11.1667 12.9159 11.3417 13.0076C11.3833 13.0242 11.4333 13.0492 11.4917 13.0742C11.5583 13.0992 11.625 13.1076 11.7 13.1076C11.8417 13.1076 11.95 13.0576 12.0417 12.9659L12.675 12.3409C12.8833 12.1326 13.0833 11.9742 13.275 11.8742C13.4667 11.7576 13.6583 11.6992 13.8667 11.6992C14.025 11.6992 14.1917 11.7326 14.375 11.8076C14.5583 11.8826 14.75 11.9909 14.9583 12.1326L17.7167 14.0909C17.9333 14.2409 18.0833 14.4159 18.175 14.6242C18.2583 14.8326 18.3083 15.0409 18.3083 15.2742Z"
        fill="#265767"
      />
    </svg>
  );
}
