// material-ui
import { Box, Divider, IconButton, useMediaQuery } from '@mui/material';

// project import

import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';
import { AppTheme } from '../../../../themes';
import LangSection from './LangSection';
import MessageIcon from '../../../../assets/icons/MessageIcon';
import NotificationIcon from '../../../../assets/icons/NotificationIcon';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {!matchesXs && <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {/* <Notification /> */}
      {!matchesXs && <LangSection />}
      {!matchesXs && <Divider orientation="vertical" flexItem />}
      {/* <IconButton sx={{ mr: 2 }}>
        <MessageIcon />
      </IconButton>
      <IconButton sx={{ mr: 1 }}>
        <NotificationIcon />
      </IconButton> */}

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
