/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { gettingOrders, updatingOrderStatus } from './ui';
import {
  DocumentData,
  Query,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { COLLECTIONS_NAMES, ORDERS_STATUS } from '../utils/constant';
import { fromFirebaseToJs } from '../utils/dateutils';
import { formatLocation, tabValue } from '../utils/stringUtils';

interface MealInfo {
  mealID: string;
  name: string;
  price: number;
  quantity: number;
  realPrice?: number;
}

interface CustomerInfo {
  name: string;
  phoneNumber: string;
}

interface RestaurantInfo {
  name: string;
  phoneNumber: string;
}

interface DriverInfo {
  name: string;
  phoneNumber: string;
}

interface PromoCodeInfo {
  code: string;
  createdAt: string;
  expiredAt: string;
  updateAt?: string;
}
export interface Addresss {
  id?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  coord?: any;
  createdAt?: string;
  updateAt?: string;
}

export interface PaymentInfo {
  amount?: number;
  createdAt?: string;
  paymentMethodId?: string;
}

export interface Order {
  id?: string;
  restaurantID?: string;
  driverID?: string;
  customerID?: string;
  customerInfo: CustomerInfo; //
  restaurantInfo: RestaurantInfo; //
  driverInfo: DriverInfo; //
  meals: MealInfo[]; // id, name, price, quantity, realPrice,
  srcAdress: Addresss;
  destAdress: Addresss;
  promoCodeID?: string;
  promoCodeInfo: PromoCodeInfo;
  paymentInfo?: PaymentInfo;
  discount?: number;
  createdAt?: boolean;
  deliveredAt?: string;
  cancelAt?: string;
}

export interface OrderState {
  data: Order;
  history: any;
}

const initialState: any = {
  data: {},
  history: {}
};

// ==============================|| SLICE - MENU ||============================== //

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderList(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.id] = el;
      }
      return { ...state, data: { ...temp } };
    },
    updateOrderList(state, { payload }) {
      state.data[payload.id] = { ...state.data[payload.id], payload };
    },
    setHistoryList(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.id] = el;
      }
      return { ...state, history: { ...state?.history, ...temp } };
    },

    updateHistoryList(state, { payload }) {
      const temp: any = {};
      for (const el of payload) {
        temp[el.id] = el;
      }

      return { ...state, history: { ...state.history, ...temp } };
    },
    cancelOrder(state, { payload }) {
      state.history[payload.id] = payload;
      delete state.data[payload.id];
      return state;
    }
  }
});

export default orderSlice.reducer;

export const {
  setOrderList,
  setHistoryList,
  updateOrderList,
  updateHistoryList,
  cancelOrder
} = orderSlice.actions;

export const updateOrderAsync =
  (data: any, callBack?: () => void, onError?: (er: any) => void) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(updatingOrderStatus(true));
      const selectedOrder = getState().order.data[data?.id];
      const updateValue: any = {
        ...data,
        id: data?.id,
        status: data?.status?.toUpperCase(),
        updatedAt: new Date()
      };
      if (data?.items) {
        updateValue['items'] = data?.items;
      }
      const docRef = doc(firestore, `${COLLECTIONS_NAMES.ORDERS}/${data?.id}`);
      await updateDoc(docRef, updateValue);
      if (data?.cancelled) {
        dispatch(
          cancelOrder({
            ...selectedOrder,
            ...updateValue
          })
        );
      } else {
        dispatch(
          updateOrderList({
            ...updateValue,
            tab: tabValue(data?.status)
          })
        );
      }
      callBack?.();
    } catch (error) {
      console.log('Error updating state', error);
      onError?.(error);
    } finally {
      dispatch(updatingOrderStatus(false));
    }
  };

export const getOrderHistoryAsync =
  (restaurantID: string, callBack?: () => void, onError?: (er: any) => void) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(gettingOrders(true));
      const data: any[] = Object.values(getState().order.history);
      let orderRef: Query<DocumentData, DocumentData> | null = null;
      if (data.length > 0) {
        let latestDate = 0;
        for (let i: number = 0; i < data.length; i++) {
          if (latestDate < data[i].createdAt) {
            latestDate = data[i].createdAt;
          }
        }
        const timestamp = new Date(latestDate);

        orderRef = query(
          collection(firestore, COLLECTIONS_NAMES.ORDERS),
          where('restaurantID', '==', restaurantID),
          where('status', '==', ORDERS_STATUS.DELIVERED),
          // where('createdAt', '>=', timestamp),
          orderBy('createdAt', 'asc')
        );
      } else {
        orderRef = query(
          collection(firestore, COLLECTIONS_NAMES.ORDERS),
          where('restaurantID', '==', restaurantID),
          where('status', '==', ORDERS_STATUS.DELIVERED),
          orderBy('createdAt', 'asc')
        );
      }
      const items: any = [];
      const querySnapshot = await getDocs(orderRef);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        items.push({
          ...data,
          createdAt: fromFirebaseToJs(data?.createdAt),
          updateAt: fromFirebaseToJs(data?.updateAt),
          deliveryAddress: {
            ...data?.deliveryAddress,
            street: data?.deliveryAddress?.street
          }
        });
      });
      dispatch(setHistoryList(items));
      // if (data.length > 0) {
      //   dispatch(updateHistoryList(items));
      // } else {
      //   dispatch(setHistoryList(items));
      // }
    } catch (error) {
      console.log('Error getting Loan History', error);
      onError?.(error);
    } finally {
      dispatch(gettingOrders(false));
      callBack?.();
    }
  };

export const getOrderHistoryCancellledAsync =
  (restaurantID: string, callBack?: () => void, onError?: (er: any) => void) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(gettingOrders(true));
      const data: any[] = Object.values(getState().order.history);
      let orderRef: Query<DocumentData, DocumentData> | null = null;
      if (data.length > 0) {
        let latestDate = 0;
        for (let i: number = 0; i < data.length; i++) {
          if (latestDate < data[i].createdAt) {
            latestDate = data[i].createdAt;
          }
        }
        const timestamp = new Date(latestDate);
        orderRef = query(
          collection(firestore, COLLECTIONS_NAMES.ORDERS),
          where('restaurantID', '==', restaurantID),
          where('cancelled', '==', true),
          // where('createdAt', '>=', timestamp),
          orderBy('createdAt', 'asc')
        );
      } else {
        orderRef = query(
          collection(firestore, COLLECTIONS_NAMES.ORDERS),
          where('restaurantID', '==', restaurantID),
          where('cancelled', '==', true),
          orderBy('createdAt', 'asc')
        );
      }
      const items: any = [];
      const querySnapshot = await getDocs(orderRef);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        items.push({
          ...data,
          createdAt: fromFirebaseToJs(data?.createdAt),
          updateAt: fromFirebaseToJs(data?.updateAt),
          deliveryAddress: {
            ...data?.deliveryAddress,
            street: data?.deliveryAddress?.street
          }
        });
      });
      dispatch(setHistoryList(items));

      // if (data.length > 0) {
      //   dispatch(updateHistoryList(items));
      // } else {
      //   dispatch(setHistoryList(items));
      // }
    } catch (error) {
      console.log('Error getting Loan History', error);
      onError?.(error);
    } finally {
      dispatch(gettingOrders(false));
      callBack?.();
    }
  };
