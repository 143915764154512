import { Stack, Typography } from '@mui/material';
import useGetColors from '../hooks/useGetColor';
import DropIcon from '../assets/icons/DropIcon';
import { useDropzone } from 'react-dropzone';
import i18n from '../i18n-config';
import { CSSProperties } from 'react';

export default function CustomDropzone({
  onDrop,
  children,
  style
}: {
  onDrop: any;
  children?: any;
  style?: CSSProperties;
}) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg']
    },
    maxFiles: 6,
    multiple: true
  });

  const colors = useGetColors();

  return (
    <div style={{ ...style }} {...getRootProps({ className: 'dropzone' })}>
      {!children ? (
        <Stack
          sx={{
            border: '1px solid #EAECF0',
            backgroundColor: '#f9fafb',
            height: '126px',
            borderRadius: '8px'
          }}
          alignItems="center"
          justifyContent={'center'}
        >
          <DropIcon />
          <Stack direction="row" spacing={1} mt={2}>
            <Typography fontSize={12} fontWeight="bold" color={colors.accent}>
              {i18n.t('click_upload')}
            </Typography>
            <Typography fontSize={12} color="#475467">
              {i18n.t('drag_drop')}
            </Typography>
          </Stack>
          <Typography fontSize={12} color="#475467">
            {i18n.t('click_upload_image_format')}
          </Typography>
        </Stack>
      ) : (
        children
      )}
      <input {...getInputProps()} />
    </div>
  );
}
