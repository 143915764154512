import { Timestamp } from 'firebase/firestore';

export function formatTimestamp(timestampSec: number): string {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (timestampSec === null || timestampSec === undefined) {
    return '---';
  }
  const date = new Date(timestampSec * 1000);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const hour = date.getHours();
  const minute = date.getMinutes();

  const formattedTime = `${day} ${month}, ${hour
    .toString()
    .padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

  return formattedTime;
}

export function fromJsToFirebase(date: any) {
  if (date && typeof date === 'number') {
    return new Date(date);
  } else if (typeof date === 'string') {
    return new Date(date);
  }
  return date || null;
}

export function fromFirebaseToJs(firebase_date: any) {
  if (firebase_date && typeof firebase_date === 'object') {
    return Timestamp.fromMillis(firebase_date.seconds * 1000)
      .toDate()
      .valueOf();
  }
  return firebase_date || null;
}

export const formatTime = (time: any) => {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true } as any;
  return new Date(time).toLocaleTimeString([], options);
};

export const fromTimestampToDate = (timestamp: number) => {
  if (Math.abs(timestamp)) {
    return new Date(timestamp);
  }
};

export function formatToLocale(
  timestamp: number,
  locale = 'default',
  options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  }
) {
  if (typeof timestamp === 'number') {
    return new Intl.DateTimeFormat(locale, options).format(new Date(timestamp));
  }
}

export const dateWithoutHour = () => {
  const date = new Date();
  let newDate = new Date(
    date?.getFullYear(),
    date?.getMonth(),
    date?.getDate()
  );
  return newDate;
};

export const timestampWithoutHour = (timestamp: number) => {
  const date = new Date(timestamp);
  let newTimestamp = new Date(
    date?.getFullYear(),
    date?.getMonth(),
    date?.getDate()
  );
  return newTimestamp.valueOf();
};

export const add = (date: Date, days = 0) => {
  if (date?.valueOf()) {
    const local = new Date();
    const result = local.setDate(date.getDate() + days);
    return new Date(result);
  }
  return null;
};
export const addHour = (date: Date, hour = 0) => {
  if (date?.valueOf()) {
    const local = new Date();
    const result = local.setHours(date.getHours() + hour);
    return new Date(result);
  }
  return null;
};

export const sub = (date: Date, days = 0) => {
  if (date?.valueOf()) {
    const local = new Date();
    const result = local.setDate(date.getDate() - days);
    return new Date(result);
  }
  return null;
};
export const add_without_hour = (date: Date, days = 0) => {
  if (date?.valueOf()) {
    const local = new Date();
    const result = local.setDate(date.getDate() + days);
    return timestampWithoutHour(new Date(result).valueOf());
  }
  return null;
};
export const sub_without_hour = (date: Date, days = 0) => {
  if (date?.valueOf()) {
    const local = new Date();
    const result = local.setDate(date.getDate() - days);
    return timestampWithoutHour(new Date(result).valueOf());
  }
  return null;
};

// export const formatter = Intl.DateTimeFormat('default', {
//   dateStyle: 'medium',
//   day: '2-digit',
//   month: 'short',
//   year: 'numeric',
// });

export const formatDate = (timestamp: Date | number, language: string) => {
  if (language === 'fr') {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${month}, ${hours}:${minutes}`;
  } else {
    const date = new Date(timestamp);
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${month} ${day}, ${hours}:${minutes}`;
  }
};

export const timestampWithoutDay = () => {
  const date = new Date();
  let newDate = new Date(date?.getFullYear(), date?.getMonth());
  return newDate;
};

export const getLastFourYearsWithMonths = () => {
  const date = new Date().getFullYear();
  return {
    years: [date, date - 1, date - 2, date - 3],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  };
};

export const getFullMonth = (monthNum: number) => {
  return [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ][monthNum];
};

export const fromDaysToMonth = (days: number) => {
  const y = 365;
  const y2 = 30;
  const remainder = days % y;
  const casio = remainder % y2;

  const result = (remainder - casio) / y2;

  return result;
};

export const getDateDifference = (
  endDate?: number,
  startDate = Date.now(),
  output = 'days'
) => {
  if (!endDate) {
    return 0;
  }
  const diffInMs =
    timestampWithoutHour(new Date(endDate).valueOf()) -
    timestampWithoutHour(new Date(startDate).valueOf());
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  if (output !== 'days') {
    return diffInDays / 30;
  }
  return diffInDays;
};

export const getDays = (date_1: Date, date_2: Date) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

export const getHours = (date_1: Date, date_2: Date) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600));
  return TotalDays;
};

export const timeCounter = (startStamp: any, newStamp: any) => {
  let diff = Math.round((newStamp - startStamp) / 1000);
  let years = Math.floor(diff / (365 * 24 * 60 * 60));
  diff = diff - years * 365 * 24 * 60 * 60;
  let months = Math.floor(diff / (30 * 24 * 60 * 60));
  diff = diff - months * 30 * 24 * 60 * 60;
  let d = Math.floor(diff / (24 * 60 * 60));
  diff = diff - d * 24 * 60 * 60;
  let h = Math.floor(diff / (60 * 60));
  diff = diff - h * 60 * 60;
  let m = Math.floor(diff / 60);
  diff = diff - m * 60;
  let s = diff;

  return {
    years,
    months,
    days: d,
    hours: h,
    minutes: m,
    seconds: s
  };
};

export const getDateTime = (val: any) => {
  if (val) {
    const date = new Date(val);

    const formattedDate = Intl.DateTimeFormat('default', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(date);

    return `${formattedDate}, ${date?.getHours()}:${date?.getMinutes()} ${
      date?.getHours() >= 12 ? 'PM' : 'AM'
    }`;
  }
};

export function recentDate(timestamp: number): string {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - timestamp;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years >= 1) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (months >= 1) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days >= 1) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours >= 1) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes >= 1) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
}

export function formatHomeDrawer(timestamp: number): string {
  function isSameDate(date1: Date, date2: Date): boolean {
    return date1.toDateString() === date2.toDateString();
  }

  function isYesterday(date1: Date, date2: Date): boolean {
    const yesterday = new Date(date1);
    yesterday.setDate(date1.getDate() - 1);
    return isSameDate(yesterday, date2);
  }

  function formatTime(hours: number, minutes: number): string {
    return `${hours.toString().padStart(2, '0')}h${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  if (isSameDate(currentDate, inputDate)) {
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    return `today:${formatTime(hours, minutes)}`;
  } else if (isYesterday(currentDate, inputDate)) {
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    return `yesterday:${formatTime(hours, minutes)}`;
  } else {
    const daysAgo = Math.floor(
      (currentDate.getTime() - inputDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    return `${daysAgo} days ago:${formatTime(hours, minutes)}`;
  }
}

export function formatHomeDrawerFr(timestamp: number): string {
  function isSameDate(date1: Date, date2: Date): boolean {
    return date1.toDateString() === date2.toDateString();
  }

  function isYesterday(date1: Date, date2: Date): boolean {
    const yesterday = new Date(date1);
    yesterday.setDate(date1.getDate() - 1);
    return isSameDate(yesterday, date2);
  }

  function formatTime(hours: number, minutes: number): string {
    return `${hours.toString().padStart(2, '0')}h${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  if (isSameDate(currentDate, inputDate)) {
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    return `Aujourd'hui :${formatTime(hours, minutes)}`;
  } else if (isYesterday(currentDate, inputDate)) {
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    return `Hier :${formatTime(hours, minutes)}`;
  } else {
    const daysAgo = Math.floor(
      (currentDate.getTime() - inputDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    return `Il y a ${daysAgo} jours :${formatTime(hours, minutes)}`;
  }
}

function formHot(timestamp: number): string {
  const date = new Date(timestamp);

  // Format the date as a timestamp in milliseconds
  return date.getTime().toString();
}
export function maxHotDealDate(
  initialDate: number | null,
  numberOfDays: number | null
): string | null {
  if (initialDate === null || numberOfDays === null) {
    return null;
  }

  const date = new Date(initialDate);
  date.setDate(date.getDate() + numberOfDays);

  // Call the function to format the date as a timestamp
  return formHot(date.getTime());
}
