import React from 'react';

export default function AuthLay() {
  return (
    <div>
      <svg
        width="315"
        height="74"
        viewBox="0 0 315 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M194.024 52.8414C191.185 52.8414 188.871 50.5246 188.871 47.6824C188.871 46.9659 189.014 46.2971 189.277 45.6761C189.396 45.3657 189.563 45.079 189.754 44.7924C189.945 44.5297 190.159 44.267 190.374 44.0281C190.493 43.9087 190.613 43.7893 190.732 43.6938C191.281 43.24 191.949 42.8817 192.664 42.6906C192.974 42.5951 193.332 42.5473 193.666 42.5234C193.786 42.5234 193.905 42.5234 194.024 42.5234C194.143 42.5234 194.263 42.5234 194.382 42.5234C194.716 42.5234 195.05 42.5951 195.384 42.6906C196.1 42.8817 196.744 43.24 197.316 43.6938C197.436 43.7893 197.555 43.9087 197.674 44.0281C197.913 44.267 198.127 44.5058 198.294 44.7924C198.485 45.0552 198.628 45.3657 198.772 45.6761C199.034 46.2971 199.177 46.9659 199.177 47.6824C199.177 50.5246 196.863 52.8414 194.024 52.8414Z"
          fill="white"
        />
        <path
          d="M247.343 32.8015C247.343 29.4338 247.343 24.7764 241.594 24.7764C240.902 24.7764 235.844 24.9914 235.844 31.5356V49.1143C235.844 52.076 233.673 53.1269 231.503 53.1269C229.332 53.1269 227.089 52.076 227.089 49.1143V32.8015C227.089 29.4338 227.089 24.7764 221.34 24.7764C220.648 24.7764 215.59 24.9914 215.59 31.5356V49.1143C215.59 52.076 213.419 53.1269 211.248 53.1269C209.077 53.1269 206.835 52.076 206.835 49.1143V20.7161C206.835 17.7545 209.077 16.7036 211.248 16.7036C213.419 16.7036 214.612 17.5395 215.185 18.7337C215.471 19.3547 215.757 19.3547 216.02 19.3547C216.378 19.3547 216.783 18.9248 217.57 18.4471C219.455 17.2529 220.6 16.6797 223.606 16.6797C226.612 16.6797 229.284 17.6589 231.264 19.2114C231.765 19.5697 232.17 19.5697 232.457 19.5697C233.22 19.5697 233.721 19.2114 234.556 18.7337C237.013 17.3246 239.828 16.7036 242.5 16.7036C250.015 16.7036 256.194 21.0027 256.194 30.0548V49.1143C256.194 52.076 254.023 53.1269 251.852 53.1269C249.681 53.1269 247.439 52.076 247.439 49.1143V32.8015H247.343Z"
          fill="white"
        />
        <path
          d="M279.215 44.7665C281.171 44.7665 283.7 44.1455 285.394 42.9991C286.444 42.3064 287.159 41.8049 288.424 41.8049C290.809 41.8049 292.718 44.2649 292.718 46.3667C292.718 47.2743 292.217 48.3252 291.31 49.0418C287.589 51.9317 283.724 53.1259 278.953 53.1259C266.953 53.1259 263.708 46.104 263.708 34.9263C263.708 23.7485 266.667 16.7266 278.595 16.7266C289.187 16.7266 293.052 22.9842 293.052 32.9678C293.052 35.5711 292.694 38.2461 288.829 38.2461H273.513C273.084 38.2461 272.822 38.5328 272.822 38.9388C273.036 42.5931 274.372 44.7665 279.215 44.7665ZM278.595 24.5128C274.945 24.5128 272.989 27.1878 272.917 30.6988C272.917 31.2003 273.132 31.3198 273.418 31.3198H283.939C284.297 31.3198 284.44 31.1048 284.44 30.7465C284.44 27.2356 282.627 24.4889 278.619 24.4889L278.595 24.5128Z"
          fill="white"
        />
        <path
          d="M169.571 14.0239C166.971 14.0239 164.919 14.7404 163.106 15.8391C162.247 16.3884 161.77 17.0094 161.364 17.0094C161.054 17.0094 160.744 17.0094 160.41 16.2929C159.79 14.9554 158.454 14 155.997 14C153.54 14 151.035 15.1942 151.035 18.4902V59.4514C151.035 56.7286 153.229 54.5313 155.949 54.5313C158.669 54.5313 160.864 56.7286 160.864 59.4514V53.0983C160.864 52.1429 161.484 52.0712 161.818 52.0712C162.223 52.0712 162.677 52.6922 163.559 53.2654C165.301 54.3641 167.567 54.8418 169.547 54.8418C180.33 54.8418 184.028 46.1002 184.028 34.4209C184.028 22.7416 180.33 14 169.547 14L169.571 14.0239ZM167.281 45.4792C160.983 45.4792 160.911 38.935 160.911 34.4448C160.911 29.4052 160.983 23.4103 167.281 23.4103C172.959 23.4103 173.961 27.59 173.961 34.4448C173.961 41.2995 173.102 45.4792 167.281 45.4792Z"
          fill="white"
        />
        <path
          d="M155.973 54.5547C153.253 54.5547 151.059 56.752 151.059 59.4748V69.0762C151.059 71.799 152.466 73.9964 155.973 73.9964C158.693 73.9964 160.887 72.9932 160.887 69.0762V59.4748C160.887 56.752 158.693 54.5547 155.973 54.5547Z"
          fill="white"
        />
        <path
          d="M13.3357 13.1222C13.3357 12.6206 13.1926 12.5012 12.7155 12.5012H3.93631C1.04968 12.5012 0 10.3277 0 8.08263C0 5.83752 1.04968 3.66406 3.93631 3.66406H31.8721C34.7588 3.66406 35.8085 5.90918 35.8085 8.08263C35.8085 10.2561 34.7588 12.5012 31.8721 12.5012H23.093C22.6636 12.5012 22.4727 12.6445 22.4727 13.1222V48.9722C22.4727 52.0533 20.0871 52.8415 17.9162 52.8415C15.7452 52.8415 13.3596 52.0772 13.3596 48.9722V13.1222H13.3357Z"
          fill="white"
        />
        <path
          d="M60.6667 21.8353C60.6667 23.9371 58.3526 26.4688 56.3248 26.4688C54.9173 26.4688 54.3686 25.8956 53.1042 25.4896C51.482 24.9164 50.7901 24.7969 49.4542 24.7969C43.8479 24.7969 43.49 28.5229 43.49 34.6372C43.49 40.7515 43.8479 44.4774 49.4542 44.4774C51.148 44.4774 52.6032 44.1192 54.4402 43.2116C55.4898 42.71 56.134 42.1607 57.3983 42.1607C59.784 42.1607 61.6925 44.764 61.6925 46.8658C61.6925 47.7734 61.4062 48.7049 60.5713 49.3976C57.4938 51.9293 53.4143 52.8369 49.478 52.8369C37.2635 52.8369 34.52 45.0268 34.52 34.6372C34.52 24.2476 37.2635 16.4375 49.478 16.4375C53.2712 16.4375 56.5634 17.2018 59.3784 19.3275C60.285 20.0201 60.7144 21.0232 60.7144 21.8592L60.6667 21.8353Z"
          fill="white"
        />
        <path
          d="M88.3166 32.5374C88.3166 29.1698 88.3166 24.5124 82.5672 24.5124C81.8754 24.5124 76.8178 24.7274 76.8178 31.2716V48.8503C76.8178 51.8119 74.6469 52.8628 72.4759 52.8628C70.305 52.8628 68.0625 51.8119 68.0625 48.8503V7.38752C68.0625 4.4259 70.305 3.375 72.4759 3.375C74.6469 3.375 76.8178 4.4259 76.8178 7.38752V18.3503C76.8178 19.043 77.0325 19.1863 77.3188 19.1863C77.6766 19.1863 78.0106 18.6847 78.7979 18.207C80.6825 17.0128 82.4479 16.4396 85.597 16.4396C93.5412 16.4396 97.1196 22.339 97.1196 29.4325V48.8264C97.1196 51.788 94.9487 52.8389 92.7777 52.8389C90.6068 52.8389 88.3643 51.788 88.3643 48.8264V32.5136L88.3166 32.5374Z"
          fill="white"
        />
        <path
          d="M310.014 53.4109C306.984 53.4109 305.028 51.2374 305.028 48.4191C305.028 45.4575 306.984 43.4273 310.014 43.4273C312.829 43.4273 315 45.4575 315 48.4191C315 51.3807 312.829 53.4109 310.014 53.4109ZM305.6 7.93554C305.6 4.97392 304.241 0.9375 309.99 0.9375C315.739 0.9375 314.38 4.9978 314.38 7.93554V35.6411C314.38 38.4594 312.209 39.5103 310.038 39.5103C307.867 39.5103 305.624 38.4594 305.624 35.6411V7.93554H305.6Z"
          fill="white"
        />
        <path
          d="M143.735 24.7487C142.829 24.9398 141.612 24.6293 140.228 22.9813C136.53 25.728 135.934 22.4081 135.934 22.4081C135.934 22.4081 131.258 21.7155 133.882 16.8431C132.141 16.0072 131.664 15.004 131.711 14.1442C129.684 13.4754 127.537 13.1172 125.294 13.1172C114.01 13.1172 104.873 22.2648 104.873 33.562C104.873 44.8592 114.01 54.0068 125.294 54.0068C136.578 54.0068 145.715 44.8592 145.715 33.562C145.715 30.4093 144.999 27.4476 143.735 24.7726V24.7487ZM125.294 22.0498C131.592 22.0498 136.674 27.161 136.674 33.4426C136.674 39.7241 131.568 44.8353 125.294 44.8353C119.02 44.8353 113.915 39.7241 113.915 33.4426C113.915 27.161 119.02 22.0498 125.294 22.0498Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
