import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AppButton from '../../../../components/AppButton';
import useGetColors from '../../../../hooks/useGetColor';
import { RootState } from '../../../../store';
import { changeTheme, ThemeProps } from '../../../../store/ui';
import AdminColorInput from '../AdminColorInput';
import '../../Settings.css';
import SettingsDetailTitle from '../../SettingsDetailTitle';
import useAuth from '../../../../hooks/useAuth';
import {
  updateRestaurant,
  updateRestaurantAsync
} from '../../../../store/auth';
import notifySuccess, { notifyError } from '../../../../utils/notify';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';

export default function Theme() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { restaurant } = useAuth();
  const colors = useGetColors();

  const theme = useSelector((state: RootState) => state.ui.theme);

  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState<ThemeProps>({
    primary: theme?.primary?.main,
    secondary: theme?.secondary?.custom,
    accent: theme?.accent
  });
  const handleColorChange = (key: string, value: string) => {
    setColor((prev) => ({ ...prev, [key]: value }));
  };

  const callback = () => {
    notifySuccess(capitalizeFirstLetter(t('update_success')));
    setLoading(false);
  };
  const onError = () => {
    notifyError(capitalizeFirstLetter(t('error_occured')));
    setLoading(false);
  };

  const onDefaultTheme = () => {
    const defaultData = {
      primary: { main: '#265767' },
      secondary: { custom: '#5089A1' },
      accent: '#D77910'
    };
    const data_to_save = {
      ...restaurant,
      theme: {
        accent: '#D77910',
        primary: '#265767',
        secondary: '#5089A1'
      },
      updatedAt: new Date()
    };
    dispatch(
      updateRestaurantAsync({
        data: data_to_save,
        callback,
        onError,
        onStart() {
          setLoading(true);
        }
      }) as any
    );
    dispatch(changeTheme(defaultData));
    setColor({
      primary: '#265767',
      secondary: '#5089A1',
      accent: '#D77910'
    });
  };
  const onSaveTheme = () => {
    const finalData = {
      primary: { main: color.primary },
      secondary: { custom: color?.secondary },
      accent: color?.accent
    };
    const data_to_save = {
      ...restaurant,
      theme: {
        accent: color.accent,
        primary: color.primary,
        secondary: color.secondary
      },
      updatedAt: new Date()
    };
    dispatch(
      updateRestaurantAsync({
        data: data_to_save,
        callback,
        onError,
        onStart() {
          setLoading(true);
        }
      }) as any
    );
    dispatch(changeTheme(finalData));
  };
  const disabled =
    color?.primary.trim() === '' ||
    color?.secondary.trim() === '' ||
    color?.accent.trim() === '';

  const disabledDefault =
    color?.primary.trim() === '#265767' &&
    color?.secondary.trim() === '#5089A1' &&
    color?.accent.trim() === '#D77910';

  return (
    <Box
      sx={{ backgroundColor: 'white', minHeight: '100vh', pb: 4 }}
      pl={3}
      pt={1}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5000 }}
        open={loading}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <SettingsDetailTitle title="theme_settings" />
      <Typography className="admin-title3" my={2}>
        {t('change_color_polor')}
      </Typography>
      <Box sx={{ width: '80%' }}>
        <AdminColorInput
          color={color?.primary}
          keyVal={'primary'}
          title={t('primary')}
          title_desc={t('primary_desc')}
          handleColorChange={(key, val) => handleColorChange('primary', val)}
        />
        <AdminColorInput
          color={color?.secondary}
          keyVal={'secondary'}
          title={t('secondary')}
          title_desc={t('secondary_desc')}
          handleColorChange={handleColorChange}
        />
        <AdminColorInput
          color={color?.accent}
          keyVal={'accent'}
          title={t('accent')}
          title_desc={t('accent_desc')}
          handleColorChange={handleColorChange}
        />

        <Stack justifyContent={'space-between'} flexDirection="row" mt={2}>
          <Button
            variant="outlined"
            onClick={onDefaultTheme}
            disabled={disabledDefault}
            sx={{
              height: '44px',
              minWidth: '148px',
              color: colors.primary.main,
              border: `1px solid ${colors.primary.main}`
            }}
          >
            {t('default')}
          </Button>
          {/* <AppButton
            title={t('default')}
            disabled={disabledDefault}
            containerStyle={{
              bgcolor: 'transparent',
              height: '44px',
              minWidth: '148px',
              backgroundColor: 'transparent',
              border: `1px solid ${colors.primary.main}`
            }}
            titleStyle={{ color: colors.primary.main }}
            onClick={onDefaultTheme}
          /> */}
          <AppButton
            disabled={disabled}
            title={t('save_changes')}
            containerStyle={{
              height: '44px',
              minWidth: '148px',
              backgroundColor: disabled ? '#D4D4D8' : colors.primary.main
            }}
            onClick={onSaveTheme}
          />
        </Stack>
      </Box>
    </Box>
  );
}
