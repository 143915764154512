export const APP_VERSION = 0;

export const COLLECTIONS_NAMES = {
  REVIEWS: 'REVIEWS',
  DRIVERS: 'DRIVERS',
  ORDERS: 'ORDERS',
  RESTAURANTS: 'RESTAURANTS',
  CUSTOMERS: 'CUSTOMERS',
  REPORTS: 'REPORTS',
  CATEGORIES: 'CATEGORIES',
  DISHES_BEVERAGE: 'DISHES_BEVERAGE',
  TAG: 'TAGS',
  SIDES: 'SIDES',
  USERS: 'USERS',
  RESTO_USERS: 'RESTO_USERS',
  paymentMethods: 'paymentMethods'
};

export const STORAGE_DIRECTORY = {
  MENU_IMAGES_DIRECTORY: 'MENU_IMAGES',
  AUTH_IMAGES: 'AUTH_IMAGES'
};
export const ORDERS_STATUS = {
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
  NEW: 'NEW',
  READY: 'READY',
  WAITING_DELIVERY: 'WAITING_DELIVERY',
  WAITING_DRIVER: 'WAITING_DRIVER',
  PREPARING: 'PREPARING',
  ONROUTE: 'ON_ROUTE',
  VALIDATED_BY_RESTO: 'VALIDATED_BY_RESTO'
};

export const PAYMENT_STATUS = {
  PAID: 'PAID'
};

export const MEAL_TYPES = {
  MAIN: 'MEAL',
  SECONDARY: 'SECONDARY'
};

export const DATES = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const CURRENCIES = ['XAF', 'USD', 'EUR'];

export const DAYORDER = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];
